import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { colors, fonts } from '../../../assets/styles/theme';

class SideMenuButton extends React.Component {   

    constructor(props){
        super(props);
        this.isActive = this.isActive.bind(this);
        this.action = this.action.bind(this);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }
    
    isActive(path){
        if(this.props.route=='/'){
            return (this.props.location.pathname==this.props.route);
        }
        return (this.props.location.pathname.indexOf(this.props.route)===0);
    }

    action(){
        this.props.history.push(this.props.route);
        if(this.props.onAction){
            this.props.onAction();
        }
    }
    render(){
        return (
            <div 
                className={(this.isActive(this.props.path)) ? "SideMenuButton active" : "SideMenuButton"}
                onClick={this.action}
                >
                
                
                {this.props.name}

                <style jsx>{`
                        .SideMenuButton {
                            padding-top:20px;
                            padding-bottom:10px;
                            padding-right:20px;
                            text-align: right;
                            position: relative;
                            font-size: 17px ;
                            color : ${colors.mid};
                            cursor: pointer;
                            ${fonts.regular}
                        }
                        .SideMenuButton:hover:not(.active) {
                            color:${colors.white};
                        }
                        
                        .SideMenuButton:before {
                            position: absolute;
                            content: "";
                            display: block;
                            width:calc(100%);
                            bottom:0px;
                            background: #fff;
                            height:1px;
                            transform: translateX(-20px);
                            transition: all 0.3s;
                            opacity: 0.1;
                        }  
                        .SideMenuButton:after {
                            position: absolute;
                            content: "";
                            display: block;
                            width:7px;
                            height:7px;
                            bottom:-3px;
                            right:0px;
                            background: ${colors.primary};
                            border-radius: 4px;
                            transform: translateX(0px);
                            transition: all 0.3s;
                            opacity: 0;
                            box-shadow: 1px 1px 2px rgba(44, 44, 44, 0.5);
                        }  
                        .SideMenuButton.active {
                            color:${colors.primary};
                        } 
                        .SideMenuButton.active:after {
                            opacity: 1;
                        }  
                        .SideMenuButton.active:before {
                            opacity: 1;
                            width:calc(100% + 20px);
                            background: ${colors.primary};
                        }  
                        p {
                            font-size: 20px ;
                            color : ${colors.mid}
                        }
                `}</style>
            </div>
        )
    }
}

/*

<SideMenuButton name="Focus" route="/" />
                    {/* <div className="navigation-item">
                        name='Focus'
                        active={this.props.location.pathname==='/'}
                        onClick={()=>{this.props.history.push('/')}}
                </div> */

SideMenuButton.propTypes = {
    
}

export default withRouter(SideMenuButton);
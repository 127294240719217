import firebase from 'firebase';
import { logEvent } from '../analytics';
import { updateActionEventsOnActionDoneChange } from './eventAction';
export const createTimeNewLog = (stepId, projectId, title, time, timeRemaining, eventId)=>{
    return (dispatch, getState)=>{
        return new Promise(async (resolve, reject)=>{
            try {
                let newTimeLog = {
                    projectId : projectId,
                    stepId : stepId,
                    text : title,
                    time : time,
                    timeRemaining : timeRemaining,
                    eventId : eventId
                }
                dispatch({
                    type: 'NEW_TIME_LOG_UPDATE',
                    payload : newTimeLog
                });
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })       
        
    }
}

//this.props.saveTimeLog(log, this.state.resolveToMinutes(this.state.remain), this.state.markComplete, eventId);

export const saveTimeLog = (log, remainingMins, markComplete, eventId )=>{

    return (dispatch, getState)=>{
            
        return new Promise(async (resolve, reject)=>{
            try {
                let user = getState().app.authedUser;
                let ref;

                log.type = 'time';
                log.pinned = false;

                if(!log.id){
                    ref = firebase.firestore().collection('users').doc(user.uid).collection('projectLogs').doc();
                    log.id = ref.id;
                    log.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                    log.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
                   
                } else {
                    ref = firebase.firestore().collection('users').doc(user.uid).collection('projectLogs').doc(log.id);
                    log.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
                }

                //create the log item
                await ref.set(log, { merge: true });

                
                if(log.stepId){

                    let stepUpdate = {
                        complete : markComplete,
                        timeRemaining : remainingMins,
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp()
                    };

                    await firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(log.stepId).update(stepUpdate);

                    //update the remaining time on the step
                }


                
                if(typeof eventId !=='undefined'){
                    await firebase.database().ref(`users/${user.uid}/helmEvents/${eventId}`).update({ complete : true })
                }
  
                //if no event is present, find any events and mark them as complete.
                if( eventId === null && log.stepId){
                    dispatch(updateActionEventsOnActionDoneChange(log.stepId, markComplete))
                }


                //update the remaining time

                //trigger an update of the calendars via http request;
                //await firebase.functions().httpsCallable('refreshUserCalendars')({});
                
                logEvent('userCreatedLog', { type : 'time' });

                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
   


}

export const cancelTimeLog = ()=>{
    return (dispatch)=>{
     
        dispatch({
            type: 'NEW_TIME_LOG_UPDATE',
            payload : null
        });
        
    }
}


export const setHelmCalendar = (calendarId)=>{
    return (dispatch, getState)=>{
        dispatch({
            type: 'USER_CHANGE_CALENDAR_FEED_ID',
            payload : calendarId
        });
    }
}


export const createHelmCalendar = (profileId)=>{
    return (dispatch, getState)=>{

        return new Promise(async(resolve, reject)=>{
            try {
                let user = getState().app.authedUser;
                //create a calendar for Helm events
                let result =  await firebase.functions().httpsCallable('createUserHelmCalendar')({ profileId : profileId});
                logEvent('userCreatedHelmCalendar');
                let calendarId = (result && result.data && result.data.calendarId) ?  result.data.calendarId : null;
                dispatch({
                    type: 'USER_CREATED_HELM_CALENDAR',
                    payload : calendarId
                });
                resolve(calendarId);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
    }
}

export const syncHelmCalendar = (days, setNotifications)=>{
    return (dispatch, getState)=>{

        return new Promise(async(resolve, reject)=>{
            try {
                let user = getState().app.authedUser;
                //create a calendar for Helm events
                let result =  await firebase.functions().httpsCallable('syncUserHelmCalendar')({ days : days, setNotifications : setNotifications });
                logEvent('syncUserHelmCalendar');
                let calendarId = (result && result.data && result.data.calendarId) ?  result.data.calendarId : null;
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
    }
}




export const refreshCalendarFeed = ()=>{
    return (dispatch, getState)=>{
        return new Promise(async (resolve, reject)=>{
            try {
                let user = getState().app.authedUser;

                
                //trigger an update of the calendars via http request;
                await firebase.functions().httpsCallable('refreshUserCalendars')({});
                logEvent('userRefreshedCalendarFeed');

                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}


export const updateActiveCalendars = (calendars)=>{
    return (dispatch, getState)=>{
        return new Promise(async (resolve, reject)=>{
            try {
                let user = getState().app.authedUser;

                let batch = firebase.firestore().batch();

                calendars.forEach((c)=>{
                    let ref = firebase.firestore().collection('users').doc(user.uid).collection('linkedCalendars').doc(c.calendar_id)
                    batch.update(ref, { trackingCalendar: c.trackingCalendar  });
                })

                await batch.commit();

                //trigger an update of the calendars via http request;
                await firebase.functions().httpsCallable('updateUserNotificationChannel')({});
                logEvent('userUpdatedActiveCalendars');

                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}
export const activateCalendars = ()=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            //cancel any previous listeners
            if(getState().time.calenderProfileListener){
                getState().time.calenderProfileListener();
            }
            if(getState().time.linkedCalendarListener){
                getState().time.linkedCalendarListener();
            }
            
            let user = getState().app.authedUser;
                let calenderProfileListener = await firebase.firestore().collection('users').doc(user.uid)
                .collection('linkedCalendarProviderProfiles')
                .onSnapshot(
                    (snapShot)=>{
                        let calendarProfiles = [];
                        snapShot.forEach((cal)=>{
                            let doc = cal.data();
                            doc.id = cal.id;
                            calendarProfiles.push(doc);
                        });
                        dispatch({
                            type: 'USER_CALENDAR_PROFILES_UPDATE',
                            payload : calendarProfiles
                        });
                    }
                )

                let linkedCalendarListener = await firebase.firestore().collection('users').doc(user.uid)
                .collection('linkedCalendars')
                .onSnapshot(
                    (snapShot)=>{
                        let calendars = [];
                        snapShot.forEach((cal)=>{
                            let doc = cal.data();
                            doc.id = cal.id;
                            calendars.push(doc);
                        });
                        dispatch({
                            type: 'USER_LINKED_CALENDARS_UPDATE',
                            payload : calendars
                        });
                    }
                )

                dispatch({
                    type: 'USER_CALENDAR_LISTENER_UPDATE',
                    payload : {
                        calenderProfileListener : calenderProfileListener,
                        linkedCalendarListener : linkedCalendarListener
                    }
                });



        })
    }
}

export const deactivateCalendars = ()=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            //cancel any previous listeners
            if(getState().time.calenderProfileListener){
                getState().time.calenderProfileListener();
            }
            if(getState().time.linkedCalendarListener){
                getState().time.linkedCalendarListener();
            }
            
            dispatch({
                type: 'USER_CALENDAR_LISTENER_UPDATE',
                payload : {
                    calenderProfileListener : null,
                    linkedCalendarListener : null
                }
            });
        })
    }
}

export const processCalendarCode = (code)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{
            let loc = window.location;
            var processCode = firebase.functions().httpsCallable('processCalendarCode');
            try {
                let resp = await processCode({ code :  code, redirectUrl : `${loc.origin}${loc.pathname}`});
                logEvent('userValidatedNewCalendar');
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject('Unable to connect to your calendar. Please try again');
            }
            resolve(true);
        })
    }
}

export const saveTimeBucket = (bucket)=>{
    return async (dispatch, getState)=>{
        return new Promise(async (resolve, reject)=>{
            let user = getState().app.authedUser;
            let ref;

            if(!bucket.id){
                ref = firebase.firestore().collection('users').doc(user.uid).collection('timeBuckets').doc();
                bucket.id = ref.id;
                bucket.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            } else {
                ref = firebase.firestore().collection('users').doc(user.uid).collection('timeBuckets').doc(bucket.id);
            }
            bucket.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

            try {
                await ref.set(bucket, {merge : true});
                dispatch({
                    type: 'USER_TIME_BUCKET_UPDATED',
                    payload : bucket
                });
                logEvent('userSavedTimeBucket');
                resolve(bucket)
            }
            catch(e){
                console.error(e);
                dispatch({
                    type: 'USER_TIME_BUCKET_UPDATE_ERROR',
                    payload : e
                });
                reject(e);
            }
        })
        
        
        

        

        


    }
}
export const saveTimeBlock = (block)=>{
    return async (dispatch, getState)=>{
        
        let user = getState().app.authedUser;
        let ref;

        if(!block.id){
            ref = firebase.firestore().collection('users').doc(user.uid).collection('timeBlocks').doc();
            block.id = ref.id;
            block.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        } else {
            ref = firebase.firestore().collection('users').doc(user.uid).collection('timeBlocks').doc(block.id);
        }
        block.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

        try {
            await ref.set(block, {merge : true});
            dispatch({
                type: 'USER_TIME_BLOCK_UPDATED',
                payload : block
            });
            logEvent('userSavedTimeBlock');
        }
        catch(e){
            console.error(e);
            dispatch({
                type: 'USER_TIME_BLOCK_UPDATE_ERROR',
                payload : e
            });
        }


    }
}


export const setActiveTimeBucket = (bucket)=>{
    return (dispatch)=>{
        dispatch({
            type: 'SET_ACTIVE_TIME_BUCKET',
            payload : bucket
        });
    }

}

export const editTimeBucket = (bucket)=>{
    return (dispatch)=>{
        dispatch({
            type: 'EDIT_TIME_BUCKET',
            payload : bucket
        });
    }

}

export const deleteTimeBlock = (block)=>{
    return async (dispatch, getState)=>{
        
        let user = getState().app.authedUser;
        try {
            await firebase.firestore().collection('users').doc(user.uid).collection('timeBlocks').doc(block.id).delete();
            dispatch({
                type: 'USER_TIME_BLOCK_REMOVED',
                payload : block
            });
            logEvent('userDeletedTimeBlock');
        }
        catch(e){
            console.error(e);
            dispatch({
                type: 'USER_TIME_BLOCK_REMOVE_ERROR',
                payload : e
            });
        }


    }
}



export const initDefaultWeek = (user)=> {
  
    return async (dispatch, getState)=> {
        const db = firebase.firestore();

        let cancelDefaultTimeBlockSubscription = getState().time.defaultTimeBlockListener;
        if(cancelDefaultTimeBlockSubscription){ cancelDefaultTimeBlockSubscription() };
        
        let cancelTimeBucketSubscription = getState().time.timeBucketListener;
        if(cancelTimeBucketSubscription){ cancelTimeBucketSubscription() };

        let cancelTimeBlockSubscription = getState().time.timeBlockListener;
        if(cancelTimeBlockSubscription){ cancelTimeBlockSubscription() };
        
        
        if(user){


            //load the user time buckets
            let timeBucketListener = await db.collection('users').doc(user.uid).collection('timeBuckets').onSnapshot(
                (snapShot)=>{
                    let timeBuckets = [];
                    snapShot.forEach((bucket)=>{
                        timeBuckets.push(bucket.data());
                    });
                    dispatch({
                        type: 'USER_TIME_BUCKETS_UPDATE',
                        payload : timeBuckets
                    });
                }
            )
            //dispatch the time bucket listener
            dispatch({
                type: 'USER_TIME_BUCKETS_LISTENER_UPDATE',
                payload : timeBucketListener
            });


            //load the user time buckets
            let timeBlockListener = await db.collection('users').doc(user.uid).collection('timeBlocks').onSnapshot(
                (snapShot)=>{
                    let timeBlocks = [];
                    snapShot.forEach((block)=>{
                        timeBlocks.push(block.data());
                    });
                    dispatch({
                        type: 'USER_TIME_BLOCKS_UPDATE',
                        payload : timeBlocks
                    });
                }
            )
            //dispatch the time bucket listener
            dispatch({
                type: 'USER_TIME_BLOCKS_LISTENER_UPDATE',
                payload : timeBlockListener
            });


            //load the user default days
            let listener = await db.collection('users').doc(user.uid).collection('defaultDays').onSnapshot(
                (snapShot)=>{
                    let defaultDays = {};
                    snapShot.forEach((day)=>{
                        defaultDays[day.id] = day.data();
                    });
                    dispatch({
                        type: 'USER_DEFAULT_DAYS_UPDATE',
                        payload : defaultDays
                    });
                }
            )
            //dispatch the default day listener
            dispatch({
                type: 'USER_DEFAULT_DAYS_LISTENER_UPDATE',
                payload : listener
            });
            
            
        } else {
            //there is no user so clear all data
            dispatch({
                type: 'DEFAULT_WEEK_CLEAR',
                payload : null
            });

        }
    }
}

export const updateLinkedCalendarActiveStatus = (calenderId, trackingCalendar)=>{
    return async (dispatch, getState)=>{

    
        let user = getState().app.authedUser;
        try {
            await firebase.firestore().collection('users').doc(user.uid).collection('linkedCalendars').doc(calenderId).update({
                trackingCalendar : trackingCalendar
            });
            
            dispatch({
                type: 'USER_CALENDAR_TRACKING_UPDATED',
                payload : {
                    calenderId : calenderId,
                    trackingCalendar : trackingCalendar
                }
            });
        }
        catch(e){
            console.error(e);
            dispatch({
                type: 'USER_CALENDAR_TRACKING_UPDATE_ERROR',
                payload : e
            });
        }


    }
}

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FocusDaySwitch from '../../common/FocusDaySwitch';
import ZoneSubTitle from '../../common/ZoneSubTitle';

import PlanStepEditor from '../../common/PlanStepEditor';
import FlipMove from 'react-flip-move';
import Relax from '../../common/Relax';
import TimeLogger from '../../common/TimeLogger';
import DailyLogs from '../../common/DailyLogs';
import { logEvent } from '../../../analytics';
import _ from 'lodash';
import { fonts, colors } from '../../../assets/styles/theme';
import FocusExternalEvent from './FocusExternalEvent';
import FocusEvent from './FocusEvent';
import ZoneTitle from '../../global/Text/ZoneTitle';
import BrainDumpHeader from '../../global/BrainDump/BrainDumpHeader';
import BraindumpInputBox from '../../global/BrainDump/BraindumpInputBox';
import PlanList from '../../global/PlanList/PlanList';
import { DragDropContext } from 'react-beautiful-dnd';
import { bulkUpdateSortOrderAndProjectId, setSelectedPlanStep } from '../../../actions/planAction';
import BrainDumpPanel from '../../global/BrainDump/BrainDumpPanel';
import MediaQuery from 'react-responsive';

class Focus extends React.Component {

    constructor(props){
        super(props);
        this.renderTasks = this.renderTasks.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = {
            start : null,
            end : null
        }
    }
    componentWillMount(){
        logEvent('focusScreenLoaded')
        this.setState({
            start : moment().startOf('day').toISOString(),
            end : moment().endOf('day').toISOString()
        })
       
    }
    
    componentDidMount(){ }

    renderTasks(){
        if(!this.props.events) return null;
        let filteredTasks = this.props.events
        .filter((e)=>{
            switch(e.type){
                case 'milestone':
                    return false;
                case 'action':
                    return  moment(e.start).isBetween(this.state.start, this.state.end); 
                default:
                    return  moment(e.start).isBetween(this.state.start, this.state.end) && moment().isBefore(moment(e.end)); 
            }  
        })
       

        filteredTasks = _.sortBy(filteredTasks, 'start')
        //sort by date
        // filteredTasks


        if(filteredTasks.length){
            return filteredTasks
            .map((e, idx)=>{
                switch(e.type){
                    case 'action':
                        return <FocusEvent key={e.id+e.start} event={e} />
                    default:
                        return <FocusExternalEvent key={e.id+e.start} event={e} />
                }
            })
        } else {
            return <Relax></Relax>
        }
        

    }

    onDragEnd(result){
        // dropped outside the list
        if (!result.destination) {
            return;
        }


        //clone the project steps
        let projectSteps = {...this.state.projectSteps};
        //take out the dropped step

        let dropped = projectSteps[result.source.droppableId].splice(result.source.index, 1);
        //insert into destination array
        projectSteps[result.destination.droppableId].splice(result.destination.index, 0, dropped[0]);

        let sortUpdate = projectSteps[result.destination.droppableId].map((i, idx)=>{
            return {...i, sortKey : idx};
        })
        
        this.props.bulkUpdateSortOrderAndProjectId(sortUpdate, result.destination.droppableId);
      
    }

    render(){

        const renderDay = ()=>{
            if(moment().isSame(this.state.start, 'day')){
                return ' today'
            }
            else {
                return moment().to(moment(this.state.start)) +' time'
            }
        }
        return (
            <div className="Focus">
            
                <ZoneTitle>Daily Focus</ZoneTitle>
                <div className="Focus-SwitchBox">
                    <FocusDaySwitch 
                        date={this.state.start}
                        onChange={(newDate)=>{
                            this.setState({
                                start : moment(newDate).startOf('day').toISOString(),
                                end : moment(newDate).endOf('day').toISOString()
                            })
                        }}
                    
                    />
                    {/* <ZoneSubTitle style={{marginBottom:20, marginTop:20}}>Thing to focus on {renderDay()}</ZoneSubTitle> */}
                </div>

                <div className="cols">
                        <MediaQuery query="(min-width: 698px)">
                            <div className="bd">
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <BrainDumpPanel>
                                        <BrainDumpHeader></BrainDumpHeader>
                                            <BraindumpInputBox placeholder="What's on your mind?" style={{marginBottom:10, marginTop:10}} />
                                            <PlanList project={{ id : "brainDump"}} steps={this.props.brainDumpSteps}></PlanList>
                                    </BrainDumpPanel>
                                    
                                </DragDropContext>
                            </div>
                        </MediaQuery>
                        <div className="primary">
                            <div className="slides">
                                {this.renderTasks()}
                            </div>
                        
                        </div>
                        <div className="secondary">
                        <DailyLogs date={this.state.start} ></DailyLogs>
                        </div>
                </div>
                
                
                
                

                <PlanStepEditor></PlanStepEditor>
                <TimeLogger onComplete={()=>{
                   this.props.setSelectedPlanStep(null);
                }} />


                <style jsx>{`

                    
                    .Focus {
                        margin:20px;
                        flex:1;
                        ${fonts.regular}
                        display: flex;
                        flex-direction: column;
                        padding-left: 340px;
                    }   
                    .Focus-SwitchBox {
                        margin:20px 0px;
                        
                    } 
                    .slides {
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                    .cols {
                        flex:1;
                        display: flex;
                        justify-content: space-between;
                        overflow: hidden;
                        
                    }
                    .primary {
                        flex:1;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        overflow-x: hidden;
                        padding:0px 20px 0px 0px;
                        
                    }
                    .secondary {
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        overflow-x: hidden;
                        width: 300px;
                        padding-left:10px;
                    }

                   

                    @media all and (max-width:959px){
                        .Focus {
                            padding-top:40px;
                        }
                        .cols {
                            display: flex;
                            flex-direction: column;
                        }
                        .primary {
                            margin-right:0px;
                        }
                        .secondary {
                            display: none;
                        }
                    }
                    @media all and (max-width:800px){
                        .Focus {
                            padding-left: 290px;
                        } 
                    }
                    @media all and (max-width:768px){
                        .primary {
                            padding:0px 0px 0px 0px;
                        }
                        .secondary {
                            display: none;
                        }
                        .Focus {
                            padding-left: 0px;
                            padding-top:0px;
                        } 

                        .Focus :global(.BrainDumpPanel){
                            display: none;
                        }
                    }
                  
                `}</style>

            </div>
        )
    }
}

Focus.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        events : state.event.allEvents,
        brainDumpSteps : state.plan.planStepsByProject.brainDump
    };
}

export default  connect(mapStateProps, { bulkUpdateSortOrderAndProjectId, setSelectedPlanStep })(Focus);

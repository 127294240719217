import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {scaleLinear} from 'd3-scale';
import interact from 'interactjs';
import { deleteTimeBlock, saveTimeBlock } from '../../actions/timeActions';
import {Draggable, TweenMax} from 'gsap/all';

import resizeSrc from './../../assets/img/resize-timeblock.svg';
import deleteSrc from './../../assets/img/delete-timeblock.svg';
import { colors } from '../../assets/styles/theme';

function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
      width: rect.width,
      height: rect.height
    };
  }


class WeekDayTimeBlock2 extends React.Component {

    constructor(props){

        super(props);
        this.blockHeight = props.blockHeight || 15;
        this.yScale = scaleLinear().domain([0, 86400]).range([0, (96 * this.blockHeight)]).clamp(true);
        this.yScaleReverse = scaleLinear().domain([0, (96 * this.blockHeight)]).range([0, 86400]).clamp(true);
        this.state = {
            y : 0,
            height: 0
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        
        let {block} = this.props;

        console.log('block', block);
        
        let y = this.yScale(this.props.block.start);
        let height = this.yScale(block.end - block.start)+'px';

       
        // this.node.style.webkitTransform = this.node.style.transform = 'translate(0px,' + y + 'px)';
        // this.node.style.height = height;
        // this.node.setAttribute('data-x', 0);
        // this.node.setAttribute('data-height', height);
        // this.node.setAttribute('data-y', y);

        var gridTarget = interact.createSnapGrid({
            x: 100,
            y: this.blockHeight,
          });

          TweenMax.set(this.node, { y : this.yScale(this.props.block.start), height : height });

          Draggable.create(this.node, {type:"x,y", edgeResistance:0.65, bounds:"#container", throwProps:true});

        //   Draggable.create('.WeekDayTimeBlock2', {
        //     onDrag: ()=>{
        //         let dragRect = offset(this.resizeHandleNode);
        //         console.log(dragRect)
        //         // TweenMax.set(this.node, {
        //         //     height: dragRect.top + dragRect.height - conRect.top,
        //         //     width: dragRect.left + dragRect.width - conRect.left
        //         // });
        //         console.log('drag', drag);
        //     },
        //   });

          Draggable.create('.WeekDayTimeBlock2', {
                type:"x,y",
              liveSnap:false,
                snap: {
                    x: function(endValue) {
                        return endValue
                        // return Math.round(endValue / gridWidth) * gridWidth;
                    },
                    y: function(endValue) {
                        return endValue
                        // return Math.round(endValue / gridHeight) * gridHeight;
                    }
                }
          })
          
    }

    componentDidUpdate(newProps){
    }
   
    getPosition(event) {
        if(event.touches){
            return {
                x: event.touches[0].pageX || event.screenX || 0,
                y: event.touches[0].pageY || event.screenY || 0
            };
        } else {
            return {
                x: event.pageX || event.screenX || 0,
                y: event.pageY || event.screenY || 0
            };
        }

    }

   

    render(){

        const getFill = ()=>{
            if(this.props.timeBucketsByKey && this.props.block.timeBucketId && this.props.timeBucketsByKey[this.props.block.timeBucketId]){
                return this.props.timeBucketsByKey[this.props.block.timeBucketId].color;
            } else {
                return colors.mid;
            }
        }
        return (
            <div
                ref={(node)=>this.node = node}
                style={{background : getFill()}}
                className="WeekDayTimeBlock2"
            >
                
                <div className="WeekDayTimeBlock2-delete"  onClick={()=>this.props.deleteTimeBlock(this.props.block)}>
                    <img src={deleteSrc} alt=""/>
                </div>
                <div className="WeekDayTimeBlock2-handle" ref={(node)=>{this.resizeHandleNode = node}} >
                    <img src={resizeSrc} alt=""/>
                </div>
                
                <style jsx global>{`
                    .WeekDayTimeBlock2 {
                        position: absolute;
                        top:0px;
                        left:40px;
                        right:0px;
                        max-width: 80px;
                        font-size:10px;
                        opacity: 0.90;
                        overflow: hidden;
                        border-top:1px solid #fff;
                        user-select: none;
                        cursor: pointer;
                        max-width: 50px;
                    }
                    .WeekDayTimeBlock2.active {
                        box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
                        z-index: 1;
                    }
                    
                    .WeekDayTimeBlock2-delete {
                        position: absolute;
                        top:0px;
                        right:0px;
                        left:0px;
                        height: ${this.props.blockHeight-1}px;
                        background:rgba(255,255,255,0.3);
                        text-align: center;
                        color:rgb(39, 39, 39);
                        font-weight: 700;
                        text-align: right;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        padding-right:1px;
                    
                    }
                    .WeekDayTimeBlock2-delete  img {
                        width:${this.props.blockHeight-4}px;
                        opacity: 0.7;
                    }
                    .WeekDayTimeBlock2-handle {
                        position: absolute;
                        bottom:0px;
                        left:0px;
                        padding-top:2px;
                        text-align: left;
                        right:${this.props.blockHeight+5}px;
                        height:${this.props.blockHeight-1}px;
                        width:${this.props.blockHeight}px;
                        background:rgba(255,255,255,1);
                        box-shadow: inset -1px 1px 3px rgba(0,0,0,0.5);
                        border-radius : 0px ${this.props.blockHeight}px 0px 0px;
                    }
                    .WeekDayTimeBlock2-handle  img {
                        width:${this.props.blockHeight-4}px;
                        position: relative;
                        left:-2px;
                    }
                `}</style>
            </div>
        )
    }
}

WeekDayTimeBlock2.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        timeBlocks : state.time.timeBlocks,
        timeBucketsByKey : state.time.timeBucketsByKey
    };
}

export default  connect(mapStateProps, { deleteTimeBlock, saveTimeBlock })(WeekDayTimeBlock2);

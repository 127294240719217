import React from 'react';
import PropTypes from 'prop-types';
import { getTimeString } from '../../../libs/stringUtils';
import { fonts, colors } from '../../../assets/styles/theme';

class AllocatedTimeDisplay extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        if(!this.props.children) return null;

        return (
            <div className="AllocatedTimeDisplay">
                <div className="icon">
                    <svg viewBox="0 0 512 512"><path d="m403.573 136.711 23.902-23.902c7.811-7.811 7.811-20.474 0-28.284s-20.474-7.811-28.284 0l-27.514 27.514c-28.75-18.223-61.375-29.335-95.677-32.454v-39.585h58.667c11.046 0 20-8.954 20-20s-8.954-20-20-20h-157.334c-11.046 0-20 8.954-20 20s8.954 20 20 20h58.667v39.585c-109.422 9.95-196.667 101.877-196.667 215.748 0 119.745 96.905 216.667 216.667 216.667 119.745 0 216.667-96.904 216.667-216.667 0-60.242-24.428-116.996-69.094-158.622zm-147.573 335.289c-97.414 0-176.667-79.252-176.667-176.667s79.252-176.667 176.667-176.667 176.667 79.252 176.667 176.667-79.253 176.667-176.667 176.667zm20-294.667v118c0 11.046-8.954 20-20 20s-20-8.954-20-20v-118c0-11.046 8.954-20 20-20s20 8.955 20 20z"/></svg>
                </div>
                {getTimeString(this.props.children)}
                <style jsx>{`
                    .AllocatedTimeDisplay {
                        ${fonts.time}    
                        color:${colors.dark};
                        font-size:15px;
                        display: flex;
                        align-items: center;
                    }
                    .icon {
                        margin-right:2px;
                        margin-top:3px;
                    }
                    .icon svg {
                        width:15px;
                        height: 15px;
                    }

                `}</style>
            </div>
        )
    }
}

AllocatedTimeDisplay.propTypes = {
    
}

export default AllocatedTimeDisplay;
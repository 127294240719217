import {combineReducers} from 'redux';
import appReducer from './appReducer';
import timeReducer from './timeReducer';
import projectReducer from './projectReducer';
import userReducer from './userReducer';
import planReducer from './planReducer';
import logReducer from './logReducer';
import eventReducer from './eventReducer';
import braindumpReducer from './braindumpReducer';
const rootReducer = combineReducers({
    app : appReducer,
    time : timeReducer,
    project : projectReducer,
    user : userReducer,
    plan : planReducer,
    log : logReducer,
    event : eventReducer,
    braindump : braindumpReducer
});

export default rootReducer;


import firebase from 'firebase';

export const initialiseBraindump = (user)=> {
    return async (dispatch, getState)=> {
        firebase.firestore()
        .collection(`users/${user.uid}/planSteps`)
        .where('projectId', '==', "brainDump")
        .where('complete', '==', false)
        .orderBy('sortKey', 'asc')
        .orderBy('updatedAt', 'asc')
        .onSnapshot((data)=>{
            let steps = [];
            data.forEach((r)=>{
                steps.push(r.data());
            })
            dispatch({
                type: 'BRAINDUMP_STEPS_UPDATED',
                payload : steps
            });
    
        })
        
        

    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/theme';
import moment from 'moment';
class TrialStatus extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        if(!this.props.permissions || !this.props.permissions.isInTrial) return null;
        return (
            <div className="TrialStatus">
                <p><span>Free trial Edition:</span> Expires {moment(this.props.permissions.trialEnds.toDate()).format('dddd, MMMM Do YYYY')}</p>
                <style jsx>{`
                    .TrialStatus {
                        background: ${colors.primary};
                        color :${colors.white};
                        padding:2px 20px;
                        text-align: right;
                        font-weight: 300;
                    }
                    span {
                        font-weight: 700;
                    }
                `}</style>
            </div>
        )
    }
}


TrialStatus.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        permissions : state.app.permissions
    };
}

export default  connect(mapStateProps, {  })(TrialStatus);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, effects } from '../../assets/styles/theme';
import {withRouter} from 'react-router-dom';
import SideMenuButton from './SideMenuButton';
import { logoutUser } from '../../actions/authActions';
import SequenceTrigger from './SequenceTrigger';
import { TweenMax } from 'gsap/all';
import './../../gsap-bonus/DrawSVGPlugin';
import MediaQuery from 'react-responsive';

let logo = require('./../../assets/img/helm-logo.svg');

class MobileMenu extends React.Component {

    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.isActive = this.isActive.bind(this);
        this.goTo = this.goTo.bind(this);
        this.state = {
            open : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        TweenMax.set('#top', {drawSVG : "0% 70%", rotation : -45, svgOrigin : "3 3"})
        TweenMax.set('#mid', { drawSVG : "0% 100%"})
        TweenMax.set('#bottom', {drawSVG : "0% 70%",rotation : 45, svgOrigin : "3 27"})

    }

    isActive(path){
        // console.log(this.props.location.pathname, path);
        if(path=='/'){
            return (this.props.location.pathname==path);
        }
        return (this.props.location.pathname.indexOf(path)===0);
    }

    toggle(){
        // console.log(this.state.open)
        let open = !this.state.open;
        if(!open){
            TweenMax.to('#top', 0.3, {drawSVG : "0% 70%", rotation : -45, svgOrigin : "3 3"})
            TweenMax.to('#mid', 0.3, { drawSVG : "0% 100%"})
            TweenMax.to('#bottom', 0.3, {drawSVG : "0% 70%",rotation : 45, svgOrigin : "3 27"})
        } else {
            TweenMax.to('#top', 0.3, {drawSVG : "0% 100%",rotation : 0, svgOrigin : "3 3"})
            TweenMax.to('#mid', 0.3, { drawSVG : "50% 50%"})
            TweenMax.to('#bottom', 0.3, {drawSVG : "0% 100%",rotation : 0, svgOrigin : "3 27"})
        }
        this.setState({open : open});

    }
    
    goTo(path){
        this.toggle()
        this.props.history.push(path)
    }
    render(){
        return (
            <div className="MobileMenu">
                <div className="header">
                    <div className="logo">
                        <a href=""><img src={logo} alt=""/></a>
                    </div>
                    <div className="toggle" >
                        <SequenceTrigger mobile={true}></SequenceTrigger>
                        <div className="toggle-button-wrap"  onClick={this.toggle}>
                            <svg className="toggle-svg" viewBox="0 0 30 30">
                                <line id="top" x1="3" y1="3" x2="27" y2="27" fill="none" stroke={colors.primary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
                                <line id="bottom" x1="3" y1="27" x2="27" y2="3" fill="none" stroke={colors.primary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
                                <line id="mid" x1="3" y1="15" x2="27" y2="15" fill="none" stroke={colors.primary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
                            </svg>
                        </div>
                        

                    </div>
                {/* onClick={()=>{this.props.history.push(this.props.route)}} */}
                </div>
               
                <div className={(this.state.open)? 'navigation open' : 'navigation'}>

                <div 
                    className={(this.isActive('/')) ? "menuButton active" : "menuButton"}
                    onClick={()=>{this.goTo('/')}}
                    >Focus
                </div>

                <div 
                    className={(this.isActive('/plans')) ? "menuButton active" : "menuButton"}
                    onClick={()=>{this.goTo('/plans')}}
                    >Plans
                </div>
                <MediaQuery query="(max-width:769px)">
                    <div 
                        className={(this.isActive('/milestones')) ? "menuButton active" : "menuButton"}
                        onClick={()=>{this.goTo('/milestones')}}
                        >Milestones
                    </div>
                </MediaQuery>
               

                <div 
                    className={(this.isActive('/master-plan')) ? "menuButton active" : "menuButton"}
                    onClick={()=>{this.goTo('/master-plan')}}
                    >Master Plan
                </div>

 <div 
                    className={(this.isActive('/time')) ? "menuButton active" : "menuButton"}
                    onClick={()=>{this.goTo('/time')}}
                    >Time
                </div>

                

{/*             
                   <div 
                className={(this.isActive(this.props.path)) ? "SideMenuButton active" : "SideMenuButton"}
                onClick={()=>{this.props.history.push(this.props.route)}}
                >
                
                
                {this.props.name}

                <style jsx>{`
                        .SideMenuButton {
                            padding-top:20px;
                            padding-bottom:10px;
                            padding-right:20px;
                            text-align: right;
                            position: relative;
                            font-size: 17px ;
                            color : ${colors.mid};
                            cursor: pointer;
                        }
                        .SideMenuButton:hover:not(.active) {
                            color:${colors.white};
                        }
                        
                        .SideMenuButton:before {
                            position: absolute;
                            content: "";
                            display: block;
                            width:calc(100%);
                            bottom:0px;
                            background: #fff;
                            height:1px;
                            transform: translateX(-20px);
                            transition: all 0.3s;
                            opacity: 0.1;
                        }  
                        .SideMenuButton:after {
                            position: absolute;
                            content: "";
                            display: block;
                            width:7px;
                            height:7px;
                            bottom:-3px;
                            right:-3px;
                            background: ${colors.primary};
                            border-radius: 4px;
                            transform: translateX(0px);
                            transition: all 0.3s;
                            opacity: 0;
                            box-shadow: 1px 1px 2px rgba(44, 44, 44, 0.5);
                        }  
                        .SideMenuButton.active:after {
                            opacity: 1;
                        }  
                        .SideMenuButton.active:before {
                            opacity: 1;
                            width:calc(100% + 20px);
                            background: ${colors.primary};
                        }  
                        p {
                            font-size: 20px ;
                            color : ${colors.mid}
                        }
                `}</style>
            </div>

                    
                    {/* <div className="navigation-item">
                        name='Focus'
                        active={this.props.location.pathname==='/'}
                        onClick={()=>{this.props.history.push('/')}}
                </div> */}
                </div>
                <style jsx>{`
                    .MobileMenu {
                        position: fixed;
                        z-index: 1000;
                    }
                    .header {
                        position: fixed;
                        top:0px;
                        left:0px;
                        right:0px;
                        height: 60px;
                        background: ${colors.dark};
                        display: flex;
                        padding-left:10px;
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                    }
                    .logo {
                        width:90px;
                        
                    }
                    .toggle {
                        display: flex;
                        position: absolute;
                        right:0px;
                        top:0px;
                        height: 60px;
                        align-items: center;

                    }
                    .toggle-button-wrap {
                        height: 60px;
                        padding-right:10px;
                        padding-left:10px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .toggle-svg {
                        width:30px;
                        height: 30px;
                        display: block;
                        margin-left:5px;
                    }
                    .navigation {
                        position: fixed;
                        top:60px;
                        left:0px;
                        bottom:0px;
                        right:0px;
                        background: ${colors.dark};
                        transition: all 0.3s;
                        transform: translateX(100%);
                    }
                    .navigation.open {
                        transform: translateX(0%);
                    }

                    .menuButton {
                        
                            padding-top:15px;
                            padding-bottom:15px;
                            padding-left:10px;
                            text-align: left;
                            position: relative;
                            font-size: 17px ;
                            color : ${colors.mid};
                            cursor: pointer;
                            border-bottom:1px solid #353535;

                        }
                    .menuButton.active  {
                        color : ${colors.primary};
                        border-bottom:1px solid ${colors.primary};
                    }
                   
                `}</style>
            </div>
        )
    }
}

MobileMenu.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  withRouter(connect(mapStateProps, {  })(MobileMenu));

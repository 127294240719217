import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../assets/styles/theme';

class PinnedIcon extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <span className="PinnedIcon" style={{...this.props.style}} onClick={this.props.onClick}>
                <svg width="18" height="18" viewBox="0 0 18 18">
                    <path fill={(this.props.active)? colors.primary : colors.mid } d="M.62,7.35a.3.3,0,0,1,.19-.23,7.39,7.39,0,0,1,2.75-.53,7.91,7.91,0,0,1,3.07.62l4.71-4A3.54,3.54,0,0,1,12,.72.35.35,0,0,1,12.22.6a.34.34,0,0,1,.24.09L17.3,5.53a.33.33,0,0,1,0,.45l0,0a3.36,3.36,0,0,1-2.12.66h-.33l-4,4.74a8.3,8.3,0,0,1,.1,5.81.32.32,0,0,1-.41.18.41.41,0,0,1-.11-.07L5.75,12.69,1.45,17A.31.31,0,0,1,1,17a.3.3,0,0,1,0-.43l4.3-4.3L.71,7.64A.28.28,0,0,1,.62,7.35Z" />
                </svg>
                <style jsx>{`

                `}</style>
            </span>
        )
    }
}

PinnedIcon.propTypes = {
    
}

export default PinnedIcon;
import firebase from 'firebase';
import moment from 'moment';
import { logEvent } from '../analytics';

export const updateSearchString = (string)=>{
    return  (dispatch, getState)=> {
        dispatch({
            type: 'UPDATE_SEARCH_STRING',
            payload : string
        });
    }
}

export const deleteLog = (logId)=>{
    return  async (dispatch, getState)=> {
        let user = getState().app.authedUser;
        await firebase.firestore().collection('users').doc(user.uid).collection('projectLogs').doc(logId).delete();
        dispatch({
            type: 'LOG_ITEM_DELETED',
            payload : logId
        });
    }
}

export const saveLog = (log)=> {
    return (dispatch, getState)=> {
        return new Promise(async (resolve, reject)=>{
            let user = getState().app.authedUser;
            let ref;
            if(!log.id){
                ref = firebase.firestore().collection('users').doc(user.uid).collection('projectLogs').doc();
                log.id = ref.id;
                log.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            } else {
                ref = firebase.firestore().collection('users').doc(user.uid).collection('projectLogs').doc(log.id);
            }
            log.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            
            logEvent('userUpdatedLog', { logId : log.id, type : log.type });
            try {
                await ref.set(log, {merge : true});
                dispatch({
                    type: 'USER_PROJECT_LOG_SAVED',
                    payload : log
                });
                resolve(log);
            }
            catch(e){
                console.error(e);
                dispatch({
                    type: 'USER_PROJECT_LOG_SAVE_ERROR',
                    payload : e
                });
                reject();
            }


        })
    }
}

export const stopLogListener = ()=>{
    return async (dispatch, getState)=> {

        let oldListener = getState().log.logListener;
        let user = getState().app.authedUser;

        if(oldListener){ oldListener(); }

        dispatch({
            type: 'PROJECT_LOG_LISTENER_UNLOADED'
        });
    }
}
export const loadProjectLog = (id)=> {
    return async (dispatch, getState)=> {

        let oldListener = getState().log.logListener;
        let user = getState().app.authedUser;

        if(oldListener){ oldListener(); }


        dispatch({
            type: 'LOADING_PROJECT_LOG',
            payload : id
        });

        try {
            let logListener = await firebase.firestore().collection('users').doc(user.uid)
            .collection('projectLogs')
            .where('projectId', "==", id)
            .orderBy('createdAt', 'desc')
            .onSnapshot(
                (snapShot)=>{
                    let logs = [];
                    snapShot.forEach((log)=>{
                        logs.push(log.data());
                    });
                    dispatch({
                        type: 'PROJECT_LOG_ITEMS_UPDATE',
                        payload : logs
                    });
                }
            )
            //dispatch the time bucket listener
            dispatch({
                type: 'PROJECT_LOG_LISTENER_UPDATE',
                payload : logListener
            });

        }
        catch(e){
            console.error(e);
        }


    }
}


export const loadDailyLog = (date)=> {
    return async (dispatch, getState)=> {

        let oldListener = getState().log.logListener;
        let user = getState().app.authedUser;

        if(oldListener){ oldListener(); }

        let start = new moment(date).startOf('day').toDate();
        let end = new moment(date).endOf('day').toDate();
        console.log('start', start, end);

        dispatch({
            type: 'LOADING_PROJECT_LOG',
            payload : date
        });

        try {
            let logListener = await firebase.firestore().collection('users').doc(user.uid)
            .collection('projectLogs')
            .where('type', "==", 'time')
            .where('createdAt', ">=", start)
            .where('createdAt', "<=", end)
            .orderBy('createdAt', 'asc')
            .onSnapshot(
                (snapShot)=>{
                    let logs = [];
                    snapShot.forEach((log)=>{
                        logs.push(log.data());
                    });
                    dispatch({
                        type: 'PROJECT_LOG_ITEMS_UPDATE',
                        payload : logs
                    });
                }
            )
            //dispatch the time bucket listener
            dispatch({
                type: 'PROJECT_LOG_LISTENER_UPDATE',
                payload : logListener
            });

        }
        catch(e){
            console.error(e);
        }


    }
}
import React from 'react';
import PropTypes from 'prop-types';
import { colors, fonts } from '../../../assets/styles/theme';

import { getContrastingFill } from '../../../libs/colorUtils';
class ProjectListItem extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        let projColour = this.props.project.color || colors.white;
        console.log(' getContrastingFill(projColour)',  getContrastingFill(projColour));
        return (
            <div>
                <div className="project-item"
                    onClick={this.props.action}
                    style={{background : projColour}}
                >
                    <p className="title"
                        style={{color : getContrastingFill(projColour)}}
                    
                    >{this.props.project.title}</p>

                    <button >
                    <svg viewBox="0 0 30 30">
                        <path className="icon" d="M13.7,9.22h0a1.35,1.35,0,0,0-.89-.34,1.3,1.3,0,0,0-.88,2.27h0L16.12,15l-4.19,3.85h0a1.31,1.31,0,0,0,.88,2.28,1.36,1.36,0,0,0,.89-.35h0L19,16A1.33,1.33,0,0,0,19,14ZM15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm0,25.38A11.38,11.38,0,1,1,26.38,15,11.4,11.4,0,0,1,15,26.38Z"/>
                    </svg>
                    </button>

                </div>
                <style jsx>{`
                    .project-item {
                        padding:10px;
                        
                        box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
                        border-radius: 5px;
                        margin-bottom:5px;
                        position: relative;
                        display: flex;
                        align-items: center;
                    }
                    .title {
                        ${fonts.bold}
                        margin-bottom:0px;
                    }
                    button {
                        position: absolute;
                        right:0px;
                        top:0px;
                        bottom: 0px;
                        width: 40px;
                        background: ${colors.white};
                        border:none;
                        border-radius: 0px 5px 5px 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    button:focus, button:active {
                        outline: none;
                    }
                    svg {
                        height: 30px;
                        width: 30px;
                    }
                    button:hover .icon {
                        fill : ${colors.white}
                    }
                    .icon {
                        transition: all 0.3s;
                        fill : ${colors.dark}
                    }
                `}</style>
            </div>
        )
    }
}

ProjectListItem.propTypes = {
    
}

export default ProjectListItem;
import React from 'react';
import PropTypes from 'prop-types';
import { fonts, colors } from '../../assets/styles/theme';

class ZoneLabel extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="ZoneLabel" style={{...this.props.style}}>
                {this.props.children}
                <style jsx>{`
                    .ZoneLabel {
                        font-size:13px;
                        ${fonts.bold}
                        color:${colors.dark};
                        margin-bottom:5px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

ZoneLabel.propTypes = {
    
}

export default ZoneLabel;
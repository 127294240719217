import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PopUp from './PopUp';
import { setSelectedPlanStep, saveStep, deleteStep, setSelectedPlanStepId } from '../../actions/planAction';
import ZoneLabel from './ZoneLabel';
import ZoneSubTitle from './ZoneSubTitle';

import { Form, Input, TextArea, Checkbox, Button, Icon, IconGroup } from 'semantic-ui-react';
import DateSelector from './DateSelector';
import Slider, { Range } from 'rc-slider';
import { colors } from '../../assets/styles/theme';
import TimeBucketDropDown from './TimeBucketDropDown';
import SwitchBox from './SwitchBox';
import TimeEditor from './TimeEditor';
import DeleteButton from './DeleteButton';
import { withRouter } from 'react-router-dom';
import DateTimeSelector from './DateTimeSelector';
import TimeLogger from './TimeLogger';
import { createTimeNewLog } from '../../actions/timeActions';
import { logEvent } from '../../analytics';

import { generateString, parseString } from '../../libs/stringUtils';
import AllocatedTimeDisplay from '../global/AllocatedTimeDisplay/AllocatedTimeDisplay';
import FlipMove from 'react-flip-move';
import PlanStepSheduledEvents from '../global/PlanStepEditor/PlanStepSheduledEvents';
import { updateActionEventsOnActionDoneChange } from '../../actions/eventAction';


class PlanStepEditor extends React.Component {

    constructor(props){
        super(props);
        this.updateKey = this.updateKey.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.deleteStep = this.deleteStep.bind(this);

        this.state = {
            step : {},
            localChanges : false,
            composite : '',
            localTime : null,
            localType : null
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
    
    }
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }


    setInternals(props){
        let step = props.selectedStep || {};

        // step.composite = generateString(step.action, step.type, step.timeRemaining);
        // step.localTime = 

        this.setState({
                step : step, 
                composite : generateString(step.action, step.type, step.timeRemaining), 
                localTime : step.timeRemaining,
                localType : step.type
            });
    }
    
    updateKey(key, value, force){
        let step = this.state.step;
        step[key] = value;
        
        if(key=="complete"){
            this.props.updateActionEventsOnActionDoneChange(this.state.step.id, value);
        }


        this.setState({
            step : step,
            localChanges : true
        }, ()=>{
            if(force){
                this.saveChanges();
            }
        });
        
        let valueExists = (value) ? true : false;
        logEvent('userUpdatedPlanStep', { key : key, stepId : step.id, valueExists : valueExists });

    }

    async deleteStep(){
        this.props.deleteStep(this.state.step.id);
        this.props.setSelectedPlanStep(null);
    }

    async saveChanges(){

        return new Promise(async (resolve, reject)=>{
            if(this.state.localChanges){
                try {
                    let step = this.state.step;

                    let parsed = parseString(this.state.composite);
                    step.action = parsed.title;
                    step.type = parsed.type;
                    step.timeRemaining = parsed.time;

                    //if the allocation matches the project, remove it.
                    if(step.allocateTo===this.props.project.allocateTo){
                        step.allocateTo=null;
                    }

                    await this.props.saveStep(step);
                    this.setState({localChanges : false});
                    resolve(true);

                }
                catch(e){
                    console.error(e);
                    reject();
        
                }
            } else {
                resolve(true);
            }
           
        })
        
    }
    
    render(){
        if(!this.props.selectedStep) return null;
        let project = this.props.project;
        let {action} = this.props.selectedStep;
        let step = this.state.step;

        const renderGoToProjectButton = ()=>{
            if(this.props.location.pathname.indexOf('/streams/')!==-1) return null;
            return(
                <Icon style={{marginLeft:10, cursor : 'pointer'}} name="arrow alternate circle right" onClick={()=>{this.props.setSelectedPlanStep(null);this.props.history.push(`/streams/${project.id}`)}}></Icon>
            )
        }

        
        const renderHoldTill = ()=>{
            if(step.type=='action' && this.state.localTime ){
                return (
                    <div key="renderHoldTill">
                        <ZoneLabel style={{marginTop:10}}>Hold till</ZoneLabel>
                        <DateSelector
                            value={step.holdTill}
                            onChange={(date)=>{ this.updateKey('holdTill', date, true) }}
                        />
                    </div>
                )
            }
            return null;
        }

        const renderImportance = ()=>{
            if(step.type=='action'  && this.state.localTime){
                return (
                    <div key="renderImportance">
                        <ZoneLabel style={{marginTop:10}}>Importance</ZoneLabel>
                            <div>
                                <Slider
                                    min={-1}
                                    max={1}
                                    step={0.1}
                                    defaultValue={0}
                                    value={step.personalImportance}
                                    onChange={(val)=>{this.updateKey('personalImportance', val, true)}}
                                    railStyle={{
                                        background:colors.mid
                                    }}
                                    trackStyle={{
                                        background: colors.primary,
                                        opacity:0
                                    }}
                                    handleStyle = {{
                                        borderColor : colors.primary,
                                        background : colors.primary
                                    }}
                                ></Slider>
                            </div>
                            <div className="slider-labels">
                                <span>Less important</span>
                                <span>More important</span>
                            </div>


                    </div>
                )
            }
            return null;
        }
        const renderActionVariants = ()=>{
            if(step.type=='action'  && this.state.localTime){
                return (
                    <div key="renderActionVariants">
                    
                            <ZoneLabel style={{marginTop:10}}>Do at fixed time</ZoneLabel>
                            <DateTimeSelector
                                value={step.doOn}
                                onChange={(date)=>{ this.updateKey('doOn', date, true) }}
                            />
                            
                            

                            <ZoneLabel style={{marginTop:10}}>Send to alternative time bucket</ZoneLabel>
                            <TimeBucketDropDown
                                default={this.props.project.allocateTo}
                                placeholder="Project default"
                                value={step.allocateTo}
                                onChange={(bucketId)=>{ this.updateKey('allocateTo', bucketId, true) }}
                            ></TimeBucketDropDown>
                    </div>
                )
            }
            return null;
        }
        const renderBlocker = ()=>{
            if ( (step.doOn || step.allocateTo) && step.type=='action'  && this.state.localTime ){
                return (
                    <ZoneLabel style={{marginTop:10}}>
                        <Checkbox style={{marginRight:10, paddingTop:10 }}  label="Next step waits for this to be completed" checked={step.blockSequence} onClick={()=>{ this.updateKey('blockSequence', !step.blockSequence, true)}} />   
                    </ZoneLabel>
                )
            }
            return null;
        }

        const renderTime = ()=>{
            if ( step.type=='action'  && this.state.localTime ){
                return (
                    <div key="renderTime">
                        <ZoneLabel style={{}}>Allocated time for this task</ZoneLabel>
                        {(!this.state.localTime)?<p className="step-note">Steps with no time allocated will be skipped when generating your master plan.</p> : '' }
                        <AllocatedTimeDisplay>{this.state.localTime}</AllocatedTimeDisplay>
                        
                    </div>
                    
                )
            }
            return null;
        }

        const renderLogTime = ()=>{
            if ( step.type=='action'  ){
                return (
                    <Button
                        content="Mark Done"
                        icon="check circle"
                        size="big"
                        color="blue"
                        compact
                        onClick={()=>{
                            this.props.createTimeNewLog(step.id, step.projectId, step.action, step.timeRemaining, 0, null )
                            
                        }}
                    ></Button>
                    
                )
            }
            return null;
        }

        const renderDueOn = ()=>{
            if ( (step.type=='action'  && this.state.localTime ) || this.state.localType=='milestone'  ){
                return (
                    <div>
                        <ZoneLabel style={{marginTop:10}}>Due on</ZoneLabel>
                        <DateSelector
                            value={step.dueOn}
                            onChange={(date)=>{ this.updateKey('dueOn', date, true) }}
                        />
                    </div>
                    
                )
            }
            return null;
        }
        const renderMarkedDone = ()=>{
            if(step.complete || step.type=="milestone"){
                return (
                    <ZoneLabel style={{marginBottom:20, textAlign:'left'}}>
                        <Checkbox  label="Marked done" checked={step.complete} onClick={()=>{this.updateKey('complete', !step.complete, true)}} />   
                    </ZoneLabel>
                )
            }

            
        }
        return (
            <PopUp
                visible={this.props.selectedStep}
                onClose={async ()=>{
                    this.saveChanges();
                    this.props.setSelectedPlanStep(null);
                }}
            >
            


                <div>
                <div className="PlanStepEditor">
                    <div className="main">
                        <ZoneSubTitle style={{color : project.color, paddingRight: 50}}>{project.title}{renderGoToProjectButton()}</ZoneSubTitle>
                        <ZoneLabel style={{marginTop:10}}>Step</ZoneLabel>
                        <Form
                            formNoValidate
                        >
                            <Input
                                value={this.state.composite}
                                fluid
                                size="large"
                                onChange={(e,v)=>{
                                    let elems = parseString(v.value);
                                    this.setState({
                                        composite : v.value,
                                        localTime : elems.time,
                                        localChanges : true,
                                        localType : elems.type
                                    });
                                }}
                                onBlur={this.saveChanges}

                            />
                            
                            <ZoneLabel style={{marginTop:10}}>Step Notes</ZoneLabel>
                            <TextArea
                                fluid="true"
                                autoHeight
                                style={{maxHeight:'30vh', minHeight:'80px', marginBottom:10}}
                                value={step.notes}
                                onChange={(e,v)=>{ this.updateKey('notes', v.value) }}
                                onBlur={this.saveChanges}
                            ></TextArea>

                            {renderTime()}

                            <PlanStepSheduledEvents 
                                actionId={step.id}
                                type={this.state.localType}
                                time={this.state.localTime}
                                color={project.color}
                                key={step.id}
                            >
                                <ZoneLabel style={{marginTop:20, textAlign:'left'}}>Scheduled Events</ZoneLabel>
                            </PlanStepSheduledEvents>



                            
                            
                        </Form>
                    </div>
                    <div className="side">
                        <Form>
                            
                          

                             <FlipMove
                                enterAnimation="accordionVertical"
                                leaveAnimation="accordionVertical"
                             >
                                {renderMarkedDone()}
                                {renderImportance()}
                                {renderHoldTill()}
                                {renderDueOn()}
                                {renderActionVariants()}
                                {renderBlocker()}
                             </FlipMove>
                            
                        </Form>
                    </div>
                    
                </div>
                
                <div className="controls">
                        <div>
                           
                            {renderLogTime()}
                           
                        </div>
                        <div>
                      
                        
                        <DeleteButton 
                                basic
                                size="small"
                                content="Delete"
                                onConfirm={this.deleteStep} />
                        <Button
                            onClick={()=>{this.props.setSelectedPlanStep(null)}}
                            color="green"
                            size="small"
                            icon="save"
                            

                        >
                            <Icon name="save"></Icon>
                            Save and Close</Button>
                        </div>
                        
                    </div>
                </div>
                {/* export const createTimeNewLog = (stepId, projectId, title, time, timeRemaining, eventId) */}
                

                <style jsx>{`

                    .PlanStepEditor {
                        padding:20px 20px 20px 20px;;
                        display:flex;
                        width:50vw;
                    }
                    .PlanStepEditor :global(.step-note){
                        color:${colors.mid};
                        font-style:italic;
                        max-width:320px;
                    }

                    
                   
                    .main {
                        flex:1;
                    }
                    .side {
                        width:270px;
                        padding-left: 20px;
                        padding-top:20px;
                    }

                    .PlanStepEditor :global(.slider-labels) {
                            display: flex;
                           
                        }
                        .PlanStepEditor :global(.slider-labels) :global(span) {
                        flex:1;
                        font-size:11px;
                        color:${colors.mid}
                        
                    }
                    .PlanStepEditor :global(.slider-labels) :global(span:nth-child(2)){
                        text-align: right;
                    }

                    .controls {
                        padding:0px 20px 20px 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items:flex-end;
                    }

                    @media all and (max-width:1150px){
                        .PlanStepEditor {
                            width:70vw;
                        }
                    }

                    @media all and (max-width:850px){
                        .PlanStepEditor {
                            width:85vw;
                        }
                    }

                    @media all and (max-width:620px){
                        .PlanStepEditor {
                            
                            width:100%;
                            display:block;
                        }
                        .side {
                            width:100%;
                            padding-left: 0px;
                        }
                    }
                    
                `}</style>
            </PopUp>

            
            
        )
    }
}

PlanStepEditor.propTypes = {
    
}

const mapStateProps = (state, props) => {
    return {
        selectedStep : state.plan.selectedPlanStep,
        project : (state.plan.selectedPlanStep) ? state.project.projectByKey[state.plan.selectedPlanStep.projectId] : {}
    };
}

export default  withRouter(connect(mapStateProps, { setSelectedPlanStep, saveStep, deleteStep, createTimeNewLog, setSelectedPlanStepId, updateActionEventsOnActionDoneChange })(PlanStepEditor));


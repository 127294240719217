const INITIAL_STATE = {
    profileLoaded : false,
    profile : {},
    profileListener : null
};

export default (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){
        
        
        case 'USER_PROFILE_UPDATE':
            return {...state, profile : action.payload, profileLoaded : true };

        case 'USER_PROFILE_LISTENER_UPDATE':
            return {...state, profileListener : action.payload };

        case 'USER_CREATED_HELM_CALENDAR':
                attrs.profile = {...state.profile};
                attrs.profile.helmCalendarId = action.payload;
                return {...state, ...attrs};
        case 'RESET':
            return {...INITIAL_STATE};

        default:
            return state;
    }
}
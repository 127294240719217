import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fonts, effects } from '../../assets/styles/theme';
import { editTimeBucket } from '../../actions/timeActions';

class TimeBucketSelectorButton extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        let { title, id, color } = this.props.bucket;
        return (
            <div className="TimeBucketSelectorButton"
                onClick={()=>{this.props.action(this.props.bucket)}}
                style={{
                    background : (this.props.activeBucket.id==id) ? color : "#fff"
                }}
            >
                <div className="title"
                    style={{
                        color  : (this.props.activeBucket.id==id) ?  "#fff" : color
                    }}
                >
                    {title}
                </div>
                <div className="icon" onClick={(e)=>{e.stopPropagation(); this.props.editTimeBucket(this.props.bucket)}}>
                    <svg viewBox="0 0 31 31" width="31" height="31">
                        <circle className="cls-1" cx="15.5" cy="15.5" r="15.5"
                            fill={
                                (this.props.activeBucket.id==id) ? "#fff" : color
                            }
                        />
                        <path
                            fill={
                                (this.props.activeBucket.id==id) ? color : "#fff"
                            }
                        d="M10.089,3.983l3.3,3.3L5.035,15.638l-3.3-3.3Zm5.8-.8L14.422,1.715a1.461,1.461,0,0,0-2.063,0l-1.41,1.41,3.3,3.3,1.645-1.645A1.125,1.125,0,0,0,15.894,3.187ZM.01,16.976a.376.376,0,0,0,.454.447l3.678-.892-3.3-3.3Z" transform="translate(8 6)"/>
                    </svg>

                </div>
                <style jsx>{`
                    .TimeBucketSelectorButton {
                        display: flex;
                        padding:5px;
                        align-items: center;
                        justify-content: space-between;
                        min-height: 40px;
                        border-radius: 2px;
                        min-width: 150px;
                        margin-bottom:10px;
                        cursor: pointer;
                        ${effects.shadow1}
                    }
                    .title {
                        flex:1;
                        ${fonts.bold}
                        padding-left:5px;

                    }
                    .icon {
                        width:31px;
                        height:31px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

TimeBucketSelectorButton.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        activeBucket : state.time.activeTimeBucket,
        timeBuckets : state.time.timeBuckets
    };
}

export default  connect(mapStateProps, { editTimeBucket })(TimeBucketSelectorButton);

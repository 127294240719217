const INITIAL_STATE = {
    steps : []
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'BRAINDUMP_STEPS_UPDATED':
            return {...state, steps : action.payload };
        default:
            return state;
    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import firebase from 'firebase';

import { loadFirebase, initPermissions } from '../actions/appActions';
import SignIn from './common/SignIn';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Time from './screens_v1/Time';

import { initDefaultWeek } from '../actions/timeActions';

import Loading from './common/Loading';
import ProjectList from './screens_v1/ProjectList';
import Project from './screens_v1/Project';

import { initProjects } from '../actions/projectAction';

import initReactFastclick from 'react-fastclick';
import SideMenu from './common/SideMenu';
import TrialStatus from './common/TrialStatus';
import CentralFramer from './common/CentralFramer';
import { initUserProfile } from '../actions/userActions';
import UserDetails from './common/UserDetails';
import OnBoarding from './common/OnBoarding';
import PlanEditor from './common/PlanEditor';
import Focus from './screens_v1/Focus';
import Test from './screens_v1/Test';
import 'rc-slider/assets/index.css';
import { initEvents } from '../actions/eventAction';
import MasterPlan from './screens_v1/MasterPlan';
import MediaQuery from 'react-responsive';
import MobileMenu from './common/MobileMenu';
import ProjectMobile from './screens_v1/ProjectMobile';
import MasterPlanMobile from './screens_v1/MasterPlanMobile';
import TimeMobile from './screens_v1/TimeMobile';
import MilestoneMobile from './screens_v1/MilestonesMobile';
import Plans from './screens_v1/Plans';
import { initialiseBraindump } from '../actions/braindumpAction';


class Helm3 extends React.Component {

    constructor(props){
        super(props);

    }

    async componentWillMount(){ 
        await this.props.loadFirebase();
        this.props.initPermissions();
        firebase.auth().onAuthStateChanged(async(user)=> {
            if(user){
                this.props.initDefaultWeek(user);
                this.props.initProjects(user);
                this.props.initUserProfile(user);
                this.props.initEvents(user);
                this.props.initialiseBraindump(user)
            }
           
        })
    }
    
    componentDidMount(){

        initReactFastclick();
     }

    render(){

      

        // user is not connected to firebase and auth hasn't been checked
        if(!this.props.connected || !this.props.authChecked) {
            return (
                <CentralFramer>
                    <Loading>Getting setup</Loading>
                </CentralFramer>
            )
        }

        // auth has been checked but the user is not logged in
        if(!this.props.authedUser) {
            return (
                <div className="Helm3">
                    <CentralFramer>
                        <SignIn />
                    </CentralFramer>
                    
                </div>
            )
        }
     
        // users permissions haven't been loaded yet
        if(!this.props.permissionsLoaded) {
            return (
                <CentralFramer>
                     <Loading>Getting set up</Loading>
                </CentralFramer>
            )
        }

        // users account is still being created
        if(!this.props.permissions.createdAt){
            return (
                <CentralFramer>
                     <Loading>Setting up your account</Loading>
                </CentralFramer>
            )
        }

        //users profile hasn't loaded yet
        if(!this.props.profileLoaded){
            return (
                <CentralFramer>
                    <Loading>Loading your stuff</Loading>
                </CentralFramer>
            )
        }
        
        // users profile has been loaded, but as yet we don't have a first name for them.
        if(!this.props.profile || !this.props.profile.firstName){
            return (
                <CentralFramer>
                    <UserDetails></UserDetails>
                </CentralFramer>
            )
        }

        // users profile has been loaded, but as yet we don't have a first name for them.
        if(!this.props.permissions.onboarded){
            return (
                <CentralFramer>
                    <OnBoarding />
                </CentralFramer>
            )
        }

      
        
        
        //all set - into the main app


        return (
            <Router>
                <div className="Helm3">
                    <MediaQuery query="(min-width: 960px)">
                        <SideMenu></SideMenu>
                    </MediaQuery>
                    
                    <div className="main-panel">
                        <MediaQuery query="(max-width: 959px)">
                            <MobileMenu></MobileMenu>
                        </MediaQuery>
                        <TrialStatus></TrialStatus>
                        <Route path="/" exact component={Focus} />
                        
                        <MediaQuery query="(min-width: 770px)">
                            <Route path="/time" exact component={Time} />
                        </MediaQuery>
                        <MediaQuery query="(max-width: 769px)">
                            <Route path="/time" exact component={TimeMobile} />
                        </MediaQuery>


                        
                        <Route path="/plans" exact component={ProjectList} />

                        <Route path="/plans_v2" exact component={Plans} />
                        
                        
                        
                        <MediaQuery query="(min-width: 770px)">
                            <Route path="/plans/:id" exact component={Project} />
                        </MediaQuery>
                        <MediaQuery query="(max-width: 769px)">
                            <Route path="/plans/:id" exact component={ProjectMobile} />
                        </MediaQuery>


                        <MediaQuery query="(min-width: 770px)">
                            <Route path="/master-plan" exact component={MasterPlan} />
                        </MediaQuery>
                        <MediaQuery query="(max-width: 769px)">
                            <Route path="/master-plan" exact component={MasterPlanMobile} />
                        </MediaQuery>
                        
                        
                        <Route path="/milestones" exact component={MilestoneMobile} />

                        <Route path="/test" exact component={Test} />
                        
                        <PlanEditor></PlanEditor>
                    </div>
                    
                
                    <style jsx>{`
                        .Helm3 {
                           display:flex;

                        }
                        .main-panel {
                            flex:1;
                            height: 100vh;
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                        }
                    `}</style>
                </div>
            </Router>
        )
    }
}

Helm3.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        connected : state.app.connected,
        connecting : state.app.connecting,
        authedUser : state.app.authedUser,
        authChecked : state.app.authChecked,
        permissionsLoaded : state.app.permissionsLoaded,
        permissions : state.app.permissions,
        profileLoaded : state.user.profileLoaded,
        profile : state.user.profile
    };
}

export default  connect(mapStateProps, {loadFirebase, initDefaultWeek , initProjects, initPermissions, initUserProfile, initEvents, initialiseBraindump })(Helm3);

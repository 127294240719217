import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sequenceEvents } from '../../../actions/eventAction';
import { Button, Icon } from 'semantic-ui-react';
import { fonts, colors } from '../../../assets/styles/theme';
import MediaQuery from 'react-responsive';
import { updateMenuState } from '../../../actions/appActions';

class SequenceTrigger extends React.Component {

    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    handleClick(){
        if(!this.props.sequencing){
            this.props.sequenceEvents();
            
        }
    }
    render(){

        const renderChangesFlag = ()=>{
            if(this.props.changes){
                return (
                    <span>
                        
                        <style jsx>{`
                            
                            span {
                                position: absolute;
                                top:-2px;
                                left:-5px;
                                background: ${colors.error};
                                font-size:12px;
                                width: 20px;
                                height: 20px;
                                ${fonts.bold}
                                line-height: 1em;
                                padding:2px;
                                border-radius: 10px;
                                color:${colors.white};
                                display: inline-block;
                                box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
                            }    
                        `}</style>
                    </span>
                )
            }
            return null
        }
        return (
            <div className="SequenceTrigger" style={{padding : (this.props.mobile)?0: 0}}>
                <MediaQuery query="(min-width: 769px)">
                    <Button
                        size={(this.props.mobile)? 'tiny' : 'medium'}
                        color="teal"
                        icon="calendar alternate"
                        content={(this.props.mobile)? 'Update Plan' : 'Update Plan'}
                        fluid={true}
                        onClick={this.handleClick}
                        loading={this.props.sequencing}
                    >
                    </Button>
                    {renderChangesFlag()}
                </MediaQuery>
                <MediaQuery query="(max-width: 768px)">
                    <p onClick={()=>{this.props.updateMenuState(true)}} style={{color:"#6D6D6D", padding:10, fontStyle : "italic", textAlign:'right'}}>LITE VERSION</p>
                </MediaQuery>

                
                <style jsx>{`
                    .SequenceTrigger {
                       position: relative;
                    }    
                    
                `}</style>
            </div>
        )
    }
}

SequenceTrigger.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        sequencing : state.event.sequencing,
        changes : state.plan.changes
    };
}

export default  connect(mapStateProps, { sequenceEvents, updateMenuState })(SequenceTrigger);

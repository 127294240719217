import * as Amplitude from 'amplitude-js';



export const initAnalytics = ()=>{
    Amplitude.getInstance().init('c8539a5cb5717a60b60ab08e5811d15f');
    Amplitude.getInstance().logEvent('App Loaded');
}


export const logEvent = (event, data)=>{
    let eventData = {...data};
    // console.log('track event', event, eventData);
    Amplitude.getInstance().logEvent(event, eventData);
}

export const setUserId = (uid)=>{
    // console.log('set user id', uid);
    Amplitude.getInstance().setUserId(uid);
}
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Popup } from 'semantic-ui-react';


class DeleteButton extends React.Component {   

    constructor(props){
        super(props);
        this.state = { isOpen: false }
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    handleOpen = () => {
    
        this.setState({ isOpen: true })
    }

    handleClose = () => {
        this.setState({ isOpen: false });
        this.props.onConfirm();
    }

    render(){
        return (
            <Popup
                    trigger={<Button basic onClick={this.handleOpen} size={this.props.size|| 'tiny'}
                    disabled={this.props.disabled || false}
                    color={(this.props.disabled) ? 'grey' : 'red' } icon='trash' content={this.props.content }/>}
                content={<Button color='red'  onClick={this.handleClose} content='Sure?' />}
                on='click'
                position='top center'
            />
        )
    }
}

DeleteButton.propTypes = {
    onConfirm : PropTypes.func.isRequired
}

export default DeleteButton;
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, effects } from '../../assets/styles/theme';
import {withRouter} from 'react-router-dom';
import SideMenuButton from './SideMenuButton';
import { logoutUser } from '../../actions/authActions';
import SequenceTrigger from './SequenceTrigger';


let logo = require('./../../assets/img/helm-logo.svg');

class SideMenu extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="SideMenu">
                <div className="logo">
                    <a href=""><img src={logo} alt=""/></a>
                </div>
                <div className="navigation">
                    <SideMenuButton name="Focus" route="/" />
                    <SideMenuButton name="Plans" route="/plans" />
                    <SideMenuButton name="Plans V2" route="/plans_v2" />
                    
                    <SideMenuButton name="Master Plan" route="/master-plan" />
                    <SideMenuButton name="Time" route="/time" />
                    
                    {/* <SideMenuButton name="Reflection" route="/reflection" /> */}

                    <SequenceTrigger></SequenceTrigger>
                    {/* <div className="navigation-item">
                        name='Focus'
                        active={this.props.location.pathname==='/'}
                        onClick={()=>{this.props.history.push('/')}}
                </div> */}
                </div>
                <div className="footButton">
                    <div className="logout" onClick={this.props.logoutUser}>Logout</div>
                </div>
                <style jsx>{`
                    .SideMenu {
                        background: ${colors.dark};
                        width:250px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        box-shadow:2px 0px 6px rgba(0,0,0,0.3);
                        z-index: 1;
                    }
                    .logo {
                        margin:20px 16px;
                        width:120px;
                        margin-left:auto;
                        padding-top:15%;
                        position: relative;
                        margin-bottom:20px;

                    }
                    .navigation {
                        flex:1;
                    }
                    .logo img {
                        position: absolute;
                        top:0px;
                        left:0px;
                        right:0px;
                        bottom:0px;
                    }
                    .footButton {
                        min-height: 55px;
                        background: #000;
                        color:${colors.mid};
                        padding:0px 20px;
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .logout {
                        cursor: pointer;
                        text-transform: uppercase;
                    }

                `}</style>
            </div>
        )
    }
}

SideMenu.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  withRouter(connect(mapStateProps, { logoutUser })(SideMenu));

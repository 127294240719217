import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { colors, effects } from '../../assets/styles/theme';
import { logEvent } from '../../analytics';
class FocusDaySwitch extends React.Component {

    constructor(props){
        super(props);
        this.incr = this.incr.bind(this);
        this.decr = this.decr.bind(this);

    }
    componentWillMount(){

        
    }
    
    componentDidMount(){ }

    incr(){
        this.props.onChange(moment(this.props.date).add(1, 'day').toISOString());
        logEvent('userSwitchedFocusDateTo', { type : 'incr', distance : moment(this.props.date).startOf('day').add(1, 'day').fromNow() })
        
    }
    decr(){
        this.props.onChange(moment(this.props.date).subtract(1, 'day').toISOString())
        logEvent('userSwitchedFocusDateTo', { type : 'decr', distance : moment(this.props.date).startOf('day').subtract(1, 'day').fromNow() })
    }

    render(){
        const getDisplayDate = ()=>{
            return (this.props.date) ? moment(this.props.date).format("dddd, MMMM Do YYYY") : moment().format("dddd, MMMM Do YYYY");

        }
        return (
            <div className="FocusDaySwitch">
            <div className="switch minus" onClick={this.decr}>
                    <Icon name="chevron circle left"></Icon>
                </div>
                <div className="display">
                    {getDisplayDate()}
                </div>

                <div className="switch plus" onClick={this.incr}>
                    <Icon name="chevron circle right"></Icon>
                </div>
                <style jsx>{`
                    .FocusDaySwitch {
                        display: flex;
                    }   
                    .FocusDaySwitch :global(*) {
                        ${effects.noSelect}
                    }    
                    .display {
                        color:${colors.white};
                        padding:0px 20px;
                        height: 34px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: ${colors.primary};
                        width: 260px;
                        position: relative;
                        z-index: 0;
                        margin-left:-2px;
                        margin-right:-2px;
                    }
                    .switch {
                        display: flex;
                        width:34px;
                        height: 34px;
                        align-items: center;
                        justify-content: center;
                        background:${colors.white};
                        ${effects.shadow1}
                        border-radius: 5px;
                        color: ${colors.primary};
                        font-size:20px;
                        cursor: pointer;
                        position: relative;
                        z-index: 1;
                        cursor: pointer;
                    }
                    .switch :global(i){
                        display: block;
                        margin:0px;
                    }
                    @media all and (max-width:500px){
                        .display {
                            font-size:13px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .FocusDaySwitch {
                            width:100%;
                        }
                        .display {
                            flex:1;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

FocusDaySwitch.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(FocusDaySwitch);

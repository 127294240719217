import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewProjectModal from './NewProjectModal';
import { withRouter, Link } from 'react-router-dom';
import { colors, fonts, effects } from '../../../assets/styles/theme';
import FlipMove from 'react-flip-move';
import ProjectListItem from './ProjectListItem';
import ProjectStepList from './../Project/ProjectStepList';
import ZoneSubTitle from '../ZoneSubTitle';
import { logEvent } from '../../../analytics';
class ProjectListLargeScreen extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            newProjectModal : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 

    }

    
    render(){
        const renderProjects = ()=>{
            return this.props.projects
                .filter((p)=>{
                    return !(p.archived);
                })
                .map((p)=>{
                return(
                    <div className="project" key={p.id}>
                        <ZoneSubTitle style={{textAlign:'center', marginBottom:20}}>{p.title}</ZoneSubTitle>
                        <ProjectStepList project={p} compact={true} 
                            rightHead={()=>{
                                return (
                                    <div className="view-project" onClick={()=>{this.props.history.push(`/plans/${p.id}`); logEvent('userLoadedPlan', { planId : p.id })}}>
                                        <span className="label">View Plan</span>
                                        <span className="icon">
                                            <svg className="icon-svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path fill={p.color || colors.primary} d="M17.56,5.62a8.66,8.66,0,0,0-3.18-3.18A8.53,8.53,0,0,0,10,1.27,8.53,8.53,0,0,0,5.62,2.44,8.66,8.66,0,0,0,2.44,5.62,8.53,8.53,0,0,0,1.27,10a8.53,8.53,0,0,0,1.17,4.38,8.66,8.66,0,0,0,3.18,3.18A8.53,8.53,0,0,0,10,18.73a8.53,8.53,0,0,0,4.38-1.17,8.66,8.66,0,0,0,3.18-3.18A8.53,8.53,0,0,0,18.73,10,8.53,8.53,0,0,0,17.56,5.62ZM14,9A1.33,1.33,0,0,1,14,11L8.7,15.78h0a1.36,1.36,0,0,1-.89.35,1.31,1.31,0,0,1-.88-2.28h0L11.12,10,6.93,6.16h0a1.3,1.3,0,0,1,.88-2.27,1.35,1.35,0,0,1,.89.34h0Z"/>
                                            </svg>
                                        </span>
                                    </div>
                                )
                            }}
                        ></ProjectStepList>
                        <style jsx>{`
                            .project {
                                width:380px;
                                padding:0px 10px;
                                overflow-y: auto;
                                display: flex;
                                flex-direction: column;
                                margin-bottom: -10px;
                                
                            }    
                            .view-project {
                                background: ${colors.white};
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding:5px;
                                border-radius: 5px;
                                ${effects.shadow1}
                                cursor: pointer;

                            }
                            .label {
                                padding:0px 5px;
                                line-height: 1em;
                                ${fonts.bold}
                                font-size: 12px;
                            }
                            .icon-svg {
                                display: block;
                            }
                        `}</style>
                    </div>
                )
                return (
                    <ProjectListItem project={p} key={p.id}  action={()=>{this.props.history.push(`/projects/${p.id}`)}} />
                )
            })
        }
        return (
            <div className="ProjectListLargeScreen">
                

                <div className="ProjectsList" style={{width : 380 * (this.props.projects.filter((p)=>{
                    return !(p.archived);
                }).length)+20}}>
                    {renderProjects()}
                </div>
                <style jsx>{`
                    .ProjectListLargeScreen {
                        margin:20px 0px;
                        padding:10px;
                        margin-right:-10px;
                        margin-bottom:-10px;
                        display: flex;
                        flex:1;
                        flex-direction: column;
                        overflow-x: scroll;
                        -webkit-overflow-scrolling: touch;
                        overflow-y: hidden;
                    } 
                    .ProjectsList {
                        margin-top:50px; 
                        flex:1;
                        display: flex;
                        overflow: hidden;
                       
                    }   
                `}</style>
            </div>
        )
    }
}

ProjectListLargeScreen.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projects
    };
}

export default  withRouter(connect(mapStateProps, {  })(ProjectListLargeScreen));

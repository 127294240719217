import React from 'react';
import PropTypes from 'prop-types';

class TimeLengthDisplay extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    // <TimeLengthDisplay
                                            
    // time={l.timeRemaining} prefix=" / "
    // zeroHides={true}
    // />

    render(){
        if(typeof this.props.time == 'undefined') return null;

        if(!this.props.time && this.props.zeroHides) return null;

        const renderLong = (hours, minutes)=>{
            if(hours === 0){
                hours = '0'
            } 
            // else if (hours < 10){
            //     hours = '0'+hours
            // }

            if(minutes === 0){
                minutes = '00'
            } else if (minutes < 10){
                minutes = '0'+minutes
            }
            return (
                <span className="TimeLengthDisplay">
                    {this.props.prefix}
                    {hours}.{minutes}
                </span>
            )
        }

        const renderShort = (hours, minutes)=>{
            if(minutes === 0){
                minutes = '00'
            } else if (minutes < 10){
                minutes = '0'+minutes
            }
            
            return (
                <span className="TimeLengthDisplay">
                    {this.props.prefix}
                    {(hours) ? hours+'.' : null }
                    {(minutes)}
                </span>
            )
        }

        const renderUnits = (hours, minutes)=>{
            if(minutes === 0){
                minutes = '00'
            } else if (minutes < 10){
                minutes = '0'+minutes
            }
            
            return (
                <span className="TimeLengthDisplay">
                    {this.props.prefix}
                    {(hours) ? hours+' hrs ' : null }
                    {(minutes)? minutes+' mins' : null }
                </span>
            )
        }


        let hours = Math.floor(this.props.time/60);
        let minutes = this.props.time % 60;

        if(this.props.units){
            return renderUnits(hours, minutes)
        }

        if(this.props.long){
            return renderLong(hours, minutes)
        } else {
            return renderShort(hours, minutes)
        }

        
       

        
    }
}

TimeLengthDisplay.propTypes = {
    zeroHides : PropTypes.bool,
    time : PropTypes.any.isRequired
}

export default TimeLengthDisplay;
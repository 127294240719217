import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment';
import calendar from './../../assets/img/calendar.svg';
import calendarClear from './../../assets/img/calendar-clear.svg';
import { colors, effects } from '../../assets/styles/theme';

class ExampleCustomInput extends React.Component {

    render () {
      return (
        <div className="DateSelectorInput" onTouchStart={this.props.onClick} onClick={this.props.onClick}>
             {this.props.value}

             <style jsx>{`
                .DateSelectorInput {
                    background: ${colors.white};
                    min-height: 38px;
                    flex:1;
                    display: flex;
                    align-items: center;
                    padding:0px 10px;
                    border-radius: 5px;
                    cursor: pointer;
                }
                    
            `}</style>
        </div>
      )
    }
  }

  
class DateSelector extends React.Component {   

    constructor(props){
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.triggerOpen = this.triggerOpen.bind(this);
        this.state = {
            date: null
          };
      
    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
        
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        let date = null;
        
        //handle standard date objects
        if(props.value instanceof Date){
            date = props.value
        }
        //or firestore date objects
        else if(props.value && props.value.toDate){
            date =props.value.toDate();
        }
        
        this.setState({date: date})
    }
    handleUpdate(date){
        let startday = null;
        if(date){
            startday = moment(date).startOf('day').toDate();
        }
        this.props.onChange(startday);
    }

    triggerOpen(){
        this.node.setOpen(true);
    }
    render(){

        const renderClear = ()=>{
            if(!this.state.date) return null;
            return(
                <span className="action" onClick={()=>{this.handleUpdate(null)}}>
                    <img src={calendarClear} alt=""/>
                </span>
            )
        }

        
        return (
            <div className="DateSelector">
                <span className="action" onClick={this.triggerOpen}>
                    <img src={calendar} alt=""/>
                </span>
                <DatePicker
                customInput={<ExampleCustomInput onClick={this.triggerOpen} />}
                selected={this.state.date}
                onChange={this.handleUpdate}
                dateFormat="d MMM yyyy"
                ref={(node)=>{this.node = node}}
            />
                {renderClear()}
            <style global jsx>{`
                
                .DateSelector {
                    display: flex;
                    border: 1px solid #22242626;
                    border-radius: 5px;
                    
                    
                }
                .DateSelector  .react-datepicker-wrapper {
                    flex:1;
                }

                .DateSelector  .react-datepicker__input-container {
                    display:block;
                }

                .DateSelector .react-datepicker__input-container input {
                    border:none;
                    border-radius: 0;
                }

                .DateSelector .action {
                    width : 38px;
                    height:38px;
                    background: ${colors.primary};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    ${effects.shadow1}

                }
                .DateSelector .action img {
                    width : 32px;
                    height:32px;
                    
                }

                .DateSelector .action:first-child {
                    border-radius: 5px 0px 0px 5px;

                }
                .DateSelector .action:last-child {
                    border-radius: 0px 5px 5px 0px;

                }
                
                `}</style>
            </div>
        )
    }
}

DateSelector.propTypes = {
    
}

export default DateSelector;
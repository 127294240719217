import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react'
import TimezonePicker from 'react-timezone';
import { saveUser } from '../../actions/userActions';
import { effects, colors, fonts } from '../../assets/styles/theme';



class TimeZoneSelector extends React.Component {

    constructor(props){
        super(props);
        
    }
    componentWillMount(){ 

       
    }
    
    componentDidMount(){
     
     }

    render(){
        return (
            <div className="TimeZoneSelector">
                <Form>
                    <TimezonePicker 
                        className='TimezonePicker'
                        style={{display:'block', marginTop:0}}
                        value={this.props.profile.timeZone}
                        onChange={(timezone)=>{
                            let profile = this.props.profile;
                            profile.timeZone = timezone;
                            this.props.saveUser(profile);
                        }}
                        inputProps={{
                        placeholder: 'Select Timezone...',
                        name: 'timezone',
                        style : {
                            width:'100%'
                        }
                        }}
                    />
                    
                </Form>
                <style global jsx>{`
                        
                    .TimezonePicker > ul {
                        ${effects.shadow1}
                    }  
                    .TimezonePicker > ul > li > button {
                        background : ${colors.primary};
                        color:#fff;
                        ${fonts.bold}
                    }  
                    .TimezonePicker > ul > li > button:hover,
                    .TimezonePicker > ul > li > button.focus {
                        background : ${colors.primaryLo};
                        color:#fff;
                        ${fonts.bold}
                        
                    }     
                `}</style>
            </div>
        )
    }
}

TimeZoneSelector.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        profile : state.user.profile
    };
}

export default  connect(mapStateProps, {  saveUser})(TimeZoneSelector);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseString } from '../../../libs/stringUtils';
import { saveStep } from '../../../actions/planAction';
import { colors } from '../../../assets/styles/theme';

class BraindumpInputBox extends React.Component {

    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.state = {
            inputString : ""
        }
    }
    componentWillMount(){ }
    
    componentDidMount(){ }
    
    async onKeyUp(e){
        //enter on return
        if(e.which == 13) {
            
            let items = e.target.value.trim().split(/\r?\n/);
            
            let promises = [];
            items.map((str, idx)=>{
                let data = parseString(str.trim());
                console.log(data);
                let action = {
                    type : data.type,
                    action : data.title,
                    timeRemaining : data.time,
                    sortKey : 0- ((this.props.planStepsByProject.brainDump.length || 0)+idx)
                }
                if(action.action.length){
                   promises.push(this.props.saveStep(action));
                }
            })

            try {
                await Promise.all(promises);
                this.setState({inputString : ""});
            }
            catch(e){
                console.error(e);
            }
            
        }
    }
    onChange(e){
        console.log('e');
        let target = e.target.value;
        this.setState({inputString : target});
    }
    render(){
        return (
            <div className="BraindumpInputBox" style={{...this.props.style}}>
                <textarea 
                    placeholder={(this.props.placeholder||"Capture thoughts here")}
                    onKeyUp={this.onKeyUp} 
                    onChange={this.onChange} 
                    value={this.state.inputString} 
                    rows="1"
                    />
                <style jsx>{`
                    textarea  {
                        display:block;
                        width:100%;
                        border:1px solid ${colors.midHi};
                        padding:${(this.props.large)? 20 : 10}px;
                        font-size:${(this.props.large)? 1.5 : 1}em;
                        border-radius: ${(this.props.large)? 6 : 3}px;
                        box-shadow: inset 1px 1px 4px rgba(0,0,0,0.2);
                        outline-color: ${colors.primary};
                        
                    }    

                    @media all and (max-width:800px){
                        textarea {
                            font-size:1em;
                            padding:10px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

BraindumpInputBox.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        planStepsByProject : state.plan.planStepsByProject
    };
}

export default  connect(mapStateProps, { saveStep })(BraindumpInputBox);

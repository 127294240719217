import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, TextArea } from 'semantic-ui-react';
import { colors } from '../../assets/styles/theme';
import { saveUser } from '../../actions/userActions';

class UserDetails extends React.Component {

    constructor(props){
        super(props);
        this.saveUser = this.saveUser.bind(this);
        this.state = {
            firstName : '',
            lastName : "",
            reason : '',
            valid : false,
            working : false,
            error : ''
        }
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    async saveUser(){
        if(this.state.valid){
            this.setState({working : true});
            try {
                await this.props.saveUser({firstName : this.state.firstName, lastName : this.state.lastName, reason : this.state.reason });
                this.setState({working : false});
            }
            catch(e){
                this.setState({working : false, error : e.message });
            }
            
        }
    };
    render(){
        return (
            <div className="UserDetails">
                <h1>Hi</h1>
                <p>Thanks for joining us at Helm! To get started, what should we call you?</p>
                <Form>
                    
                    <Input
                        value={this.state.firstName}
                        onChange={(e)=>{
                            this.setState({
                                firstName : e.target.value,
                                valid : (e.target.value.length)
                            });
                        }}
                        fluid
                        placeholder="First name"
                    />
                    <br/>
                    <Input
                        value={this.state.lastName}
                        onChange={(e)=>{
                            this.setState({
                                lastName : e.target.value,
                                valid : (this.state.firstName.length)
                            });
                        }}      
                        fluid
                        placeholder="Last name (optional)"
                    />
                    <br/>

                    <p>And while you are here, please tell us how you hope Helm will help you.</p>
                    
                    <TextArea 
                        value={this.state.reason}
                        onChange={(e)=>{
                            this.setState({
                                reason : e.target.value
                            });
                        }}      
                        fluid="true"
                        placeholder="Main reason for joining? (optional)"
                    ></TextArea>

                    <br/>
                    <br/>
                    <Button
                        color="teal"
                        disabled={!this.state.valid}
                        loading={this.state.working}
                        onClick={this.saveUser}
                        fluid
                    >Save</Button>
                </Form>
                <style jsx>{`
                    h1 {
                        color : ${colors.primary};
                    }
                    p {
                        color : #fff;
                    }
                    .UserDetails {

                    }
                    @media all and ( min-width : 500px){
                        .UserDetails {
                            min-width: 500px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

UserDetails.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, { saveUser })(UserDetails);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/theme';
import logo from './../../assets/img/helm-logo.svg';


class OnBoarding extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="OnBoarding">
                <img className="logo" src={logo} alt=""/>
                <h3>Thanks for signing up</h3>
                
                <p>One of our on-boarding team will be in touch very shortly to get things setup for you.</p>
                <p>In the meantime, please check out these <a href="https://www.myhelm.io/getting-started.html" target="_blank">introductory videos to get you started</a>. </p>


                <p><i>The Helm Team</i></p>
                <style jsx>{`
                    .OnBoarding {
                        max-width: 500px;
                        margin:auto;
                        padding:20px;
                        color : ${colors.white};
                    }
                    .logo {
                        width:200px;
                        height:39px;
                        display: block;
                        margin-bottom:20px;
                    }
                    .OnBoarding a {
                        color: ${colors.primary};
                    }
                    .OnBoarding a:hover {
                        text-decoration:underline;
                    }
                `}</style>
            </div>
        )
    }
}

OnBoarding.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(OnBoarding);

import React from 'react';
import PropTypes from 'prop-types';
import { colors, fonts } from '../../../assets/styles/theme';


class ZoneTitle extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="ZoneTitle" style={{...this.props.style
            }}>
                {this.props.children}
                <style jsx>{`
                    .ZoneTitle {
                        font-size:26px;
                        ${fonts.bold};
                        color: ${colors.dark};
                    }
                    
                    `}</style>
            </div>
        )
    }
}

ZoneTitle.propTypes = {
    
}

export default ZoneTitle;
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateMenuState } from '../../../actions/appActions';
import { TweenMax } from 'gsap/all';
import { colors } from '../../../assets/styles/theme';
import SideMenuButton from './SideMenuButton';
import MediaQuery from 'react-responsive';
import { logoutUser } from '../../../actions/authActions';

class SideMenu extends React.Component {

    constructor(props){
        super(props);
        this.animate = this.animate.bind(this);
    }
    componentWillMount(){ }
    
    componentDidMount(){
        TweenMax.set(this.blind, { autoAlpha : 0 });
        TweenMax.set(this.menu, { xPercent : -100 });
        this.animate(this.props);
    }

    componentWillReceiveProps(newProps){
        this.animate(newProps);
    }

    animate(props){

        if(props.open){
            TweenMax.to(this.blind, 0.4, { autoAlpha : 1 });
            TweenMax.to(this.menu, 0.4, { xPercent : 0 });
        } else {
            TweenMax.to(this.blind, 0.4, { autoAlpha : 0 })
            TweenMax.to(this.menu, 0.4, { xPercent : -100 });
        }
    }



    render(){
        return (
            <div className={(this.props.open)?"SideMenu active" : "SideMenu"}>
                <div ref={(node)=>this.blind=node} className="blind" onClick={()=>{this.props.updateMenuState(false)}}></div>
                <div className="menu" ref={(node)=>this.menu=node}>
                    
                    <SideMenuButton name="Brain Dump" route="/" onAction={()=>{this.props.updateMenuState(false)}} />
                    <MediaQuery query="(min-width: 769px)">
                        <SideMenuButton name="Streams" route="/streams"  onAction={()=>{this.props.updateMenuState(false)}} />
                        <SideMenuButton name="My Plan" route="/master-plan"  onAction={()=>{this.props.updateMenuState(false)}} />
                    </MediaQuery>
                    <SideMenuButton name="Daily Focus" route="/daily-focus" onAction={()=>{this.props.updateMenuState(false)}} />
                    <MediaQuery query="(min-width: 769px)">
                        <SideMenuButton name="Time" route="/time" onAction={()=>{this.props.updateMenuState(false)}}  />
                    </MediaQuery>
                    <MediaQuery query="(max-width: 768px)">
                        <p style={{color:"#6D6D6D", padding:10, fontStyle : "italic", textAlign:'right'}}>Please use a larger screen for the full Helm experience</p>
                    </MediaQuery>

                    <div className="footButton">
                        <div className="logout" onClick={this.props.logoutUser}>Logout</div>
                    </div>
                    
                </div>
                <style jsx>{`

                    .SideMenu {
                        position: relative;
                        z-index: 1000;
                        
                    }
                    .blind {
                        position: fixed;
                        background: rgba(0,0,0,0.5);
                        height: calc(100vh - 50px);
                        position: fixed;
                        width:100vw;
                        top:50px;
                    }
                    
                   

                    .menu {
                        overflow-y: auto;
                        height: calc(100vh - 50px);
                        background: ${colors.dark};
                        width:300px;
                        position: fixed;
                        top:50px;
                        border-top:1px solid #404040;
                        
                    }
                    .menu a {
                        display: block;
                        margin-bottom:30px;
                    }

                    .footButton {
                        min-height: 55px;
                        background: #000;
                        color:${colors.mid};
                        padding:0px 20px;
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        bottom:0px;
                        left:0px;
                        right:0px;
                    }
                    .logout {
                        cursor: pointer;
                        text-transform: uppercase;
                    }

                    @media all and (max-width:500px){
                        .menu {
                            width:90vw;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

SideMenu.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        open : state.app.menuOpen
    };
}

export default  connect(mapStateProps, { updateMenuState, logoutUser })(SideMenu);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';


class TimeBucketDropDown extends React.Component {

    constructor(props){
        super(props);
        this.setInternal = this.setInternal.bind(this);
        this.state = {
            options : []
        }

    }
    componentWillMount(){ }
    

    componentDidMount(){
        this.setInternal(this.props);
     }

    componentWillReceiveProps(newProps){
        this.setInternal(newProps);
    }
    setInternal(props){
        let options = this.props.buckets.map((b)=>{
            return {
                key : b.id,
                value : b.id,
                text : (this.props.default === b.id) ? `Project default (${b.title})` : b.title
            }
        });
        options.push({
            key : 'none',
            value : null,
            text : 'None'
        });
        this.setState({
            options : options
        })

    }

    render(){
        return (
            <div className="TimeBucketDropDown">
                <Dropdown 
                    size={this.props.size}
                    value={this.props.value}
                    onChange={(e,v)=>{this.props.onChange(v.value)}}
                    placeholder={ this.props.placeholder || 'Select default time bucket' } fluid selection options={this.state.options} />
                    
            </div>
        )
    }
}

TimeBucketDropDown.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        buckets : state.time.timeBuckets
    };
}

export default  connect(mapStateProps, {  })(TimeBucketDropDown);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateProjectPlan, bulkUpdateSortOrder, setSelectedPlanStep } from '../../../actions/planAction';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import firebase from 'firebase';
import { colors, fonts, effects } from '../../../assets/styles/theme';
import TimeLengthDisplay from '../TimeLengthDisplay';
import plus from './../../../assets/img/add-timeblock.svg';
import ZoneTitle from '../ZoneTitle';
import moment from 'moment';

const Brightness = (color)=>{
    if(color.length==7){color=color.substring(1);}
    var R =	parseInt(color.substring(0,2),16);
    var G =	parseInt(color.substring(2,4),16);
    var B =	parseInt(color.substring(4,6),16);
    return Math.sqrt(R * R * .241 + G * G * .691 + B * B * .068);
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

class ProjectStepList extends React.Component {

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.renderSteps = this.renderSteps.bind(this);
        this.renderStep = this.renderStep.bind(this);
        this.subscription = null;
        this.state = {
            steps : [],
            loading : true
        }

    }
    componentWillMount(){ }
    
    async componentDidMount(){ 
        if(this.props.project){
            try {
                this.subscription = await firebase.firestore()
                .collection('users')
                .doc(this.props.user.uid)
                .collection('planSteps')
                .where('projectId', '==', this.props.project.id)
                .where('complete', '==', false)
                .orderBy('sortKey', 'asc')
                .orderBy('updatedAt', 'asc')
                // .where('projectId', '==', project.id)
                .onSnapshot((data)=>{
                    let steps = [];
                    data.forEach((r)=>{
                        steps.push(r.data());
                    })
                    // steps.sort(function(a, b) {
                    //     return a.sortKey - b.sortKey;
                    // });
                    this.setState({steps : steps, loading : false})

                })
            }
            catch(e){

            }
           
        }
        //setup the listener

    }
    componentWillUnmount(){
        if(this.subscription){
            console.log('dumping subscription')
            this.subscription();
        }
    }

    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        //no actual change
        if(result.source.index===result.destination.index) return;

        const newOrder = reorder(
            this.state.steps,
            result.source.index,
            result.destination.index
        );
        let sortUpdate = newOrder.map((i, idx)=>{
            return {...i, sortKey : idx};
        })
        this.setState({
            steps : sortUpdate
        })

        await this.props.bulkUpdateSortOrder(sortUpdate);
        // console.log('all done!');
        // // console.log('newOrder', newOrder, sortUpdate);
    
        // // this.setState({
        // //   items,
        // // });
    }

    renderStep(step, idx){
        let {color} = this.props.project;
        if(!color){
            color = colors.primary;
        }
        

        const getExpectedCompletion = ()=>{
            if(this.props.exp[step.id]){
                let prefix = (step.dueOn) ? ' | ' : '';

                return prefix+'Exp: '+moment.unix(this.props.exp[step.id]).format('D MMM YYYY')
            }

            return 'Unscheduled'
        }

        const getDueDate = ()=>{
            if(step.dueOn){
                return 'Due: '+moment(step.dueOn.toDate()).format('D MMM YYYY')
            }

            return ''
        }

        const getStatusMarker = ()=>{
            
            if(step.dueOn && this.props.exp[step.id]){

                let dueDay = moment(step.dueOn.toDate()).startOf('day');
                let expectedDay = moment.unix(this.props.exp[step.id]).startOf('day');
                let fill = (expectedDay.isSameOrBefore(dueDay)) ? colors.green : colors.error;
                return (
                    <svg style={{position : 'relative', top : 2, marginRight:1}} width="12" height="12" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="8" fill={fill} strokeWidth={2} stroke="#fff"></circle>
                    </svg>
                )
            }
            return '';
        }
        switch(step.type){
            case 'milestone':
                return (
                    <Draggable key={step.id} draggableId={step.id} index={idx} >
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="step-wrap"
                                >
                                    <span className="handle" {...provided.dragHandleProps} style={{backgroundColor : color || colors.primary }} >
                                        <svg  width="10" height="32" viewBox="0 0 10 32">
                                            <g opacity="0.48">
                                                <path d="M9.45,4l-4-4a.66.66,0,0,0-.94,0h0l-4,4A.66.66,0,1,0,1.49,5h0L4.32,2.13V13.79a.68.68,0,0,0,.68.67.67.67,0,0,0,.67-.67V2.13L8.5,5a.68.68,0,0,0,.95,0,.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                                <path d="M9.45,27a.66.66,0,0,0-.94,0h0L5.67,29.87V18.21a.67.67,0,0,0-1.34,0h0V29.87L1.49,27A.66.66,0,1,0,.55,28l4,4a.66.66,0,0,0,.94,0h0l4-4a.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                            </g>
                                        </svg>
                                    </span>

                                    <div className="main-area" style={{backgroundColor : color || colors.primary }}  onClick={()=>{this.props.setSelectedPlanStep(step)}}>
                                        <div className="main-area-top">
                                            <p className="action"
                                                style={{
                                                    color : (Brightness(color)<200) ? "#fff" : "#000"
                                                }}
                                            >{step.action}</p>
                                        </div>
                                        <div className="main-area-controls">
                                            <div className="step-time-meta"
                                                style={{
                                                    color : (Brightness(color)<200) ? "#fff" : "#000"
                                                }}
                                            >
                                                {getStatusMarker()} {getDueDate()} {getExpectedCompletion()}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* <span className="stepMark" style={{backgroundColor : color || colors.primary }}></span> */}
                              
                                <style jsx>{`
                                    .step-wrap {
                                        min-height: 70px;
                                        background: ${colors.primary};
                                        margin-bottom:10px;
                                        box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
                                        display: flex;
                                        justify-content: stretch;
                                        position: relative;
                                    }
                                   
                                    .handle {
                                        width:20px;
                                        min-height: 75px;
                                        background: ${colors.primary};
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                    }
                                    @media (pointer: coarse) {
                                        .handle {
                                            width:40px;
                                        }
                                    }
                                    .main-area {
                                        flex:1;
                                        display: flex;
                                        flex-direction: column;
                                        padding:5px 10px;
                                    }
                                    .main-area-top {
                                        flex:1;
                                    }
                                    .action {
                                        font-weight: 400;
                                        font-size:17px;
                                        color:${colors.white};
                                    }
                                    .main-area-controls {
                                        font-size:14px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-end;

                                    }
                                    .step-time-meta {
                                        font-size:11px;
                                        color:${colors.white};
                                        opacity: 0.8;
                                    }
                                    .stepMark {
                                        width:10px; 
                                        height:10px;
                                        border-radius: 5px;
                                        position: absolute;
                                        display: block;
                                        background: #000;
                                        left:calc(50% + 2.5px);
                                        bottom:-4px;
                                        background: ${colors.primary};
                                    }
                                    @media (pointer: coarse) {
                                        .stepMark {
                                            left:calc(50% - 2.5px);
                                        }
                                    }
                                    
                                `}</style>
                                </div>
                            )}  
                    </Draggable>
                )
            default :
                return (
                    <Draggable key={step.id} draggableId={step.id} index={idx} >
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="step-wrap"
                                >
                                    <span className="handle" {...provided.dragHandleProps} style={{backgroundColor : color || colors.primary }}>
                                        <svg  width="10" height="32" viewBox="0 0 10 32">
                                            <g opacity="0.48">
                                                <path d="M9.45,4l-4-4a.66.66,0,0,0-.94,0h0l-4,4A.66.66,0,1,0,1.49,5h0L4.32,2.13V13.79a.68.68,0,0,0,.68.67.67.67,0,0,0,.67-.67V2.13L8.5,5a.68.68,0,0,0,.95,0,.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                                <path d="M9.45,27a.66.66,0,0,0-.94,0h0L5.67,29.87V18.21a.67.67,0,0,0-1.34,0h0V29.87L1.49,27A.66.66,0,1,0,.55,28l4,4a.66.66,0,0,0,.94,0h0l4-4a.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                            </g>
                                        </svg>
                                    </span>

                                    <div className="main-area" onClick={()=>{this.props.setSelectedPlanStep(step)}}>
                                        <div className="main-area-top">
                                            <p className="action">{step.action}</p>
                                        </div>
                                        <div className="main-area-controls">
                                            <div className="step-time-meta">
                                              {getStatusMarker()} {getDueDate()} {getExpectedCompletion()}
                                            </div>
                                            <div className="step-time-allocation">
                                                <TimeLengthDisplay long={true} time={step.timeRemaining}></TimeLengthDisplay>
                                            </div>
                                        </div>
                                    </div>
                                   {/* <span className="stepMark" style={{backgroundColor : color || colors.primary }}></span> */}
                              
                                <style jsx>{`
                                    .step-wrap {
                                        min-height: 70px;
                                        background: ${colors.white};
                                        margin-bottom:10px;
                                        box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
                                        display: flex;
                                        justify-content: stretch;
                                        position: relative;
                                    }
                                    .handle {
                                        width:20px;
                                        min-height: 75px;
                                        background: ${colors.primary};
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                    }

                                    @media (pointer: coarse) {
                                        .handle {
                                            width:40px;
                                        }
                                    }
                                    .main-area {
                                        flex:1;
                                        display: flex;
                                        flex-direction: column;
                                        padding:5px 10px;
                                    }
                                    .main-area-top {
                                        flex:1;
                                    }
                                    .action {
                                        font-weight: 400;
                                        font-size:17px;
                                        color:${colors.dark};
                                    }
                                    .main-area-controls {
                                        font-size:14px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-end;

                                    }
                                    .step-time-meta {
                                        font-size:11px;
                                        color:${colors.mid};
                                    }
                                    .step-time-allocation {
                                        width:80px;
                                        text-align: center;
                                        padding:5px 15px;
                                        border-radius : 15px;
                                        background: ${colors.white};
                                        white-space: nowrap;
                                        font-weight: 700;
                                        color:${colors.dark};
                                        border:1px solid ${colors.dark};
                                    }
                                    .stepMark {
                                        width:10px; 
                                        height:10px;
                                        border-radius: 5px;
                                        position: absolute;
                                        display: block;
                                        background: #000;
                                        left:calc(50% + 2.5px);
                                        bottom:-4px;
                                        background: ${colors.primary};
                                    }
                                    @media (pointer: coarse) {
                                        .stepMark {
                                            left:calc(50% - 2.5px);
                                        }
                                    }
                                `}</style>
                                
                                </div>

                            )}
         
                    </Draggable>
                )
        }
        
    }
    renderSteps(){
        return this.state.steps.map((s, idx)=>{
            return this.renderStep(s, idx);
        })
    }

    render(){
        const renderTitle = ()=>{
            return null;
            if(this.props.compact) return null;
            return <ZoneTitle style={{position:'absolute'}}>Project Plan</ZoneTitle>
        }
        let {color} = this.props.project;
        return (
            <div className="ProjectStepList">
               
                <span className="centerLine" style={{backgroundColor : color || colors.primary }}></span>
                <div className="ProjectStepList-title">
                    {renderTitle()}
                    <button className="edit-steps" style={{backgroundColor : color || colors.primary}} onClick={()=>{this.props.updateProjectPlan(this.props.project)}}>
                        <span
                            style={{color : (Brightness(color || colors.primary)<200) ? "#fff" : "#000"}}
                        >Add / Edit Steps</span>
                        
                         <img src={plus} alt=""/>
                    </button>
                    <div className="rightHead">
                    {(this.props.rightHead)? this.props.rightHead(): null}
                    </div>
                    
                </div>
                <div className="ProjectStepList-list">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId={this.props.project.id}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} >
                                    {this.renderSteps()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                
             <style jsx>{`
                    .ProjectStepList {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        flex:1;
                        overflow: hidden;
                    }
                    .ProjectStepList-list {
                        flex:1;
                        overflow-x: visible;
                        overflow-y: scroll;
                        -webkit-overflow-scrolling: touch;
                        padding-right:5px;
                    }
                 
                   
                    .centerLine {
                        position: absolute;
                        top:0px;
                        bottom:15px;
                        left:10px;
                        width: 4px;
                        background: ${colors.primary};
                        transform: translateX(-50%);
                    }
                    .edit-steps {
                        display: flex;
                        margin:0;
                        z-index: 0;
                        position: relative;
                        height:30px;
                        margin-bottom:5px;
                        background: ${colors.primary};
                        border-radius: 5px;
                        border:none;
                        justify-content: center;
                        align-items: center;
                        ${fonts.bold}
                        font-size: 12px;
                        color:#fff;
                        cursor: pointer;
                        ${effects.shadow1}
                    }
                    .edit-steps:focus {
                        outline: none;
                    }
                    .edit-steps img {
                        width: 20px;
                        height:20px;
                        display: block;
                    }
                    .rightHead {
                        position: absolute;
                        top:0px;
                        right:2px;
                    }

                    @media (pointer: coarse) {
                        .centerLine {
                            left:20px;
                        }
                    }
                    
                `}</style>
            </div>
        )
    }
}

ProjectStepList.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        user : state.app.authedUser,
        exp : state.event.expectedComplete
    };
}

export default  connect(mapStateProps, { updateProjectPlan, bulkUpdateSortOrder, setSelectedPlanStep })(ProjectStepList);

import firebase from 'firebase';
import { logEvent } from '../analytics';

export const setSelectedPlanStep = (step)=>{
    return (dispatch, getState)=>{
        dispatch({
            type: 'SET_SELECTED_PLAN_STEP',
            payload : step
        });
        if(step){
            logEvent('userSelectedPlanStep', { stepId : step.id })
        }
        
    }
}

export const setSelectedPlanStepId = (stepId)=>{
    return (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{
            let user = getState().app.authedUser;
            try {
                let ref = await firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(stepId).get();
                
                let doc = ref.data();
                doc.id = ref.id;
                dispatch({
                    type: 'SET_SELECTED_PLAN_STEP',
                    payload : doc
                });
                logEvent('userSelectedPlanStep', { stepId : stepId })
            }
            catch(e){

            }
            

        })
        

    }
}


export const exitPlanEditing = ()=>{
    return (dispatch, getState)=>{
        let cancelPlanListener = getState().plan.planListener;
        if(cancelPlanListener){cancelPlanListener()};

        dispatch({
            type: 'EXIT_PLAN_EDIT_MODE'
        });
        logEvent('userExitedPlanEditing')
    }
}



export const bulkUpdateSortOrder = (planSteps)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            try {
                let user = getState().app.authedUser;
                let batch  = firebase.firestore().batch();

                planSteps.forEach((plan)=>{
                    let ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(plan.id);
                    batch.update(ref, {
                        sortKey : plan.sortKey,
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp() 
                    });
                });

                await batch.commit();
                

                dispatch({
                    type : 'BULK_PLAN_STEPS_SORT_UPDATED',
                    payload : planSteps
                })

                resolve(true);


            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}


export const incrementChanges = (count)=>{
    return async (dispatch, getState)=>{
        let user = getState().app.authedUser;
        let ref = firebase.database().ref(`users/${user.uid}/status`);
        if(!count){
            count=1;
        }
        ref.transaction((status)=>{
            if(status){
                if(status.changes){
                    status.changes+= count
                } else {
                    status.changes=count;
                }
                return status;
               
            } else {
                return {
                    changes : count
                }
            }
           
        })
    }
}

export const bulkUpdateSortOrderAndProjectId = (planSteps, projectId)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            try {
                let user = getState().app.authedUser;
                let batch  = firebase.firestore().batch();

                planSteps.forEach((plan)=>{
                    let ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(plan.id);
                    batch.update(ref, {
                        sortKey : plan.sortKey,
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                        projectId : projectId
                    });
                });

                await batch.commit();
                
                
                dispatch({
                    type : 'BULK_PLAN_STEPS_SORT_UPDATED',
                    payload : planSteps
                })

                resolve(true);


            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}
export const deleteStep = (stepId)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            try {
                let user = getState().app.authedUser;
                
                await firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(stepId).delete();
                
                dispatch(incrementChanges())


                dispatch({
                    type : 'PLAN_STEP_DELETED',
                    payload : stepId
                })

                resolve(true);


            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}


export const bulkDeleteSteps = (stepIds)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            try {
                let user = getState().app.authedUser;
                let batch  = firebase.firestore().batch();

                stepIds.forEach((id)=>{
                    let ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(id);
                    batch.delete(ref);
                });

                await batch.commit();
                
                dispatch(incrementChanges())

                dispatch({
                    type : 'BULK_PLAN_STEPS_DELETED',
                    payload : stepIds
                })

                resolve(true);


            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}


export const insertPlanSteps = (steps, insertPoint, insertIndex)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            try {
                let user = getState().app.authedUser;
                let batch  = firebase.firestore().batch();

                let currentSteps = getState().plan.planSteps;
                
                let previousSteps = [];
                let endSteps = []; 

                if(insertPoint==='top'){
                    endSteps = currentSteps.slice();
                }
                if(insertPoint==='bottom'){
                    previousSteps = currentSteps.slice();
                }

                if(insertPoint==='before'){
                    previousSteps = currentSteps.slice(0, insertIndex);
                    endSteps = currentSteps.slice(insertIndex);
                }
                if(insertPoint==='after'){
                    previousSteps = currentSteps.slice(0, insertIndex+1);
                    endSteps = currentSteps.slice(insertIndex+1);
                }

                let allSteps = [].concat(previousSteps, steps, endSteps);
                console.log('allSteps', allSteps);

                let allStepsWithSortKey = allSteps.map((s, idx)=>{
                    s.sortKey=idx;
                    return s;
                })



                allStepsWithSortKey.forEach((step)=>{
                    let ref;
                    if(!step.id){
                        ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc();
                        step.id = ref.id;
                        step.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                        step.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
                        step.complete = false;
                        step.archived = false;
                        batch.set(ref, step, { merge : true });
                    } else {
                        ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(step.id);
                        batch.update(ref, {sortKey : step.sortKey });
                    }
                });

                await batch.commit();
                
                dispatch({
                    type : 'NEW_PLAN_STEPS_INSERTED',
                    payload : allStepsWithSortKey
                })
                logEvent('userInsertedNewPlanSteps', { stepCount : steps.length })
                dispatch(incrementChanges(steps.length))
                resolve(true);


            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}

export const saveStep = (step)=> {
    return (dispatch, getState)=> {
        return new Promise(async (resolve, reject)=>{
            let user = getState().app.authedUser;
            let ref;
            if(!step.id){
                ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc();
                step.id = ref.id;
                step.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            } else {
                ref = firebase.firestore().collection('users').doc(user.uid).collection('planSteps').doc(step.id);
            }
            step.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            if(!step.personalImportance){
                step.personalImportance = 0;
            }

            if(!step.projectId){
                step.projectId = 'brainDump';
            }

            if(!step.complete){
                step.complete = false;
            }

            if(!step.archived){
                step.archived = false;
            }


            logEvent('userSavedPlanStep', { stepId : step.id })
            dispatch(incrementChanges())
            try {
                await ref.set(step, {merge : true});
                dispatch({
                    type: 'USER_PROJECT_STEP_UPDATED',
                    payload : step
                });
                dispatch(incrementChanges())
                resolve(step);
            }
            catch(e){
                dispatch({
                    type: 'USER_PROJECT_STEP_UPDATE_ERROR',
                    payload : e
                });
                reject();
            }


        })
    }
}


export const updateProjectPlan = (project)=> {
    
    return async (dispatch, getState)=> {

        console.log('this.props.project', project);
        
        let cancelPlanListener = getState().plan.planListener;
        if(cancelPlanListener){cancelPlanListener()};

        let user = getState().app.authedUser;

        dispatch({
            type: 'SET_SELECTED_PROJECT',
            payload : project
        });

        logEvent('userEnteredPlanEditing', { projectId : project.id })

        let planListener = await firebase.firestore()
                                    .collection('users')
                                    .doc(user.uid)
                                    .collection('planSteps')
                                    .where('projectId', '==', project.id )
                                    .where('complete', '==', false)
                                    .orderBy('sortKey', 'asc')
                                    .orderBy('updatedAt', 'asc')
                                    .onSnapshot((results)=>{
                                        let planSteps = [];
                                        results.forEach((doc)=>{
                                            planSteps.push(doc.data());
                                        });

                                        planSteps.sort(function(a, b) {
                                            return a.sortKey - b.sortKey;
                                        })


                                        dispatch({
                                            type: 'UPDATE_SELECTED_PROJECT_PLAN_STEPS',
                                            payload : planSteps
                                        });
                                    });
            dispatch({
                type: 'UPDATE_SELECTED_PROJECT_PLAN_STEPS_LISTENER',
                payload : planListener
            });

    }
}


export const initPlanSteps = ()=>{
    return async (dispatch, getState)=>{

        firebase.auth().onAuthStateChanged((user)=> {

            //cancel any previous subscriptions
            if(getState().plan.planStepsListener){ 
                getState().plan.planStepsListener();
            }

            if(user){
                let listener =  firebase.firestore()
                    .collection(`users/${user.uid}/planSteps`)
                    .where('complete', '==', false)
                    .orderBy('sortKey', 'asc')
                    .orderBy('updatedAt', 'asc')
                    .onSnapshot((data)=>{
                        

                        let projectSteps = {
                            brainDump : []
                        };

                        data.forEach((r)=>{
                            let step = r.data();
                            //create a project array if one doesn't exist already
                            if(!projectSteps[step.projectId]){ 
                                projectSteps[step.projectId] = []  
                            }

                            projectSteps[step.projectId].push(step);

                        })

                        dispatch({
                            type: 'UPDATE_USER_PLAN_STEPS',
                            payload : projectSteps
                        });
                        
                    })
                dispatch({
                    type: 'UPDATE_USER_PLAN_STEP_LISTENER',
                    payload : listener
                });

                firebase.database().ref(`users/${user.uid}/status`).on('value', (snap)=>{
                    let changes = 0;
                    if(snap.val() && snap.val().changes){
                        changes = snap.val().changes;
                    }
                    
                    dispatch({
                        type: 'UPDATE_USER_CHANGES_COUNT',
                        payload : changes
                    });
                })

            } else {
                dispatch({
                    type: 'UPDATE_USER_PLAN_STEPS',
                    payload : {}
                });
                dispatch({
                    type: 'UPDATE_USER_PLAN_STEP_LISTENER',
                    payload : null
                });
            }
        })


       


    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Button } from 'semantic-ui-react'
import { bulkDeleteSteps, bulkUpdateSortOrder } from '../../actions/planAction';
import DeleteButton from './DeleteButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import WeekDayTimeBlock from './WeekDayTimeBlock';
import { colors, fonts } from '../../assets/styles/theme';
import TimeLengthDisplay from './TimeLengthDisplay';
import PlanStepInlineEditor from '../global/PlanStepInlineEditor/PlanStepInlineEditor';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#fff' : 'none',
  
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  
class PlanEditorList extends React.Component {

    constructor(props){
        super(props);
        this.toggleArrayItem = this.toggleArrayItem.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.bulkDelete = this.bulkDelete.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = {
            selectedIds : [],
            deleteWorking : false,
            existingSteps : [],
            totalTime:0
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props)
    }
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    
    setInternals(props){
        let totalTime = 0;
        if(props.existingSteps && props.existingSteps.length){
            props.existingSteps.map((step)=>{
                if(step.type=='action' && step.timeRemaining){
                    totalTime+=step.timeRemaining
                }
            })
        }
        this.setState({existingSteps : props.existingSteps, totalTime : totalTime});

    }

    toggleArrayItem(id){
        let ids = this.state.selectedIds.slice();
        let idx = ids.indexOf(id);
        if(idx===-1){
            ids.push(id);
        } else {
            ids.splice(idx, 1);
        }
        this.setState({selectedIds : ids})
    }

    toggleSelectAll(){
        let ids = []
        if(this.state.selectedIds.length!==this.state.existingSteps.length){
            console.log('add ');
            //add them all
            ids = this.state.existingSteps.map((s)=>{return s.id});
            console.log('add ', ids);
        } 
        this.setState({selectedIds : ids});
        
    }
    async bulkDelete(){
        if(!this.state.selectedIds.length) return;
        this.setState({deleteWorking : true});
        try {
            await this.props.bulkDeleteSteps(this.state.selectedIds);
            this.setState({deleteWorking : false, selectedIds : []});
        }
        catch(e){
            console.error(e);
            this.setState({deleteWorking : false});
        }
        

    }

   

    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        //no actual change
        if(result.source.index===result.destination.index) return;

        const newOrder = reorder(
            this.state.existingSteps,
            result.source.index,
            result.destination.index
        );
        let sortUpdate = newOrder.map((i, idx)=>{
            return {...i, sortKey : idx};
        })
        this.setState({
            existingSteps : sortUpdate
        })

        await this.props.bulkUpdateSortOrder(sortUpdate);
        console.log('all done!');
        // console.log('newOrder', newOrder, sortUpdate);
    
        // this.setState({
        //   items,
        // });
      }

    render(){


        const renderListItems = ()=>{

            return this.state.existingSteps.map((l, idx)=>{
                return (
                    <div key={l.id}>
                        <div className="list-row">
                            <Draggable key={l.id} draggableId={l.id+"drag"} index={idx} style={{width:'30vw'}}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                       <div className="list-row-wrap" style={{
                                           display:'flex', 
                                           width:'28vw', 
                                           borderBottom:'1px solid #ccc',
                                           padding : '10px 5px 10px 0px',
                                           alignItems: 'center'
                                           }}>
                                       
                                       <div className={(l.type=='milestone') ? 'list-row-text milestone' : 'list-row-text'}>
                                            
                                            <PlanStepInlineEditor step={l}></PlanStepInlineEditor>
                                        
                                        </div>
                                        <Checkbox style={{marginLeft:10}} checked={(this.state.selectedIds.indexOf(l.id)>-1)} onClick={()=>{this.toggleArrayItem(l.id)}} />
                                       </div>
                                       
                                        
                                        
                                        
                                    </div>
                                )}

                                
                            </Draggable>
                          
                            
                        </div>
                        <style jsx>{`
                            .list-row  {
                                display: flex;
                                align-items: center;
                                width:30vw;
                               
                            }
                          
                            .list-row-text { 
                                flex:1;
                                padding-left:5px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .list-row-text.milestone { 
                                color:${colors.primary};
                                font-weight: 700;
                            }

                            
                           

                        `}</style>
                    </div>
                )
            })
        }

        const toggleAllChecked = ()=>{
            if(this.state.existingSteps.length===0) return false;
            return this.state.selectedIds.length===this.state.existingSteps.length;
        }

        const renderTotalAllocated = ()=>{
            if(!this.state.totalTime) return null;
            return (
                <div className="PlanEditorList-total">
                    
                        Total Time Allocated: <TimeLengthDisplay units={true} time={this.state.totalTime} />
                    <style jsx>{`
                        .PlanEditorList-total {
                            ${fonts.bold}
                            font-size:12px;
                            color: ${colors.mid};

                        }    
                    `}</style>
                </div>
            )
        }

        return (
            <div className="PlanEditorList">
                <div className="PlanEditorList-controls">
                    <div className="note">Drag to re-order steps</div>
                   
                        <Checkbox style={{marginRight:10}}  label="Select all" checked={toggleAllChecked()} onClick={this.toggleSelectAll} />
                        <DeleteButton disabled={!(this.state.selectedIds.length)} onConfirm={this.bulkDelete} />
                    
                    
                    
                    
                </div>


                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div className="PlanEditorList-steps"  ref={provided.innerRef} >
                                {renderListItems()}
                                {provided.placeholder}
                                <div ref={(node) => { this.messagePanelFoot = node }}>&nbsp;</div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {renderTotalAllocated()}
                
                <style jsx>{`
                    .PlanEditorList {
                        max-height:70vh;
                       

                    }
                    .PlanEditorList-steps {
                        max-height:70vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        position: relative;

                    }
                    .PlanEditorList-steps:before {
                        position: absolute;
                        content : " ";
                        background: #ccc;
                        top:0px;
                        left:0px;
                        right:17px;
                        height: 1px;
                    }
                    .PlanEditorList-controls {
                        display: flex;
                        align-items: center;
                        margin-bottom:15px;
                        padding-right: 17px;
                        padding-left:5px;
                    }
                    .PlanEditorList-controls .ui.checkbox {
                        margin-left:10px;
                    }
                    .note {
                        flex:1;
                        text-align: left;
                        font-weight: bold;
                        color:${colors.mid}
                    }
                    
                `}</style>
            </div>
        )
    }
}



PlanEditorList.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        existingSteps : state.plan.planSteps
    };
}

export default  connect(mapStateProps, { bulkDeleteSteps, bulkUpdateSortOrder })(PlanEditorList);

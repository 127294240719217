import React from 'react';
import PropTypes from 'prop-types';

import brain from '../../../assets/img/brain-dump-1.svg';
import {fonts, colors} from '../../../assets/styles/theme.js';

class BrainDumpHeader extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="BrainDumpHeader">
                <img  src={brain} style={{width:(this.props.width)?this.props.width:90}} alt=""/>
                <p>Brain Dump</p>

                <style jsx>{`
                    .BrainDumpHeader {
                        display: block;

                        align-items: flex-end;
                    }
                    img {

                    }  
                    p {
                        margin:0;
                        ${fonts.bold}
                        color:${colors.white};
                        font-size:28px;
                        line-height: 1em;
                        
                    }  
                    
                `}</style>
            </div>
        )
    }
}

BrainDumpHeader.propTypes = {
    
}

export default BrainDumpHeader;

import firebase from 'firebase';
import { logEvent } from '../analytics';

export const sampleAction = ()=> {
    return (dispatch)=> {
        dispatch({
            type: 'SAMPLE_ACTION',
            payload : null
        });


    }
}

/**
 * When you change the "complete" value of a step this function makes sure that any events for this are also marked complete
 * @param {string} actionId The id of the step that you updating the action status off
 * @param {bool} actionDoneValue 
 */

export const updateActionEventsOnActionDoneChange = (actionId, actionDoneValue)=>{
    return (dispatch, getState)=> {
       
        return new Promise(async (resolve, reject)=>{
            
            try {
                let user = getState().app.authedUser;
              
                let promises = [];

                getState().event.events.filter((event)=>{
                    return event.actionId==actionId
                })
                .map((event)=>{
                    console.log(event);
                    promises.push(firebase.database().ref('users/' + user.uid+'/helmEvents/'+event.id).update({
                        complete : actionDoneValue
                    }))
                })

                await Promise.all(promises);
                resolve(true);
               
            }
            catch(e){
                console.error(e);
                reject(e);
            }
            
        })

    }
}

export const toggleEventDone = (event)=>{
    return (dispatch, getState)=> {
       
        return new Promise(async (resolve, reject)=>{
            
            
            try {
                let user = getState().app.authedUser;
                let newMark = (event.complete) ? false : true;
                await firebase.database().ref('users/' + user.uid+'/helmEvents/'+event.id).update({
                    complete : newMark
                })
                logEvent('userToggleEventDone', { actionId : event.actionId, eventId : event.id, done : newMark })
                resolve();
             
            }
            catch(e){
                console.error(e);
                reject(e);
            }
            
        })

    }
}
export const sequenceEvents =  ()=> {
    return (dispatch)=> {
       
        return new Promise(async (resolve, reject)=>{
           
            dispatch({
                type: 'SEQUENCING_STATUS_UPDATE',
                payload : true
            });

            try {
                await firebase.functions().httpsCallable('sequenceEvents')({});
                logEvent('userSequencedPlans')
                dispatch({
                    type: 'SEQUENCING_STATUS_UPDATE',
                    payload : false
                });
            }
            catch(e){
                dispatch({
                    type: 'SEQUENCING_STATUS_UPDATE',
                    payload : false
                });
            }
            
        })

    }
}



export const initEvents = (user)=>{
    return (dispatch)=> {
        // console.log('initEvents');
        firebase.database().ref('users/' + user.uid+'/externalEvents/events').on('value',(snapShot)=>{
           let externals = [];
            snapShot.forEach(function(snap) {
                var data = snap.val();
                data.id = snap.key;
                externals.push(data)
            });
            dispatch({
                type: 'USER_EXTERNAL_EVENTS_UPDATED',
                payload : externals
            });

        })

        firebase.database().ref('users/' + user.uid+'/helmEvents').on('value',(snapShot)=>{
            let helmEvents = [];
             snapShot.forEach(function(snap) {
                 var data = snap.val();
                 data.id = snap.key;

                 helmEvents.push(data)
             });
             dispatch({
                 type: 'HELM_EVENTS_UPDATED',
                 payload : helmEvents
             });
 
         })

         firebase.database().ref('users/' + user.uid+'/expectedCompletions').on('value',(snapShot)=>{
            let completionTimes = {};
             snapShot.forEach(function(snap) {
                 completionTimes[snap.key] =  snap.val()
             });
             dispatch({
                 type: 'HELM_EXPECTED_COMPLETION_DATES_UPDATED',
                 payload : completionTimes
             });
 
         })

         firebase.firestore().collection('users').doc(user.uid)
            .collection('planSteps')
            .where('type', '==', 'milestone')
            .where('complete', '==', false)
            .onSnapshot(
                (snapShot)=>{
                    let milestonesByKey = {};
                    snapShot.forEach((step)=>{
                        milestonesByKey[step.id] = step.data();
                    });
                    dispatch({
                        type: 'USER_MILESTONES_UPDATE',
                        payload : milestonesByKey
                    });
                }
            )
         

         
        dispatch({
            type: 'EVENTS_INITIALISED',
            payload : null
        });


    }
}
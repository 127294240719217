import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/theme';

let timeScales = [0, 2, 5, 10, 15, 20, 25, 30, 45, 60, 90, 120, 150, 180, 210, 240];

// for(var i=4; i<41; i++){
//     timeScales.push(i*60);
// }

const closestIndex = (input)=>{
    let closestVal = timeScales.reduce((prev, curr) => Math.abs(curr - input) < Math.abs(prev - input) ? curr : prev);
    return timeScales.indexOf(closestVal);
}



const parseMinutes = (mins)=>{
   
    let hours = Math.floor(mins/60);
    let rounding = mins%60;
    if(rounding<10){
        rounding =  '0'+rounding;
    }
    if(!hours){
        return mins;
    }
    if(!rounding){
        rounding = '00'
    }
    
    return hours+'.'+rounding;
}

const resolveToMinutes = (input)=>{
    let string = input+"";
    let time = 0;
    let splitChar = (string.indexOf('.')>-1) ? '.' : ':';
    let timeSplit = string.split(splitChar);
    if(timeSplit.length==1){
    if(timeSplit[0]){
        time = parseInt(timeSplit[0]);
    }             
    } else if (timeSplit.length==2) {
        let mins = 0;
        if(timeSplit[0]){
            mins+= (parseInt(timeSplit[0])* 60);
        }
        if(timeSplit[1]){
            if(timeSplit[1].length==1){
                timeSplit[1]+="0";
            }
            mins+= (parseInt(timeSplit[1]));
        }
        time = mins;
    }
    return time;
}


class TimeEditorInput extends React.Component {

    constructor(props){
        super(props);
        this.increaseValue = this.increaseValue.bind(this);
        this.decreaseValue = this.decreaseValue.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.manualUpdate = this.manualUpdate.bind(this);
        this.done = this.done.bind(this);
        this.state = {
            minutes : 0,
            timeString : ''
        }
    }
    componentDidMount(){
        if(matchMedia('(pointer:fine)').matches){
            this.node.focus();
            this.node.select();
        }
        
        this.setInternals(this.props);
    }
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          this.props.onEnter(resolveToMinutes(this.state.timeString));
        }
    }

    setInternals(props){
        this.setState({
            minutes : props.value,
            timeString : parseMinutes(props.value)
        });
    }

    manualUpdate(e){
        console.log(/^\d*.?\d\d?$/.test(e.target.value))
        let val = e.target.value;


        if(!/^\d*\.?([0-5]|[0-5][0-9])?$/.test(val)){
            //invalid time entry
            return;
        }
        // console.log(
        //     'numeric',
        //     /^\d*\.?([0-5]|[0-5][0-9])?$/.test(val),
        //     'decimal',
        //     /\./.test(val)
        // )

        this.setState({
            timeString : e.target.value
        })
    }

    done(){
        this.props.onEnter(resolveToMinutes(this.state.timeString));
    }

    increaseValue(){
        let idx = closestIndex(this.state.minutes);
        let mins = (idx==timeScales.length-1) ? this.state.minutes+60 :  timeScales[idx+1];
        this.setInternals({
            value : mins
        })
        //switch the focus back on the input so that enter always completes the operation
        if(matchMedia('(pointer:fine)').matches){
            this.node.focus();
        }
    }

    decreaseValue(){
        let idx = closestIndex(this.state.minutes);
        let mins;
        if( (idx==timeScales.length-1)  && (this.state.minutes > timeScales[timeScales.length-1]) ){
            //it's at the top end;
            let scaleMins = timeScales[timeScales.length-1];
            let newMins = this.state.minutes-60;
            mins = (newMins >= scaleMins) ? newMins : scaleMins;
        } else {
            mins = (idx==0) ? 0 :  timeScales[idx-1]
        }
        this.setInternals({
            value : mins
        })
        //switch the focus back on the input so that enter always completes the operation
        if(matchMedia('(pointer:fine)').matches){
            this.node.focus();
        }
        
    }


    render(){
        return (
            <div className="TimeEditorInput">
            <div className="increm left" onClick={this.decreaseValue}>-</div>
            <input type="text"
                pattern="^\d*\.?([0-5]|[0-5][0-9])?$"
                onKeyPress={this._handleKeyPress}
                value={this.state.timeString}
                onChange={this.manualUpdate}
                ref={(node)=>{this.node=node}}
                className="inputbox"
                
            />
            <div className="increm right" onClick={this.increaseValue}>+</div>
            <div className="done" onClick={this.done}>
                <svg width="30" height="30" viewBox="0 0 38 38">
                    <path fill="#fff" d="M6.3,20a1,1,0,0,1,0-1.4l1.4-1.4a1,1,0,0,1,1.4,0l.1.1,5.5,5.9a.48.48,0,0,0,.7,0L28.8,9.3h.1a1,1,0,0,1,1.4,0l1.4,1.4a1,1,0,0,1,0,1.4h0l-16,16.6a1,1,0,0,1-1.4,0L6.5,20.3Z"/>
                </svg>      
            </div>
            <style jsx>{`
                .TimeEditorInput {
                    display: flex;
                    align-items: center;
                }
                .inputbox{
                    width:70px!important;
                    height:30px!important;
                    margin:0px 0px!important;
                    border-radius: 0px!important;
                    border-color : ${colors.primary}!important;
                    padding: .67857143em 4px !important;
                    text-align: center;
                }
                .inputbox:focus {
                    border-color : ${colors.primary}!important;
                }
                .increm {
                    width:30px;
                    height:30px;
                    border-radius: 15px;
                    background: ${colors.primary};
                    color:${colors.white};
                    border:none;
                    font-size:24px;
                    cursor: pointer;
                    padding:5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .increm.left{
                    border-radius: 15px 0px 0px 15px;
                }
                .increm.right{
                    border-radius: 0px 15px 15px 0px;
                }
                .increm:focus {
                    outline: none;
                }
                .done {
                    width:30px;
                    height:30px;
                    border-radius: 20px;
                    background: ${colors.primary};
                    color:${colors.white};
                    border:none;
                    font-size:24px;
                    cursor: pointer;
                    margin-left:5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding:5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .done:focus {
                    outline: none;
                }
                @media (pointer: coarse) {
                    .done {
                        margin-left:10px;
                    }
                }
            `}</style>
            </div>
        )
    }
}

TimeEditorInput.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(TimeEditorInput);

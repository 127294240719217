const brightness = (color)=>{
    if(color.length==7){color=color.substring(1);}
    var R =	parseInt(color.substring(0,2),16);
    var G =	parseInt(color.substring(2,4),16);
    var B =	parseInt(color.substring(4,6),16);
    return Math.sqrt(R * R * .241 + G * G * .691 + B * B * .068);
}

exports.brightness = brightness;


/**
 * 
 * @param {String} color The colour you want a contrast for
 * @param {String} light OPT: The light colour you want returned for a dark backround. Default #fff
 * @param {String} dark OPT: The dark color you wanr returned for a light background. Default #000
 */

const getContrastingFill = (color, light, dark)=>{
    if(!light){
        light="#fff";
    }
    if(!dark){
        dark="#000";
    }
    return (brightness(color)<200) ? light : dark
}
exports.getContrastingFill = getContrastingFill;
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { effects, colors, fonts } from '../../../assets/styles/theme';
import { getContrastingFill } from '../../../libs/colorUtils';
import ZoneLabel from '../Text/ZoneLabel';

class PlanStepSheduledEvents extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            events : []
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        this.setInternals(this.props)
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        let events = props.events.filter((e)=>{
            if(e.type=='milestone') return false;
            return e.actionId === this.props.actionId
        })
        this.setState({events : events});
    }

    


    render(){

        if(this.props.type=="milestone") return null;
        
        
        if(!this.state.events.length || !this.props.time){
            return (
                <div>
                    <p>{(this.props.time)?"Unsheduled":"Allocate some time to this step to make it actionable"}</p>
                    <style jsx>{`
                        p {
                            ${fonts.regularIt}
                            color:${colors.mid}
                        }    
                    `}</style>
                </div>
            )
        };

        const renderEvents = ()=>{
            return this.state.events.map((event, idx)=>{
                
                return (
                    <div className="event" key={idx} style={{borderColor:this.props.color}}>
                        <div>
                            <svg className="icon" viewBox="0 0 97.16 97.16">
                                <path fill={getContrastingFill(this.props.color, this.props.color, colors.dark)} d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z"/>
                                <path fill={getContrastingFill(this.props.color, this.props.color, colors.dark)} d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z"/>
                            </svg>
                        </div>
                        <div className="time"
                            style={{
                                color : getContrastingFill(this.props.color, this.props.color, colors.dark)
                            }}
                        >
                            
                            <div>
                                {moment(event.start).format("ddd, MMM Do YYYY")}
                            </div>
                            <div>
                                <span>{moment(event.start).format('h:mma')}</span> - <span>{moment(event.end).format('h:mma')}</span>
                            </div>
                            
                        </div>
                        <style jsx>{`
                            .icon {
                                width: 14px;
                                height: 14px;
                                margin-right:5px;
                                margin-top:1px;
                            }
                            .event {
                                ${fonts.bold}
                                font-size:13px;
                                line-height: 1em;
                                border-radius: 30px;
                                margin:0px 3px 5px 0px;
                                ${effects.shadow1}
                                padding:2px 10px 2px 5px;
                                display: flex;
                                align-items: center;
                                border-width: 1px;
                                border-style: solid;
                            }  
                            .time {
                                flex:1;
                                display: flex;
                                justify-content: space-between;
                         
                            }  
                        `}</style>
                    </div>
                )
            })
        }
        return (
            <div className="PlanStepSheduledEvents" style={{...this.props.style}}>
                {this.props.children}
                {renderEvents()}

                <style jsx>{`
                    .PlanStepSheduledEvents {
                        max-height:100px;
                        overflow-y:auto;
                    }    
                `}</style>
            </div>

        )
    }
}

PlanStepSheduledEvents.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        events : state.event.events
    };
}

export default  connect(mapStateProps, {  })(PlanStepSheduledEvents);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, Form, Dropdown, Checkbox } from 'semantic-ui-react';
import { activateCalendars, deactivateCalendars, createHelmCalendar, syncHelmCalendar } from '../../../actions/timeActions';
import { fonts } from '../../../assets/styles/theme';


class PushHelmToCalendar extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.createUserHelmCalendar = this.createUserHelmCalendar.bind(this);
        this.syncUserHelmCalendar = this.syncUserHelmCalendar.bind(this);
        this.state = {
            isOpen : false,
            calendars : [],
            working : false,
            helmCalendarId : null,
            selectedHelmCalenderProfile : null,
            days : 1,
            setNotifications : false
        }
        
    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.props.activateCalendars();
        this.setInternals(this.props);
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    componentWillUnmount(){
        this.props.deactivateCalendars();
    }

    setInternals(props){

        
        let calendars = [];
        let helmCalendarId = null;

        if(props.linkedCalendars){
            let seen = new Set();
            props.linkedCalendars.map((cal)=>{
                if(!seen.has(cal.profile_name)){
                    seen.add(cal.profile_name);
                    calendars.push({
                        text : cal.profile_name,
                        value : cal.profile_id
                    })
                }
            });
        }

        if(props.profile){
            helmCalendarId = props.profile.helmCalendarId || null;
        }

        this.setState({
            calendars : calendars,
            helmCalendarId : helmCalendarId
        })
    }
    
    async createUserHelmCalendar(){
        try {
            this.setState({working : true})
            await this.props.createHelmCalendar(this.state.selectedHelmCalenderProfile);
            this.setState({working : false})
        }
        catch(e){
            console.error(e);
            this.setState({working : false})
        }
    }

    async syncUserHelmCalendar(){
        try {
            this.setState({working : true})
            await this.props.syncHelmCalendar(this.state.days, this.state.setNotifications);
            this.setState({working : false, isOpen : false})
        }
        catch(e){
            console.error(e);
            this.setState({working : false})
        }
    }


    render(){
        
        if(!this.state.calendars.length) return null;

        const renderCalendarChoice = ()=>{
            if(!this.props.profile.helmCalendarId){
                return (
                    <Form>
                        <Form.Field >
                            <p>Choose the account your want to use</p>
                            <Dropdown 
                                value={this.state.selectedHelmCalenderProfile}
                                onChange={(e,v)=>{
                                    this.setState({ selectedHelmCalenderProfile : v.value})
                                }}
                                placeholder="Select an account for Helm create your calendar" 
                                fluid 
                                selection 
                                options={this.state.calendars} />
                        
                        </Form.Field>
                    </Form>
                )
            } else {
                return (
                    <Form>
                        <p>Number of days ahead to send</p>
                        <Dropdown 
                            value={this.state.days}
                            onChange={(e,v)=>{
                                this.setState({ days : v.value})
                            }}
                            placeholder="Select an account for Helm create your calendar" 
                            fluid 
                            selection 
                            options={[
                                { value : 1, text : "1 Day" },
                                { value : 7, text : "7 Days" },
                                { value : 31, text : "31 Days" }
                            ]} />
                            <p>
                            <Checkbox 
                            value={this.state.setNotifications}
                            onChange={()=>{
                                this.setState({setNotifications : !this.state.setNotifications})
                            }}
                        /> Add notifications to calendar events
                            </p>
                        
                                
                    </Form>
                )
            }
        }

        const renderCalendarActions = ()=>{
            if(!this.props.profile.helmCalendarId){
                return (
                    <Button color='green' 
                        disabled={!this.state.selectedHelmCalenderProfile}
                        onClick={this.createUserHelmCalendar}
                        loading={this.state.working}
                    >
                        <Icon name='checkmark' /> Next
                    </Button>
                )
            } else {
                return (
                    <Button color='green' 
                        onClick={this.syncUserHelmCalendar}
                        loading={this.state.working}
                    >
                        <Icon name='sync alternate' /> Sync
                    </Button>
                )
            }
        }

        return (
            <div className="PushHelmToCalendar" style={{...this.props.style}}>
                <Button
                    size={(this.props.mobile)? 'tiny' : 'medium'}
                    color="teal"
                    icon="sync alternate"
                    content={(this.props.mobile)? 'Send Plan' : 'Send plan to calendar'}
                    fluid={true}
                    onClick={()=>{this.setState({isOpen : true})}}
                    loading={this.props.sequencing}
                >
                </Button>
                <Modal
                className="NewProjectModal"
                basic
                size="small"
                open={this.state.isOpen}
                onClose={()=>{this.setState({isOpen : false})}}
                >
                <Modal.Header>
                    <Icon name="alternate sync"></Icon>  Send plan to your calendar
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                    {renderCalendarChoice()}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red'
                            onClick={()=>{
                                this.setState({isOpen : false})
                            }}
                            inverted>
                        <Icon name='remove' /> Cancel
                    </Button>
                    {renderCalendarActions()}
                </Modal.Actions>
            </Modal>
            <style jsx>{`
                .PushHelmToCalendar {
                    ${fonts.regular}
                }
            `}</style>
            </div>
        )
    }
}

PushHelmToCalendar.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        profile : state.user.profile,
        calendarProfiles : state.time.calendarProfiles,
        linkedCalendars : state.time.linkedCalendars
    };
}

export default  connect(mapStateProps, { activateCalendars, deactivateCalendars, createHelmCalendar, syncHelmCalendar })(PushHelmToCalendar);

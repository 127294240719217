import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { effects, colors, fonts } from '../../../assets/styles/theme';
import moment from 'moment';

import { setSelectedPlanStepId } from '../../../actions/planAction';
import { createTimeNewLog } from '../../../actions/timeActions';
import { Button } from 'semantic-ui-react';
import { toggleEventDone } from '../../../actions/eventAction';


class FocusExternalEvent extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        
        let {summary, start, end, type, actionId, projectId, stepRemainderMins, id } = this.props.event;

        const projectColor = ()=>{
            return colors.mid;
        }

        const getTitle = ()=>{
            if(this.props.projects && this.props.projects[this.props.event.projectId]){
                return this.props.projects[this.props.event.projectId].title;
            }
            return "";
        }
        


        return (
            <div className="FocusExternalEvent">
                
                <div className="TopSection">
                    <div className="EventName">{summary}</div>
                </div>

                <div className="BottomSection">
                    <div className="EventTime">
                    Time today: {moment(start).format('h:mm a')} - {moment(end).format('h:mm a')}
                    </div>
                </div>

               
                <style jsx>{`
                    .FocusExternalEvent {
                        margin-bottom:20px;
                        border:1px solid ${projectColor()};
                        padding:5px;
                        border-left:20px solid ${projectColor()};
                        border-radius: 0px 3px 3px 0px;
                    }   
                    .projectTitle {
                        ${fonts.bold}
                        font-size:13px;
                        color: ${projectColor()};
                        margin-bottom:5px;
                    }
                    .EventName {
                        font-size:16px;
                        ${fonts.light}
                        color:${colors.dark};
                    }
                    .EventTime {
                        ${fonts.bold}
                        font-size:13px;
                        color: ${colors.dark};
                        margin-bottom:0px;
                    }
                    .BottomSection {
                        padding-top:0px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

FocusExternalEvent.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projectByKey
    };
}

export default  connect(mapStateProps, { setSelectedPlanStepId, createTimeNewLog, toggleEventDone })(FocusExternalEvent);

const INITIAL_STATE = {
    externalEvents : [],
    events : [],
    allEvents : [],
    expectedComplete : {},
    milestonesByKey : {},
    sequencing: false
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'USER_EXTERNAL_EVENTS_UPDATED':
            return {...state, externalEvents : action.payload, allEvents : state.events.concat(action.payload)  };
        case 'HELM_EVENTS_UPDATED':
            return {...state, events : action.payload, allEvents : state.externalEvents.concat(action.payload) };
        case 'SEQUENCING_STATUS_UPDATE':
            return {...state, sequencing : action.payload }
        case 'HELM_EXPECTED_COMPLETION_DATES_UPDATED':
            return {...state, expectedComplete : action.payload}
        case 'USER_MILESTONES_UPDATE':
            return {...state, milestonesByKey : action.payload}
        default:
            return state;
    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { effects, colors, fonts } from '../../../assets/styles/theme';
import moment from 'moment';

import { setSelectedPlanStepId } from '../../../actions/planAction';
import { createTimeNewLog } from '../../../actions/timeActions';
import { Button } from 'semantic-ui-react';
import { toggleEventDone } from '../../../actions/eventAction';
import { getContrastingFill } from '../../../libs/colorUtils';


class FocusEvent extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }


    
    render(){
        let {summary, start, end, type, actionId, projectId, stepRemainderMins, id } = this.props.event;

        console.log('event', this.props.event)
;        const projectColor = ()=>{

            if(this.props.event && this.props.event.complete){
                return colors.dark;
            }
            if(this.props.projects && this.props.projects[this.props.event.projectId]){
                return this.props.projects[this.props.event.projectId].color;
            }
            return colors.mid;
        }

        const getTitle = ()=>{
            if(this.props.projects && this.props.projects[this.props.event.projectId]){
                return this.props.projects[this.props.event.projectId].title;
            }
            return "";
        }
        
    
        return (
            <div className={(this.props.event.complete)?"FocusEvent complete" : "FocusEvent"}
                onClick={()=>{
                    if(type=='action'){
                        this.props.setSelectedPlanStepId(actionId);
                    }
                }}
            >
                
                <div className="TopSection">
                    
                    <div className="EventName">
                        <div className="projectTitle">{getTitle()} </div>
                    {summary}</div>
                    

                    <div className="EventTime">
                        <svg className="icon" viewBox="0 0 97.16 97.16">
                            <path fill={projectColor()} d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z"/>
                            <path fill={projectColor()} d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z"/>
                        </svg>
                        
                        {moment(start).format('h:mm a')} - {moment(end).format('h:mm a')}
                    </div>
                 

                </div>

                <div className="BottomSection">
                    
                   
                    <Button
                        content="Mark Done"
                        icon="check circle"
                        size="small"
                        color="blue"
                        compact
                        onClick={(e)=>{
                            e.stopPropagation();
                            let time = moment.duration(moment(end).diff(moment(start))).asMinutes();
                            this.props.createTimeNewLog(actionId, projectId, summary, time, stepRemainderMins, id )
                        }}
                    ></Button>
                   
                    
                </div>

               
                <style jsx>{`
                    .FocusEvent {
                        margin-bottom:20px;
                        ${effects.shadow1}
                        background: ${colors.white};
                        padding:10px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        margin-right:5px;
                        align-items: flex-end;
                    }  
                    .FocusEvent.complete {
                        opacity: 0.3;
                    } 
                    .TopSection {
                        flex:1;
                    }
                    .FocusEvent.complete .TopSection {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                    }
                    .projectTitle {
                        ${fonts.bold}
                        font-size:12px;
                        color: ${projectColor()};
                        margin-bottom:10px;
                        line-height: 1em;
                        display: flex;
                        align-items: center;
                    }
                    
                    .FocusEvent.complete .projectTitle {
                        color: ${colors.mid};
                        margin-bottom:2px;
                    }

                    .EventName {
                        font-size:20px;
                        ${fonts.bold}
                        color:${colors.dark};
                    }

                    .FocusEvent.complete .EventName {
                        font-size:15px;
                    }


                    .EventTime {
                        ${fonts.bold}
                        font-size:13px;
                        line-height: 1em;
                        border-radius: 30px;
                        margin:20px 3px 0px 0px;
                        padding:2px 8px 2px 3px;
                        display: inline-flex;
                        align-items: center;
                        border-width: 1px;
                        border-style: solid;
                        border-color:${projectColor()};
                        color: ${projectColor()};
                        justify-content: space-between;
                        height: 21px;
                        white-space: nowrap;
                    }

                    .FocusEvent.complete .EventTime {
                        margin-top:0px;
                    }
                    .BottomSection {
                        padding-top:10px;
                    }
                    .FocusEvent.complete .BottomSection {
                        display: none;
                    }
                    .icon {
                        width: 15px;
                        height: 15px; 
                        margin-right:3px;
                    }
                    @media all and (max-width:697px){
                        .EventName {
                            font-size:16px;
                        }
                        .FocusEvent {
                            display: block;
                        }  
                    }
                `}</style>
            </div>
        )
    }
}

FocusEvent.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projectByKey
    };
}

export default  connect(mapStateProps, { setSelectedPlanStepId, createTimeNewLog, toggleEventDone })(FocusEvent);

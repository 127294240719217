import firebase from 'firebase';
import axios from 'axios';
import { setUserId } from '../analytics';

export const sampleAction = ()=> {
    return (dispatch)=> {
        dispatch({
            type: 'SAMPLE_ACTION',
            payload : null
        });


    }
}

export const updateMenuState = (open)=> {
    return (dispatch)=> {
        dispatch({
            type: 'MENU_STATE_UPDATE',
            payload : open
        });


    }
}



export const loadFirebase = ()=> {

    return (dispatch)=> {

        return new Promise((resolve, reject)=>{

            dispatch({type: 'FIREBASE_INITIALISING'})

            const initFirebase = (config)=>{


                if (!firebase.apps.length) {
                    firebase.initializeApp(config);
                    firebase.firestore().settings({ timestampsInSnapshots: true});
                    resolve(true)
                } else {
                    resolve(true)
                }



                firebase.database().ref('.info/connected').on('value', (snap)=> {
                    
                    dispatch({type: 'FIREBASE_CONNECTED', payload: snap.val()})
                })


                firebase.auth().onAuthStateChanged((user)=> {
                    dispatch({type: 'FIREBASE_AUTHSTATE_CHANGE', payload: user});

                    if(user){
                        setUserId(user.uid);
                    }
                })

                
                firebase.firestore().enablePersistence({experimentalTabSynchronization:true})
                    .then(
                        ()=>{
                    dispatch({type: 'FIRESTORE_PERSITANCE_ENABLED', payload: true})
                },
                    (err)=>{
                    dispatch({type: 'FIRESTORE_PERSITANCE_ENABLED', payload: err})
                }
            )

            
            }


            axios.get('/__/firebase/init.json')
                .then(
                    (resp)=> {
                if(resp.data.databaseURL){
                //it's a valid firebase object
                initFirebase(resp.data);
            } else {
                initFirebase({
                    apiKey: "AIzaSyCwFqocyoXKJVGMWZqjsHQDirSfnPv6W1w",
                    authDomain: "helm-3-dev.firebaseapp.com",
                    databaseURL: "https://helm-3-dev.firebaseio.com",
                    projectId: "helm-3-dev",
                    storageBucket: "helm-3-dev.appspot.com",
                    messagingSenderId: "277820149903"
                });
                // console.log('response invalid');
            }
        }
        )
        .catch((e)=> {
                //default for local testing
                initFirebase({
                    apiKey: "AIzaSyCwFqocyoXKJVGMWZqjsHQDirSfnPv6W1w",
                    authDomain: "helm-3-dev.firebaseapp.com",
                    databaseURL: "https://helm-3-dev.firebaseio.com",
                    projectId: "helm-3-dev",
                    storageBucket: "helm-3-dev.appspot.com",
                    messagingSenderId: "277820149903"
                });

        })

        })
        

        //load auth from /__/firebase/init.json - JSON representation of the configuration
        //https://firebase.googleblog.com/2017/04/easier-configuration-for-firebase-on-web.html
        //Maybe load it and if it fails (which it will locally, fallback to the default test one).



    }
}


export const initPermissions = ()=>{
    return (dispatch, getState)=>{
        firebase.auth().onAuthStateChanged((user)=> {
            if(user){
                firebase.firestore()
                .collection('permissions')
                .doc(user.uid)
                .onSnapshot((snap)=>{
                    let data = snap.data() || {};
                    dispatch({type: 'USER_PERMISSIONS_UPDATE', payload: data});
                });

            } else {
                dispatch({type: 'USER_PERMISSIONS_UPDATE', payload: {}});
            }
        })
    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ZoneSubTitle from '../common/ZoneSubTitle';
import PlanList from '../common/PlanList';
import BrainDumpList from '../common/BrainDumpList';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import firebase from 'firebase';
import { bulkUpdateSortOrderAndProjectId } from '../../actions/planAction';



class Plans extends React.Component {

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            projects : [],
            projectSteps : {},
            listeners : []
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
    }
    
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    
    async setInternals(props){
        let attrs = {
            projects : [],
            listeners : [],
            projectSteps : {...this.state.projectSteps}
        };

        try {
            if(props.projects){
                
                attrs.projects = props.projects.filter((p)=>{
                    return !(p.archived);
                });
                
                for(let i=0; i<attrs.projects.length; i++){
                    attrs.listeners.push(firebase.firestore()
                        .collection(`users/${this.props.user.uid}/planSteps`)
                        .where('projectId', '==', attrs.projects[i].id)
                        .where('complete', '==', false)
                        .orderBy('sortKey', 'asc')
                        .orderBy('updatedAt', 'asc')
                        .onSnapshot((data)=>{
                            let projectSteps = {...this.state.projectSteps};
                            projectSteps[attrs.projects[i].id] = [];
                            data.forEach((r)=>{
                                projectSteps[attrs.projects[i].id].push(r.data());
                            })
                            this.setState({projectSteps : projectSteps})
                        })
                    );
                }
            }
            attrs.projectSteps.brainDump = props.braindumpSteps

        

            
            this.setState({...attrs});
        }
        catch(e){
            console.error(e);
        }
    }

    componentWillUnmount(){
        this.state.listeners.map((l)=>{
            if(l) l();
        })
    }

    onDragEnd(result){
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        //clone the project steps
        let projectSteps = {...this.state.projectSteps};
        //take out the dropped step
        let dropped = projectSteps[result.source.droppableId].splice(result.source.index, 1);
        //insert into destination array
        projectSteps[result.destination.droppableId].splice(result.destination.index, 0, dropped[0]);

        let sortUpdate = projectSteps[result.destination.droppableId].map((i, idx)=>{
            return {...i, sortKey : idx};
        })
        
        this.props.bulkUpdateSortOrderAndProjectId(sortUpdate, result.destination.droppableId);
        console.log(projectSteps);
      
    }
    render(){

        const renderProjects = ()=>{
            return this.props.projects
                .filter((p)=>{
                    return !(p.archived);
                })
                .map((p)=>{
                    return (
                        <div className="project" key={p.id}>
                            <ZoneSubTitle style={{textAlign:'center', marginBottom:20}}>{p.title}</ZoneSubTitle>
                            <PlanList project={p} steps={this.state.projectSteps[p.id]}></PlanList>
                        </div>
                    )
                })
        }
        return (
            <div className="Plans">
                    
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        {renderProjects()}
                        <BrainDumpList steps={this.state.projectSteps.brainDump}></BrainDumpList>
                    </DragDropContext>
                
                
                <style jsx>{`
                    .Plans {
                        display: flex;

                    }

                `}</style>
            </div>
        )
    }
}

Plans.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projects,
        user : state.app.authedUser,
        braindumpSteps : state.braindump.steps
    };
}

export default  connect(mapStateProps, { bulkUpdateSortOrderAndProjectId })(Plans);

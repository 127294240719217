import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ZoneTitle from '../ZoneTitle';
import Loading from '../Loading';
import { loadProjectLog, stopLogListener, saveLog, updateSearchString, deleteLog } from '../../../actions/logAction';
import ProjectLogEditor from './ProjectLogEditor';
import moment from 'moment'
import Linkify from 'react-linkify';
import { Input, Button, Icon } from 'semantic-ui-react';
import FlipMove from 'react-flip-move';
import ZoneSubTitle from '../ZoneSubTitle';
import { colors } from '../../../assets/styles/theme';
import ProjectLogEntry from './ProjectLogEntry';
import { createTimeNewLog } from '../../../actions/timeActions';
import TimeLengthDisplay from '../TimeLengthDisplay';


class ProjectLog extends React.Component {

    constructor(props){
        super(props);
        this.renderLog = this.renderLog.bind(this);
        this.updatePin = this.updatePin.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.getLogs = this.getLogs.bind(this);
        this.renderPinned = this.renderPinned.bind(this);
        this.renderUnPinned = this.renderUnPinned.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.renderSearchBox = this.renderSearchBox.bind(this);
        this.state = {
            unPinnedLength : 2,
            totalProjectTime : 0
        }
        

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        this.props.loadProjectLog(this.props.project.id);
        this.setInternals(this.props);
    }
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        if(props.logs && props.logs.length){
            let totalProjectTime = 0;
            props.logs.map((l)=>{
                if(l.type=='time'){
                    totalProjectTime+=l.time;
                }
            })
            this.setState({totalProjectTime : totalProjectTime});
        } else {
            this.setState({totalProjectTime : 0});
        }
        
    }
    componentWillUnmount(){
        this.props.stopLogListener();
    }

    updatePin(log){
        log.pinned = !log.pinned;
        this.props.saveLog(log);
    }


    getLogs(pinned){
        return this.props.logs
        .filter((l)=>{
            return (l.pinned == pinned)
        });
    }
    renderLog(logs){
        if(this.props.loading){
            return <Loading>LOADING PROJECT LOG</Loading>
        }
      
        if(!logs.length) return null;


        return logs.map((l)=>{
            return <ProjectLogEntry key={l.id} log={l} onPinClick={()=>{this.updatePin(l)}} onDelete={()=>{this.props.deleteLog(l.id)}}/>
        })
        

    }


    renderPinned(){
        if(this.props.searchString) return null;
        let pinned = this.props.logs
        .filter((l)=>{
            return (l.pinned == true)
        });
        if(pinned.length){
            return (
                <div className="ProjectListPinned">
                    <ZoneSubTitle>Pinned Logs</ZoneSubTitle>
                    <div className="list">
                        <FlipMove enterAnimation="fade" leaveAnimation="fade">
                        {this.renderLog(pinned)}
                        </FlipMove>
                    </div>
                    <style jsx>{`
                            .ProjectListPinned {
                                margin-top:20px;
                                max-height: 40vh;
                                display: flex;
                                flex-direction: column;
                            }
                            .list {
                                flex:1;
                                overflow-y: auto;
                                -webkit-overflow-scrolling: touch;
                                padding-right:20px;
                                margin-top:10px;
                                margin-bottom:20px;
                            }    
                        `}</style>
                </div>
            )
        }
        return null;
        
    }

    renderUnPinned(){
        if(this.props.searchString) return null;

        
        let logs = this.props.logs
        .filter((l)=>{
            return (l.pinned == false)
        });
        if(logs.length){
            return (
                <div className="ProjectList">
                    <ZoneSubTitle>Logs</ZoneSubTitle>
                    <div className="list">
                        <FlipMove enterAnimation="fade" leaveAnimation="fade">
                        {this.renderLog(logs)}
                        </FlipMove>
                    </div>
                    <style jsx>{`
                            .ProjectList {
                                margin-top:20px;
                                flex:1;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                overflow: hidden;
                            }
                            
                            .list {
                                flex:1;
                                overflow-y: auto;
                                -webkit-overflow-scrolling: touch;
                                padding-right:20px;
                                margin-top:10px;
                                margin-bottom:20px;
                            }    
                        `}</style>
                </div>
            )
        }
        return null;
        
    }

    renderSearch(){
        if(!this.props.searchString) return null;
        return (
            <div className="SearchList">
                <ZoneSubTitle>Log Search Results</ZoneSubTitle>
                <div className="list">
                    <FlipMove enterAnimation="fade" leaveAnimation="fade">
                        {(this.props.searchResults.length)? this.renderLog(this.props.searchResults) : <p>No matches found</p>}
                        <Button icon
                            size="mini"
                            onClick={()=>{this.props.updateSearchString('')}}
                        >
                            Clear Search 
                            <Icon name="close"></Icon>
                        </Button>
                    </FlipMove>
                </div>
                
                <style jsx>{`
                        .SearchList {
                            margin-top:20px;
                            flex:1;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                        }
                        
                        .list {
                            flex:1;
                            overflow-y: auto;
                            -webkit-overflow-scrolling: touch;
                            padding-right:20px;
                            margin-top:10px;
                            margin-bottom:20px;
                        }    
                    `}</style>
            </div>
        )
    }

    renderSearchBox(){
        if(!this.props.logs.length) return null;
        return (
            <div className="searchBox">
                <Input icon='search' 
                    fluid 
                    placeholder='Search project logs...'
                    value={this.props.searchString}
                    onChange={(e, v)=>{ this.props.updateSearchString(v.value) }}
                />
                <style jsx>{`
                    .searchBox {
                        margin-bottom:20px;
                    }
                `}</style>
            </div>
        )   
    }

   

    render(){
        let logs = this.getLogs(false);

        const renderTotalTime = ()=>{
            if(!this.state.totalProjectTime) return null;
            return (
                <div className="totalLoggedTime">
                    <TimeLengthDisplay units={true} prefix="Total Time Logged: " time={this.state.totalProjectTime}></TimeLengthDisplay>
                <style jsx>{`
                    .totalLoggedTime {
                        margin-top: 0px;
                        margin-bottom: 20px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        color: rgb(44, 44, 44);
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-family: Lato, sans-serif;
                        font-weight: 700;
                        font-style: normal;
                        padding: 10px;
                        background: rgb(243, 243, 243);
                        border-radius: 0px 0px 5px 5px;
                    }
                    
                `}</style>
                </div>
            )
        }
        
        return (
            <div className="ProjectLog">
            
                <ZoneSubTitle style={{marginBottom:20}}>
                    <Button
                        floated="right"
                        content="Log time"
                        icon="time"
                        size="tiny"
                        color="blue"
                        onClick={()=>{
                            this.props.createTimeNewLog(null, this.props.project.id, "", 0, 0, null )
                        }}
                    ></Button>
                    <span style={{position : 'relative', top:3}}>Activity Log</span>
                </ZoneSubTitle>
                   
                
                <ProjectLogEditor project={this.props.project}>
                
                </ProjectLogEditor>
                
                {this.renderPinned()}
                {this.renderUnPinned()}

                {renderTotalTime()}
                {/* {this.renderSearch()}
                {this.renderSearchBox()} */}
            
                <style jsx>{`
                    .ProjectListPinned {
                        margin-top:20px;
                        max-height: 40vh;
                        display: flex;
                        flex-direction: column;
                    }
                    .list {
                        flex:1;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        padding-right:20px;
                        margin-top:10px;
                        margin-bottom:20px;
                    }
                    .ProjectLog {
                        padding:0px 20px;
                        display: flex;
                        flex-direction: column;
                        flex:1;
                        width: 100%;
                        overflow: hidden;
                        
                    }    
                    .ProjectList {
                        flex:1;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden

                    }
                    .searchBox {
                        margin-bottom:20px;
                    }
                `}</style>
            </div>
        )
    }
}


ProjectLog.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        loading : state.log.loading,
        logs : state.log.logs,
        searchString : state.log.searchString,
        searchResults : state.log.searchResults
    };
}

export default  connect(mapStateProps, { loadProjectLog , stopLogListener, saveLog, updateSearchString, deleteLog, createTimeNewLog})(ProjectLog);

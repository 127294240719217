import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '../../../assets/styles/theme';
import { TweenMax } from 'gsap/all';
import './../../../gsap-bonus/DrawSVGPlugin';
import { updateMenuState } from '../../../actions/appActions';
import SequenceTrigger from './SequenceTrigger';

class HeaderBar extends React.Component {

    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.animate = this.animate.bind(this);

    }
    componentWillMount(){ }
    
    componentDidMount(){
        //set defaults
        TweenMax.set('#top', {drawSVG : "0% 70%", rotation : -45, svgOrigin : "3 3"})
        TweenMax.set('#mid', { drawSVG : "0% 100%"})
        TweenMax.set('#bottom', {drawSVG : "0% 70%",rotation : 45, svgOrigin : "3 27"});
    
        this.animate(this.props.open)
    }

    componentWillReceiveProps(newProps){
      this.animate(newProps.open);
    }

    animate(open){
        if(open){
            TweenMax.to('#top', 0.3, {drawSVG : "0% 100%",rotation : 0, svgOrigin : "3 3"})
            TweenMax.to('#mid', 0.3, { drawSVG : "50% 50%"})
            TweenMax.to('#bottom', 0.3, {drawSVG : "0% 100%",rotation : 0, svgOrigin : "3 27"})
        } else {
            TweenMax.to('#top', 0.3, {drawSVG : "0% 70%", rotation : -45, svgOrigin : "3 3"})
            TweenMax.to('#mid', 0.3, { drawSVG : "0% 100%"})
            TweenMax.to('#bottom', 0.3, {drawSVG : "0% 70%",rotation : 45, svgOrigin : "3 27"})
        }
    }
    

     toggle(){
        let open = !this.props.open;
        this.props.updateMenuState(open)
    }

    render(){
        return (
            <div className="HeaderBar">
                
                <div className="left">
                    <div className="toggle-button-wrap"  onClick={this.toggle}>
                            <svg className="toggle-svg" viewBox="0 0 30 30">
                            <line id="top" x1="3" y1="3" x2="27" y2="27" fill="none" stroke={colors.primary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
                            <line id="bottom" x1="3" y1="27" x2="27" y2="3" fill="none" stroke={colors.primary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
                            <line id="mid" x1="3" y1="15" x2="27" y2="15" fill="none" stroke={colors.primary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
                        </svg>
                    </div>

                    <div className="logo-wrap">
                        <svg  className="logo" viewBox="0 0 248 48">
                            <g>
                                <path d="M41.16,47.9h-9V27H9.8V47.9h-9V.1h9V20.66H32.2V.1h9Z" fill={colors.white}/>
                                <path d="M105.64.1V7.18H84.46V20.43h16.69v6.84H84.46V40.79h21.18V47.9H75.5V.1Z" fill={colors.white}/>
                                <path d="M146.28,40.56h19.14V47.9h-28V.1h8.89Z" fill={colors.white}/>
                                <path d="M220.37,30.26c.32.76.64,1.53.95,2.3.3-.79.62-1.57,1-2.35S223,28.7,223.33,28L237.48,1.53a3.82,3.82,0,0,1,.54-.8,1.73,1.73,0,0,1,.63-.43,3,3,0,0,1,.78-.16c.28,0,.62,0,1,0h6.71V47.9h-7.83V17c0-.58,0-1.2,0-1.89s.08-1.37.15-2.08l-14.45,27.1a3.77,3.77,0,0,1-1.29,1.44,3.31,3.31,0,0,1-1.85.51h-1.22a3.34,3.34,0,0,1-1.85-.51,3.77,3.77,0,0,1-1.29-1.44L202.94,13q.13,1.09.18,2.13c0,.7,0,1.34,0,1.94V47.9h-7.83V.1h6.71c.39,0,.74,0,1,0a3,3,0,0,1,.78.16,1.76,1.76,0,0,1,.64.43,3.21,3.21,0,0,1,.56.8l14.28,26.54C219.71,28.77,220.05,29.5,220.37,30.26Z" fill={colors.white}/>
                            </g>
                        </svg>
                    </div>
                </div>
                
                <div className="right">
                    <SequenceTrigger></SequenceTrigger>
                </div>
                

                <style jsx>{`
                    .HeaderBar {
                        position: fixed;
                        top:0px;
                        left:0px;
                        right:0px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        min-height: 50px;
                        box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
                        background: ${colors.dark};
                        z-index: 1000;
                    }

                    .logo-wrap {
                        display: flex;
                        align-items: center;
           
                    }
                   
                    .logo {
                        width: 94px;
                        height: 18px;
                       margin-left:5px;
                    }
                    .menuTrigger {
                        width:30px;
                        height:30px;
                        display: block;
                    }

                    .left {
                        display: flex;
                        align-items: center;
                    }
                    .right {
                        display: flex;
                        align-items: center;
                        padding-right:10px;
                    }
                    .toggle-button-wrap {
                        height: 40px;
                        padding-right:10px;
                        padding-left:10px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .toggle-svg {
                        width:28px;
                        height: 28px;
                        display: block;
                        margin-left:5px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

HeaderBar.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        open : state.app.menuOpen
    };
}

export default  connect(mapStateProps, { updateMenuState })(HeaderBar);


exports.colors = {
    dark : '#2C2C2C',
    mid : "#B5B5B5",
    midLo : '#707070',
    midHi : "#cacaca",
    backgroundHi : '#f3f3f3',
    primaryHi : "#45d6c0",
    primary : "#00C9AA",
    primaryLo : "#293d48",
    white : '#fff',
    error : "#FF3C42",
    green  : "#87B51F",
    note : "#fffbd9"
}


exports.fonts = {
    light : `
    font-family: Lato, sans-serif;
    font-weight: 300;
    font-style: normal;   
    `,
    regular : `
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-style: normal;  
    `,
    regularIt : `
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-style: italic;  
    `,
    bold : `
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-style: normal;
    `,
    time : `
    font-family: 'Fjalla One', monospace;    
    font-style: normal;
    `,
    
}
exports.effects = {
    shadow1 : `
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);  
    `,
    noSelect : `
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;   
    
    `
}
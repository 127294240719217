import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, fonts, effects } from '../../assets/styles/theme';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { processCalendarCode, activateCalendars, deactivateCalendars, updateActiveCalendars, refreshCalendarFeed } from '../../actions/timeActions';
import UserCalendarPanel from './UserCalendarPanel';
import _ from 'lodash';
import { Button, Loader } from 'semantic-ui-react';

class UserCalendarSettings extends React.Component {

    constructor(props){
        super(props);
        this.handleAuthCode = this.handleAuthCode.bind(this);
        this.renderProfiles = this.renderProfiles.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.refreshCalendars = this.refreshCalendars.bind(this);
        this.state = {
            authorizing : false,
            error : '',
            unsavedChanges : false,
            calendarProfiles : [],
            linkedCalendars : [],
            working : false,
            refreshing: false
        }
    }
    componentWillMount(){

    }
    
    componentDidMount(){ 
        this.props.activateCalendars();
        this.handleAuthCode();
        this.setInternals(this.props)
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    componentWillUnmount(){
        this.props.deactivateCalendars();
    }

    setInternals(props){
        this.setState({
            calendarProfiles : props.calendarProfiles,
            linkedCalendars : props.linkedCalendars
        })
    }


    async handleAuthCode(){
        const parsed = queryString.parse(window.location.search);
        
        if(parsed.code){
            this.setState({authorizing : true, error : ''});
            try {
                
                await this.props.processCalendarCode(parsed.code);
                this.setState({authorizing : false, error : ''});
                this.props.history.replace('/time');
                
            }
            catch(e){
                console.error(e);
                this.setState({authorizing : false, error: e });
            }
        }
    }

    toggleCalendar(calendarId, newVal){
        console.log('toggleCalendar',calendarId, newVal )
        let calendars = this.state.linkedCalendars.slice();
        let c = _.find(calendars, { 'calendar_id': calendarId });
        c.trackingCalendar = newVal;
        console.log('calendars', calendars);
        this.setState({
            linkedCalendars : calendars,
            unsavedChanges : true
        })

    }

    async refreshCalendars(){
        if(this.state.refreshing) return;
        try {
            this.setState({
                refreshing : true
            })
            await this.props.refreshCalendarFeed();
            this.setState({
                refreshing : false
            })
        }
        catch(e){
            console.error(e);
            this.setState({
                refreshing : false
            })
        }
    }
 

    async saveChanges(){
        if(!this.state.unsavedChanges)return;
        try {
            this.setState({
                working : true
            })
            await this.props.updateActiveCalendars(this.state.linkedCalendars);
            this.setState({
                working : false,
                unsavedChanges : false
            })
        }
        catch(e){
            console.error(e);
            this.setState({
                working : false
            })
        }
    }

    renderProfiles(){
        let profiles = new Set();
        this.state.linkedCalendars.map((cal)=>{
            profiles.add(cal.profile_name);
        })
        let profileNames = Array.from(profiles);

        if(this.state.authorizing){
            return (
                <div className="authorizing">
                    <Loader active>
                        <div style={{ color : colors.white, fontSize:14, color : colors.midHi }}>Syncing Calandars</div>

                    </Loader>
                <style jsx>{`
                    .authorizing {
                        margin-bottom:10px;
                        padding:10px;
                        ${effects.shadow1}
                        background: ${colors.white};
                        overflow: hidden;
                        flex:1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height:100px;
                        position: relative;
                    }

                `}</style>
                </div>
            )
        }
        
        return profileNames.map((profileName, idx)=>{
            return (
                <UserCalendarPanel 
                    linkedCalendars={this.state.linkedCalendars} 
                    profile={profileName}
                    key={idx} 
                    toggleCalendar={this.toggleCalendar}
                />
            )
        })
    }
    render(){

       
        let loc = window.location;
        let redirectUrl = `${loc.origin}${loc.pathname}`;

        console.log('redirectUrl', redirectUrl);
        return (
            <div className="UserCalendarSettings">

                <a className="createNew" href={`https://app.cronofy.com/oauth/authorize?response_type=code&client_id=J-ggFZU6onlytI5ntMZNiQsMfiZ_4yB5&redirect_uri=${redirectUrl}&scope=create_calendar read_events create_event delete_event&state=${this.props.user.uid}&provider_name`}>
                    <div className="label">Connect a calendar</div>
                    <svg className="createNewIcon" width="30" height="30" viewBox="0 0 30 30">
                        <path fill={colors.white} d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm0,25.38A11.38,11.38,0,1,1,26.38,15,11.4,11.4,0,0,1,15,26.38ZM22,15a1,1,0,0,1-1,1H16v5a1,1,0,0,1-2,0V16H9a1,1,0,0,1,0-2h5V9a1,1,0,0,1,2,0v5h5A1,1,0,0,1,22,15Z"/>
                    </svg>
                </a>
                

                <div className="calenderPanels">
                    {this.renderProfiles()}
                </div>
                
                <Button fluid={true} disabled={!this.state.unsavedChanges} color={(this.state.unsavedChanges)?'orange' : 'grey'} loading={this.state.working} onClick={this.saveChanges}>Save Changes</Button>

                

                <div className="refresh">
                    <Button 
                        fluid={true}
                        onClick={this.refreshCalendars}
                        loading={this.state.refreshing}
                        size="mini"
                    >Force refresh calendars</Button>
                </div>
                
                
                <style jsx>{`
                    .calenderPanels {
                        margin-top:10px;
                    }
                    .createNew {
                        background: ${colors.primary};
                        display: flex;
                        color:${colors.white};
                        align-items: center;
                        
                    }  
                    .label {
                        flex:1;
                        ${fonts.bold}
                        padding-left:10px;
                    }  
                    .refresh {
                        margin:10px 0px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

UserCalendarSettings.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        user : state.app.authedUser,
        calendarProfiles : state.time.calendarProfiles,
        linkedCalendars : state.time.linkedCalendars
    };
}

export default  withRouter(connect(mapStateProps, { processCalendarCode, activateCalendars, deactivateCalendars, updateActiveCalendars, refreshCalendarFeed })(UserCalendarSettings));

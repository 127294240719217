import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ZoneTitle from '../ZoneTitle';
import { Form, Input, TextArea, Checkbox, Label } from 'semantic-ui-react';
import { saveProject } from '../../../actions/projectAction';
import {TwitterPicker, SwatchesPicker} from 'react-color';
import { colors, effects } from '../../../assets/styles/theme';
import ZoneLabel from '../ZoneLabel';
import Slider, { Range } from 'rc-slider';
import DateSelector from '../DateSelector';
import TimeBucketDropDown from '../TimeBucketDropDown';
class ProjectMetaEditor extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.updateKey = this.updateKey.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.toggleArchive = this.toggleArchive.bind(this);
        this.togglePoolMode = this.togglePoolMode.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
        this.state = {
            project : {},
            localChanges : false,
            colorOpen : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props)
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    setInternals(props){
        this.setState({project : props.project});
    }

    saveChanges(){
        if(this.state.localChanges){
            this.props.saveProject(this.state.project);
            this.setState({localChanges : false});
        }
    }

    renderLabel(){
        return (this.props.project.pool) ? 'Pool' : "Stream";
    }

    async toggleArchive(){
        let project = this.state.project
        if(project.archived){
            project.archived = false;
            project.sortKey=0;
        } else {
            project.archived = true;
            project.sortKey = -1;
        }
        try {
            await this.props.saveProject(this.state.project);
        }
        catch(e){
            console.error(e);
        }
    }

    async togglePoolMode(){
        let project = this.state.project
        if(project.pool){
            project.pool = false;
        } else {
            project.pool = true;
        }
        try {
            await this.props.saveProject(this.state.project);
        }
        catch(e){
            console.error(e);
        }
    }
    
    updateKey(key, value, forceSave){
        let project = this.state.project;
        project[key]=value;
        if(forceSave){
            this.props.saveProject(project);
        }
        this.setState({
            project : project,
            localChanges : true
        });
    }
    render(){
        let {title, color, purpose, personalImportance, dueDate, allocateTo, archived, pool } = this.state.project;
        
        const renderPicker = ()=>{
            if(this.state.colorOpen){
                return (
                    <div className="color-pick-panel">
                            <SwatchesPicker
                            color={this.state.project.color}
                            onChange={(color)=>{
                                this.updateKey('color', color.hex, true);
                            }}
                        ></SwatchesPicker>
                        <style jsx>{`
                        .color-pick-panel {
                            position: absolute;
                            bottom:-225px;
                            left:15px;
                        }
                            `}</style>
                        </div>
                )
            } else {
                return null
            }
        }


        const renderImportance = ()=>{
            if(this.props.project.pool){
                return null;
            }
            return (
                <div>
                    <ZoneLabel>Personal importance</ZoneLabel>
                    <div>
                    <Slider
                        min={-1}
                        max={1}
                        step={0.1}
                        value={personalImportance}
                        onChange={(val)=>{this.updateKey('personalImportance', val, true)}}
                        railStyle={{
                             background:colors.white
                        }}
                        trackStyle={{
                            background: colors.primary,
                            opacity:0
                        }}
                        handleStyle = {{
                            borderColor : colors.primary,
                            background : colors.primary
                        }}
                    ></Slider>
                    </div>
                    <div className="slider-labels">
                        <span>Less important</span>
                        <span>Normal</span>
                        <span>More important</span>
                    </div>

                </div>
            )
        }

       
        return (
            <div className="ProjectMetaEditor">
                <ZoneTitle>{this.renderLabel()} Details</ZoneTitle>
                <Form className="ProjectMetaEditor-form" style={{paddingTop:20}} >

                    <div style={{marginBottom:20}}>
                        <ZoneLabel>
                            <Label color='teal' horizontal>Alpha Feature</Label>
                            <Checkbox style={{marginRight:10, position:'relative', top:7}} toggle checked={pool} onClick={this.togglePoolMode} />Use Pool Mode </ZoneLabel>
                        <ZoneLabel style={{opacity : 0.5, marginTop:5, maxWidth:400, lineHeight:"1.1em"}}>Pool Mode is used for individual tasks that don't need to be done in any particular order.</ZoneLabel>
                    </div>

                    <div className="top-inputs">

                   

                        <div className="title-input" >
                        <ZoneLabel>{this.renderLabel()} name</ZoneLabel>
                        <Input 
                            size="large"
                            fluid
                            onChange={(e,v)=>{ this.updateKey('title', v.value) }}
                            onBlur={this.saveChanges}
                            value={title || ''}
                        />
                        </div>
                        <div>
                            <ZoneLabel style={{textAlign:'right'}}>Colour</ZoneLabel>
                            <div className="color-pick" onClick={()=>{this.setState({colorOpen : !this.state.colorOpen})}}>
                                <div className="color-pick-swatch" style={{background : this.props.project.color || colors.primary}}></div>
                                {renderPicker()}
                            </div>
                        </div>
                    </div>
                    <ZoneLabel>{this.renderLabel()} Purpose</ZoneLabel>
                    <TextArea
                        fluid="true"
                        autoHeight
                        style={{maxHeight:'30vh', minHeight:'50px', marginBottom:10}}
                        value={purpose}
                        onChange={(e,v)=>{ this.updateKey('purpose', v.value) }}
                        onBlur={this.saveChanges}
                    >

                    </TextArea>
                    {renderImportance()}
                    
                    
                    
                    {/* <ZoneLabel>Personal importance</ZoneLabel>
                    <div>
                    <Slider
                        min={-1}
                        max={1}
                        step={0.1}
                        value={personalImportance}
                        onChange={(val)=>{this.updateKey('personalImportance', val, true)}}
                        railStyle={{
                             background:colors.white
                        }}
                        trackStyle={{
                            background: colors.primary,
                            opacity:0
                        }}
                        handleStyle = {{
                            borderColor : colors.primary,
                            background : colors.primary
                        }}
                    ></Slider>
                    </div>
                    <div className="slider-labels">
                        <span>Less important</span>
                        <span>Normal</span>
                        <span>More important</span>
                    </div> */}

                    {/* <div className="dueDate">
                        <ZoneLabel>Plan due date</ZoneLabel>
                        <DateSelector
                            value={dueDate}
                            onChange={(dateObj)=>{ this.updateKey('dueDate', dateObj, true) }}
                        />
                    </div>
 */}

                    <div className="timeBucket">
                        <ZoneLabel>{this.renderLabel()} time bucket</ZoneLabel>
                        <TimeBucketDropDown
                            value={allocateTo}
                            onChange={(bucketId)=>{ this.updateKey('allocateTo', bucketId, true) }}
                        ></TimeBucketDropDown>
                    </div>

                    <div className="archived">
                            <ZoneLabel><Checkbox style={{marginRight:10, position:'relative', top:7}} toggle checked={archived} onClick={this.toggleArchive} />{this.renderLabel()} archived </ZoneLabel>
                    </div>

                    
                    
                    
                </Form>    
             
                <style jsx>{`
                        .ProjectMetaEditor {
                            width: 100%;
                            padding-right:40px;
                          
                        }
                        .ProjectMetaEditor-form {
                            width: 100%;
                            padding-top:20px;
                            display: block;
                        }
                        .color-pick {
                            width: 41px;
                            height:41px;
                            background: ${colors.white};
                            ${effects.shadow1}
                            border-radius: 5px;
                            position: relative;   
                            z-index: 1;
                            margin-left:5px;
                        }
                        .color-pick-swatch {
                            position: absolute;
                            top:4px;
                            left:4px;
                            right:4px;
                            bottom:4px;
                            background: ${colors.primary};
                            border-radius:21px;
                        }
                        
                        .top-inputs {
                            display: flex;
                            margin-bottom:20px;
                        }
                        .title-input {
                            flex:1;
                        }
                        .ProjectMetaEditor :global(.slider-labels) {
                            display: flex;
                           
                        }
                        .ProjectMetaEditor :global(.slider-labels) :global(span)  {
                            flex:1;
                            font-size:11px;
                            color:${colors.mid}
                            
                        }
                        .ProjectMetaEditor :global(.slider-labels) :global(span:nth-child(2)) {
                            text-align: center;
                        }
                        .ProjectMetaEditor :global(.slider-labels) :global(span:nth-child(3)) {
                            text-align: right;
                        }
                        .dueDate {
                            margin-top:10px;
                        }
                        .timeBucket {
                            margin-top:20px;
                        }
                        .archived {
                            margin-top:20px;
                            margin-bottom:20px;
                        }
                `}</style>
            </div>
        )
    }
}

ProjectMetaEditor.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, { saveProject })(ProjectMetaEditor);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Form, Input, Button} from 'semantic-ui-react';
import { CirclePicker } from 'react-color';
import ZoneLabel from './ZoneLabel';
import { editTimeBucket, saveTimeBucket } from '../../actions/timeActions';

class TimeBucketEditor extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.updateKey = this.updateKey.bind(this);
        this.save = this.save.bind(this);
        this.state = {
            bucket : {
                title : "",
                
            },
            working: false,
            valid : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        this.setInternals(this.props)

    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    validate(bucket){
        let valid = true;
        if(!bucket.title) {valid = false;}
        if(!bucket.color) {valid = false;}
        return valid;
    }
    setInternals(props){
        this.setState({
            bucket : props.bucket,
            valid : this.validate(props.bucket)
        })
    }

    async save(){
        this.setState({working : true});
        try {
            await this.props.saveTimeBucket(this.state.bucket);
            this.props.editTimeBucket(null);
        }
        catch(e){
            console.error(e);
            this.setState({working : false});
        }
    }

    updateKey(key, val){
        let bucket = {...this.state.bucket};
        bucket[key] = val;
        this.setState({bucket : bucket,  valid : this.validate(bucket)});
    }


    render(){
        return (
            <div className="TimeBucketEditor">
                <Form>
                    <div className="inputs">
                        <div>
                            <ZoneLabel>Time Bucket Name</ZoneLabel>
                            <Input
                                onChange={(e,v)=>{this.updateKey('title', v.value)}}
                                fluid
                                value={this.state.bucket.title}
                            ></Input>
                        </div>
                        <div>
                            <ZoneLabel  style={{marginTop:20}}>Time Bucket Color</ZoneLabel>
                            <CirclePicker
                                onChange={(color)=>{this.updateKey('color', color.hex)}}
                                color={this.state.bucket.color}
                            ></CirclePicker>
                        </div>

                        
                    </div>
                   

                    <div className="controls">
                        <div>
                            <Button
                                basic
                                onClick={()=>{this.props.editTimeBucket(null)}}
                                color="teal"
                            >Cancel</Button>
                        </div>
                        <div>
                        <Button
                            onClick={this.save}
                            loading={this.state.working}
                            color="teal"
                            disabled={!this.state.valid}
                        >Save</Button>
                        </div>
                        
                    </div>
                    

                </Form>
                <style jsx>{`
                    
                    .TimeBucketEditor {
                        width:40vw;
                    
                    
                    }
                    .inputs {
                        padding: 10px;
                    }
                    .controls {
                        padding:10px;
                        display: flex;
                        justify-content: space-between;
                    }
                `}</style>
            </div>
        )
    }
}

TimeBucketEditor.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  editTimeBucket, saveTimeBucket})(TimeBucketEditor);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProjectMetaEditor from '../common/Project/ProjectMetaEditor';
import Loading from '../common/Loading';
import ProjectStepList from '../common/Project/ProjectStepList';
import ProjectLog from '../common/Project/ProjectLog';
import ZoneTitle from '../common/ZoneTitle';
import PlanStepEditor from '../common/PlanStepEditor';
import MilestoneList from '../common/MilestoneList';
import ZoneLabel from '../common/ZoneLabel';
import DeleteButton from '../common/DeleteButton';
import { deleteProject } from '../../actions/projectAction';
import { setSelectedPlanStepId } from '../../actions/planAction';
import TimeLogger from '../common/TimeLogger';
import { MediaQuery } from 'react-responsive';



class ProjectMobile extends React.Component {

    constructor(props){
        super(props);
        this.deleteProject = this.deleteProject.bind(this);
        this.state = {
            activeIndex : 1
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){

    }

    async deleteProject(){
        try {
            await this.props.deleteProject(this.props.project);
            this.props.history.push('/plans');
        }
        catch(e){
            console.error(e);
        }
    }


    
    handleTabChange(){
        this.setState({activeIndex:1})
    }
      
    render(){
        if(!this.props.project){
            return <Loading />
        }
        
       

        return (
            <div className="Project">
                
                <ProjectStepList project={this.props.project}></ProjectStepList>

                {/* <div className="ProjectMetaEditor-wrap">
                    <ProjectMetaEditor project={this.props.project} />
                    
                    <div className="milestones-list">
                        <ZoneLabel>Plan milestones</ZoneLabel>
                        <MilestoneList filtered={true} projectId={this.props.project.id}></MilestoneList>
                    
                    </div>

                    <div className="delete-control">
                        <DeleteButton 
                            onConfirm={this.deleteProject}
                            content="Delete Plan">
                        </DeleteButton>
                    </div>
                    
                </div> */}
                {/* <div className="ProjectStepList-wrap">
                    <ProjectStepList project={this.props.project}></ProjectStepList>
                </div>
                <div className="ProjectNotes-wrap">
                    <ProjectLog  project={this.props.project}></ProjectLog>

                    
                </div> */}

                <PlanStepEditor />

                <TimeLogger onComplete={()=>{
                    //reload the step
                    if(this.props.selectedStep){
                        this.props.setSelectedPlanStepId(this.props.selectedStep.id);
                    }
                   
                }} />

                
                <style jsx>{`
                    .Project {

                        flex:1;
                        display: flex;
                        padding:50px 10px 0px 10px;
                        flex-direction: column;
                    }    
                    .ProjectMetaEditor-wrap {
                        width:30%;
                        display: flex;
                        padding-top:20px;
                        flex-direction: column;
                        
                    }
                    .ProjectNotes-wrap  {
                        padding-top:20px;
                        width:30%;
                        display: flex;
                        background: #fff;
                        box-shadow: -2px 0px 4px rgba(0,0,0,0.2);
                        margin-right:-20px;
                        margin-left:0px;
                        
                    }
                    .ProjectStepList-wrap {
                        display: flex;
                        flex-direction: column;
                        flex:1;
                        padding-top:20px;
                        margin-right:20px;
                        
                    }
                    .milestones-list {
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        margin-right:20px;
                        padding-top:10px;
                    }
                    .delete-control {
                        padding-right:30px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

ProjectMobile.propTypes = {
    
}

const mapStateProps = (state, props) => {
    return {
        project : state.project.projectByKey[props.match.params.id],
        selectedStep : state.plan.selectedPlanStep
    };

}

export default  connect(mapStateProps, { deleteProject, setSelectedPlanStepId  })(ProjectMobile);

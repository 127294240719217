import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, fonts, effects } from '../../../assets/styles/theme';
import { setSelectedPlanStep, deleteStep } from '../../../actions/planAction';
import AllocatedTimeDisplay from '../AllocatedTimeDisplay/AllocatedTimeDisplay';
import { parseString, generateString } from '../../../libs/stringUtils';
import { saveStep } from '../../../actions/planAction';
import TimeLengthDisplay from '../../common/TimeLengthDisplay';

class PlanStepInlineEditor extends React.Component {

    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.updateAndSaveStep = this.updateAndSaveStep.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.state = {
            deleteActive : false,
            editMode : false,
            inputString : ''
        }
    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
     }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    
    setInternals(props){
       this.setState({
           inputString : generateString(props.step.action, props.step.type, props.step.timeRemaining)
       })
    }

    onKeyUp(e){
        //enter on return
        if(e.which == 13) { 
            this.updateAndSaveStep(e);
        }
    }
    onBlur(){
        this.updateAndSaveStep();
    }
    
    async updateAndSaveStep(e){
        let data = (e) ? parseString(e.target.value.trim()) : parseString(this.state.inputString);
        this.setState({
            inputString : data.original.trim()
        })
        let action = {
            type : data.type,
            action : data.title,
            timeRemaining : data.time
        }

        try {
            await this.props.saveStep({...this.props.step, ...action});
            this.setState({
                editMode : false,
                inputString : ""
            })
        }
        catch(e){
            console.error(e);
        }
    }
    onChange(e){
        let target = e.target.value;
        if(e.which == 13) { 
            target = target.trim();
        }
        this.setState({inputString : target});
    }

    render(){
        
        const renderAction = ()=>{
            if(this.state.editMode){
                return (
                    <div>
                    <textarea 
                    placeholder="Edit"
                    onKeyUp={this.onKeyUp}
                    onBlur={this.onBlur}
                    onChange={this.onChange} 
                    value={this.state.inputString} 
                    rows="1"
                    />
                    <style jsx>{`
                    textarea  {
                        display:block;
                        width:100%;
                        border:1px solid ${colors.midHi};
                        padding:10px;
                        border-radius: 3px;
                        box-shadow: inset 1px 1px 4px rgba(0,0,0,0.2);
                        outline-color: ${colors.primary};
                        
                    }    
                `}</style>
                </div>
                )
            } else {
                return (
                    <div>
                        <p className="action" onClick={()=>{
                            this.setState({editMode : true})
                        }}>
{/*                             
                            {this.props.step.action}
                        

                        <TimeLengthDisplay
                                        
                            time={this.props.step.timeRemaining} prefix=" / "
                            zeroHides={true}
                            />
                        {(this.props.step.type=='milestone')? ` / m` : null } */}
                        {generateString(this.props.step.action, this.props.step.type, this.props.step.timeRemaining)}
                        </p>
                    </div>
                )
            }
        }
        return (
            <div className="PlanStepInlineEditor">
                {renderAction()}
            </div>
        )
    }
}

PlanStepInlineEditor.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, { saveStep })(PlanStepInlineEditor);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '../../../assets/styles/theme';
import BrainDumpHeader from '../../global/BrainDump/BrainDumpHeader';
import BraindumpInputBox from '../../global/BrainDump/BraindumpInputBox';
import { DragDropContext } from 'react-beautiful-dnd';
import PlanList from '../../global/PlanList/PlanList';
import { setSelectedPlanStepId, bulkUpdateSortOrderAndProjectId } from '../../../actions/planAction';
import { reOrder } from '../../../libs/arrayUtils';
import MediaQuery from 'react-responsive';
import TimeLogger from '../../common/TimeLogger';

class BraindumpScreen extends React.Component {

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            planSteps : []
        }

    }
    componentWillMount(){ }
    
 
    componentDidMount(){
        this.setInternals(this.props);
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        let planSteps = props.planStepsByProject.brainDump || [];
        this.setState({planSteps : planSteps})
    }

    async onDragEnd(result){
        if (!result.destination) {
            return;
        }

        if(result.source.index===result.destination.index) return;

        

        try {


            let newOrder = reOrder(
                Array.from(this.state.planSteps),
                result.source.index,
                result.destination.index
            );

            let sortUpdate = newOrder.map((i, idx)=>{
                return {...i, sortKey : idx};
            })

            this.setState({
                planSteps : sortUpdate
            });

            await this.props.bulkUpdateSortOrderAndProjectId(sortUpdate, this.props.match.params.id);
            
           
        }
        catch(e){
            console.error(e);
        }
        
    
    }



    render(){

        const renderNotes = ()=>{
            if(this.state.planSteps.length) return null;
            return(
                <div>
                    <p></p>
                </div>
            )
        }
        return (
            <div className="BraindumpScreen">
                <div className={(this.state.planSteps.length)?'BraindumpScreen-inner' : 'BraindumpScreen-inner empty'}>
                    <div className="input">
                        <MediaQuery query="(min-width: 801px)">
                            <BrainDumpHeader width={200}></BrainDumpHeader>
                        </MediaQuery>
                        <MediaQuery query="(max-width: 800px)">
                            <BrainDumpHeader width={50}></BrainDumpHeader>
                        </MediaQuery>
                        
                    </div>
                    <div className={(this.state.planSteps.length)?'items' : 'items empty'}>
                        {renderNotes()}
                        
                        <BraindumpInputBox style={{marginTop:10, marginBottom:10}} large placeholder="What's on your mind?"></BraindumpInputBox>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="project brain-dump" >
                            
                            <div className="brain-dump-list">
                                <PlanList project={{ id : "brainDump"}} steps={this.state.planSteps}></PlanList>
                            </div>
                            
                        </div>
                    </DragDropContext>
                    </div>
                    
                    <TimeLogger onComplete={()=>{
                        this.props.setSelectedPlanStep(null);
                        
                    }} />
                
                
                </div>
                
                <style jsx>{`
                    
                .BraindumpScreen {
                    min-height: calc(100vh - 50px);
                    background: ${colors.primary};
                    overflow: visible;
                    padding:10px 20px;
                }
                .BraindumpScreen-inner {
                    margin:10px 0px;
                    display: flex;
                    max-width: 1000px;
                    margin:auto;
                    padding-top:0vh;
                    transition: all 0.3s;
                }
                .BraindumpScreen-inner.empty {
                    padding-top:10vh;
                }
                .items {
                    flex:1;
                    padding-top:0px;
                    transition: all 0.3s;
                }
                .items.empty {
                    padding-top:143px;
                }
                .input {
                   
                    margin-right:20px;
                }
                @media all and (max-width:800px){
                    .BraindumpScreen-inner {
                        flex-direction: column;
                    }
                    .BraindumpScreen-inner.empty {
                        padding-top:5vh;
                    }
                    .items.empty {
                        padding-top:0px;
                    }
                }
                `}</style>
                <style global jsx>{`
                    .BraindumpScreen .PlanList .plan-list {
                        min-height: 0px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

BraindumpScreen.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        planStepsByProject : state.plan.planStepsByProject
    };
}

export default  connect(mapStateProps, {  })(BraindumpScreen);

import lunr from 'lunr';

const INITIAL_STATE = {
    logs : [],
    logsById : {},
    loading : false,
    logListener : null,
    logIndex : null,
    searchString : '',
    searchResults : []
};

export default (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){
        case 'LOADING_PROJECT_LOG':
            return {...state, loading : true, logs : [], logIndex : null, searchString : '', logsById : {}, searchResults : [] };
            
        case 'UPDATE_SEARCH_STRING':
            attrs.searchString = action.payload;
            let searchString = `*${action.payload}*`;;
            attrs.searchResults = [];
            if(action.payload){
                let results = state.logIndex.search(searchString);
                attrs.searchResults = results.map((idx)=>{
                    return state.logsById[idx.ref];
                }) || [];
            }
            return {...state, ...attrs};


        case 'PROJECT_LOG_LISTENER_UPDATE':
            return {...state, logListener : action.payload};

        case 'PROJECT_LOG_ITEMS_UPDATE':
            attrs.logIndex = lunr(function () {
                this.ref('id')
                this.field('text')
                this.field('type')
                action.payload.forEach(function (doc) {
                    
                this.add(doc)
                }, this)
            })
            attrs.logsById = {};
            action.payload.map((l)=>{
                attrs.logsById[l.id] = l;
            });
            return {...state, loading : false, logs : action.payload, ...attrs};
        default:
            return state;
    }
}
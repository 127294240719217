import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/theme';

class CentralFramer extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="CentralFramer">
                {this.props.children}
                <style jsx>{`
                    .CentralFramer {
                        width: 100vw;
                        height: 100vh;
                        background:${colors.primaryLo};
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }        
                        
                `}</style>
            </div>
        )
    }
}

CentralFramer.propTypes = {
    
}

export default CentralFramer;
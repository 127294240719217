import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WeekDayTimeBlock from './WeekDayTimeBlock';

import { saveTimeBlock } from '../../actions/timeActions';
import { fonts, colors } from '../../assets/styles/theme';
import iconSrc from './../../assets/img/add-timeblock.svg';
import WeekDayTimeBlock2 from './WeekDayTimeBlock2';

class WeekDay extends React.Component {

    constructor(props){
        super(props);
        this.renderBlocks = this.renderBlocks.bind(this);
        this.onGridTouchStart = this.onGridTouchStart.bind(this);
        this.onGridTouchEnd = this.onGridTouchEnd.bind(this);
        this.onWeekLeave = this.onWeekLeave.bind(this);
        this.renderTimeBlocks = this.renderTimeBlocks.bind(this);
        this.state = {
            blocks : [],
            creating : false,
            createStart : null
           
        }
    }
    componentWillMount(){ 
        let blocks = [];
        for(let i = 0; i < 97; i++){
            let mod = i % 4;
            blocks.push({
                idx : i,
                start :  i * 900,
                end : (i * 900) + 900,
                hour : Math.floor((i * 900) / 3600),
                min : 15 * mod
            })
        }
        this.setState({ blocks : blocks })
    }
    
    componentDidMount(){

        
     }

    onGridTouchStart(block){
        console.log(block);
        // let timeBlocks = this.state.timeBlocks.slice();

        this.props.saveTimeBlock({
            start : block.start,
            end : block.start+900,
            day : this.props.day,
            timeBucketId : this.props.activeTimeBucket.id
        });
        // timeBlocks.push({
        //     start : block.start,
        //     end : block.start+900,
        //     day : this.props.day
        // })
        // this.setState({
        //     timeBlocks : timeBlocks
        // })
        // this.setState({
        //     creating : true,
        //     createStart : block
        // })

        // console.log('started on', this.props.day, timeBlocks);
    }
    onGridTouchEnd(block){

        console.log('create block', this.state.createStart, block);
        this.setState({
            creating : false,
            createStart : null
        })
    }

    onWeekLeave(){
        console.log('cancel create');
        this.setState({
            creating : false,
            createStart : null
        })
    }


    renderBlocks(){
        return this.state.blocks.map((b, idx)=>{
            
            let classes = ['WeekDay-block'];
            let mod = b.idx % 4;
            
            if(mod==0){
                classes.push('WeekDay-week-start')
            }

            if(mod==3){
                classes.push('WeekDay-week-end')
            }

            const getFill = ()=>{
                if(!this.props.activeTimeBucket){
                    return colors.mid;
                }
                return  this.props.activeTimeBucket.color || "orange";
            }

            return (
                <div 
                    key={`bgBlock${this.props.day}${idx}`}
                    
                   
                    // onTouchEnd={()=>this.onGridTouchEnd(b)} 
                    // onMouseUp={()=>this.onGridTouchEnd( b)} 
                    className={classes.join(" ")}>
                    <span className="WeekDay-Trigger" style={{backgroundColor : getFill()}} onClick={()=>this.onGridTouchStart(b)} ></span>
                    <span className="WeekDay-label">{b.hour}:{(b.min)? b.min : '00'}</span>
                </div>
            )
        })
    }


    renderTimeBlocks(){
        return this.props.timeBlocks
            .filter((b)=>{
                return b.day == this.props.day;
            })
            .map((b, idx)=>{
            return <WeekDayTimeBlock blockHeight={this.props.blockHeight} key={b.id} block={b} />
        })
    }
    render(){
        return (
            <div className="WeekDay"
                onTouchEnd={this.onWeekLeave}
                >
               
                <div className="WeekDay-wrap">
                    {this.renderBlocks()}
                    {this.renderTimeBlocks()}
                </div>
               

                <style global jsx>{`
                    .WeekDay-wrap {
                        margin-top:4px;
                        width:100%;
                        padding-left:5px;
                        position: relative;
                    }
                    .WeekDay  {
                        ${fonts.bold}
                        color : ${colors.primary};
                        display: flex;
                        flex:1;
                        position: relative;
                    }
                    .WeekDay-block {
                        border-top:1px solid ${colors.mid};
                        border-bottom:1px solid ${colors.mid};
                        height:${this.props.blockHeight || 20}px;
                        border-bottom: 0px;
                        font-size:10px;
                        position: relative;
                        margin-left:35px;
                        background: #fff;
                        max-width: 50px;
                    }

                    .WeekDay-Trigger {
                        position: absolute;
                        left :0px;
                        top:0px;
                        right:0px;
                        bottom:0px;
                        cursor : pointer;
                        opacity: 0;
                        background-color: orange;
                        background-image: url(${iconSrc});
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    .WeekDay-Trigger:hover {
                        opacity: 1;
                        
                    }
                    .WeekDay-label {
                        position: relative;
                        top:-10px;
                        left:-30px;
                        transform: translateX(-110%);
                        user-select: none;
                        color:${colors.dark};
                        ${fonts.bold}
                        text-align: left;
                    }
                   
                    .WeekDay-week-start {
                        border-top:2px solid ${colors.dark};
                    }  
                `}</style>
            </div>
        )
    }
}

WeekDay.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        timeBlocks : state.time.timeBlocks,
        activeTimeBucket : state.time.activeTimeBucket
    };
}

export default  connect(mapStateProps, { saveTimeBlock })(WeekDay);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import PlanListStep from './PlanListStep';


class BrainDumpList extends React.Component {

    constructor(props){
        super(props);
        this.renderSteps = this.renderSteps.bind(this);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }
    
    renderSteps(){
        if(!this.props.steps) return null;

        return this.props.steps.map((s, idx)=>{
            return (
                <PlanListStep project={{}} step={s} idx={idx} key={s.id} />
            )
        })
    }
    render(){
        return (
            <div className="BrainDumpList">
                <Droppable droppableId="brainDump" key="brainDump">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} >
                            {this.renderSteps()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

BrainDumpList.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(BrainDumpList);

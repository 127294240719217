import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../assets/styles/theme';

class BrainDumpPanel extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="BrainDumpPanel">
                
                {this.props.children}
                
                <style jsx>{`
                    .BrainDumpPanel {
                        position: fixed;
                        left:0px;
                        top:50px;
                        background: ${colors.primary};
                        z-index: 1;
                        padding:10px 10px 0px 10px;
                        border-radius: 0px 5px 0px 0px;
                        box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
                        bottom:0px;
                        width:330px;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;

                    }    

                    .BrainDumpPanel :global(.PlanList){
                        overflow-y: auto;
                        flex:1;
                        -webkit-overflow-scrolling: touch;
                    }

                    .BrainDumpPanel :global(.PlanList) :global(.plan-list){
                        min-height: 80%;
                        margin-bottom:20px;
                    }

                    @media all and (max-width:800px){
                        .BrainDumpPanel {
                            width:280px;
                        }
                    }
                    

                    @media all and (max-width:697px){
                        .BrainDumpPanel {
                            width:50vw;
                            position: relative;
                            margin-top:-50px;
                            left:-10px;
                        }
                    }

                     
                `}</style>
            </div>
        )
    }
}

BrainDumpPanel.propTypes = {
    
}

export default BrainDumpPanel;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import Linkify from 'react-linkify';
import { Checkbox, Icon } from 'semantic-ui-react';
import { colors } from '../../../assets/styles/theme';
import PinnedIcon from './PinnedIcon';
import { parseMinutes, getSuffix } from '../timeUtils';
import { setSelectedPlanStepId } from '../../../actions/planAction';

class ProjectLogEntry extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        
        
        const renderDate = ()=>{
            if(!this.props.log || !this.props.log.createdAt ) return null;
            let date = moment(this.props.log.createdAt.toDate()).fromNow();
            // return (date==='Invalid date') ? '' : date;
            if((date==='Invalid date')) return null;
            return (
                <span className="date">{date}
                    <style jsx>{`
                        .date {
                            color : ${colors.primary};
                            font-size:13px;
                            font-weight: 700;
                        }
                    `}</style>
                </span>
            )
        }

        switch(this.props.log.type){
            case 'time':
                return (
                    <div className="ProjectLogEntry" key={this.props.log.id}>
                        {/* <Checkbox label="pinned" checked={this.props.log.pinned} onClick={this.props.onPinClick}></Checkbox> */}
                        <div className="ProjectLogEntry-time">
                            <div>
                            {renderDate(this.props.log.createdAt)}
                            </div>
                            <div >
                                <a className="delete" onClick={this.props.onDelete}>Delete Log Entry</a>
                            </div>

                        </div>

                        <div className={(this.props.log.stepId)?'note linked' : 'note'} onClick={()=>{this.props.setSelectedPlanStepId(this.props.log.stepId)}}>
                            <Icon name="time" color="teal" />
                                {this.props.log.timeString} {getSuffix(this.props.log.timeString)} : {this.props.log.text}
                            </div>

                    
                        <style jsx>{`
                            .ProjectLogEntry {
                                
                            }
                            .ProjectLogEntry-time {
                            
                                display: flex;
                                align-items: flex-start;
                                justify-content : space-between;

                            }
                            .ProjectLogEntry:hover .delete {
                                opacity: 1;
                            }
                            .delete {
                                transition:all 0.3s;
                                opacity:  0;
                                cursor: pointer;
                                color:${colors.mid};
                                font-size: 11px;
                                font-weight: 700;
                                text-transform: uppercase;
                                margin-top:6px;
                            }
                            .delete:hover {
                                color:${colors.error};
                            }
                            .note {
                                max-width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size:14px;
                                line-height: 22px;
                                color:${colors.dark};
                                padding-bottom:10px;
                                border-bottom:1px solid ${colors.backgroundHi};
                                margin-bottom:10px;
                                white-space: pre-line;
                                overflow-wrap: break-word;
                               
                                
                            }
                            .note.linked {
                                cursor: pointer;
                            }
                            .note.linked:hover {
                               text-decoration: underline;
                            }
                        `}</style>
                    </div>
                )
            default :
                return (
                    <div className="ProjectLogEntry" key={this.props.log.id}>
                        {/* <Checkbox label="pinned" checked={this.props.log.pinned} onClick={this.props.onPinClick}></Checkbox> */}
                        <div className="ProjectLogEntry-time">
                            <div>
                            {renderDate(this.props.log.createdAt)}
                                <PinnedIcon active={this.props.log.pinned} style={{marginLeft:5}} onClick={this.props.onPinClick}></PinnedIcon>
                            </div>
                            <div >
                                <a className="delete" onClick={this.props.onDelete}>Delete Log Entry</a>
                            </div>

                        </div>

                        <Linkify properties={{target:"_blank"}}>
                            <div className="note">{this.props.log.text}</div>
                        </Linkify>


                       
                        <style jsx>{`
                            .ProjectLogEntry {
                                
                            }
                            .ProjectLogEntry-time {
                               
                                display: flex;
                                align-items: flex-start;
                                justify-content : space-between;

                            }
                            .ProjectLogEntry:hover .delete {
                                opacity: 1;
                            }
                            .delete {
                                transition:all 0.3s;
                                opacity:  0;
                                cursor: pointer;
                                color:${colors.mid};
                                font-size: 11px;
                                font-weight: 700;
                                text-transform: uppercase;
                                margin-top:6px;
                            }
                            .delete:hover {
                                color:${colors.error};
                            }
                            .note {
                                max-width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size:14px;
                                line-height: 19px;
                                color:${colors.dark};
                                padding-bottom:10px;
                                border-bottom:1px solid ${colors.backgroundHi};
                                margin-bottom:10px;
                                white-space: pre-line;
                                overflow-wrap: break-word;
                            }
                        `}</style>
                    </div>
                )
        }
       
    }
}

ProjectLogEntry.propTypes = {
    
}



const mapStateProps = (state) => {
    return {
       
    };
}

export default  connect(mapStateProps, { setSelectedPlanStepId })(ProjectLogEntry);


import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProjectMetaEditor from '../../common/Project/ProjectMetaEditor';
import Loading from '../../common/Loading';
import ProjectLog from '../../common/Project/ProjectLog';
import PlanStepEditor from '../../common/PlanStepEditor';
import MilestoneList from '../../common/MilestoneList';
import ZoneLabel from '../../common/ZoneLabel';
import DeleteButton from '../../common/DeleteButton';
import { deleteProject } from '../../../actions/projectAction';
import { setSelectedPlanStepId, bulkUpdateSortOrderAndProjectId, setSelectedPlanStep } from '../../../actions/planAction';
import TimeLogger from '../../common/TimeLogger';
import { MediaQuery } from 'react-responsive';
import { DragDropContext } from 'react-beautiful-dnd';
import PlanList from '../../global/PlanList/PlanList';
import { reOrder } from '../../../libs/arrayUtils';


class PlanScreen extends React.Component {

    constructor(props){
        super(props);
        this.deleteProject = this.deleteProject.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
        this.state = {
            planSteps : []
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
        if(this.props.match.params.actionId){
            this.props.setSelectedPlanStepId(this.props.match.params.actionId);
        }
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        let planSteps = props.planStepsByProject[props.match.params.id] || [];
        this.setState({planSteps : planSteps})
    }

  
  
    async onDragEnd(result){
        if (!result.destination) {
            return;
        }

        if(result.source.index===result.destination.index) return;

        

        try {


            let newOrder = reOrder(
                Array.from(this.state.planSteps),
                result.source.index,
                result.destination.index
            );

            let sortUpdate = newOrder.map((i, idx)=>{
                return {...i, sortKey : idx};
            })

            this.setState({
                planSteps : sortUpdate
            });

            await this.props.bulkUpdateSortOrderAndProjectId(sortUpdate, this.props.match.params.id);
            
           
        }
        catch(e){
            console.error(e);
        }
        
    
    }

    async deleteProject(){
        try {
            await this.props.deleteProject(this.props.project);
            this.props.history.push('/streams');
        }
        catch(e){
            console.error(e);
        }
    }

    renderLabel(){
        return (this.props.project.pool) ? 'Pool' : "Stream";
    }
    render(){
        if(!this.props.project){
            return <Loading />
        }
        
        return (
            <div className="PlanScreen">
                
                
               
                <div className="ProjectMetaEditor-wrap">
                    <ProjectMetaEditor project={this.props.project} />
                    
                    <div className="milestones-list">
                        <ZoneLabel>{this.renderLabel()} milestones</ZoneLabel>
                        <MilestoneList filtered={true} projectId={this.props.project.id}></MilestoneList>
                    </div>

                    <div className="delete-control">
                        <DeleteButton 
                            onConfirm={this.deleteProject}
                            content={`Delete `+this.renderLabel()}>
                        </DeleteButton>
                    </div>
                    
                </div>
                <div className="ProjectStepList-wrap">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <PlanList project={this.props.project} steps={this.state.planSteps}></PlanList>
                    </DragDropContext>
                </div>
                <div className="ProjectNotes-wrap">
                    <ProjectLog  project={this.props.project}></ProjectLog>

                    
                </div>

                <PlanStepEditor />

                <TimeLogger onComplete={()=>{
                   
                    this.props.setSelectedPlanStep(null);
                }} />

                
                <style jsx>{`
                    .PlanScreen {
                        flex:1;
                        display: flex;
                        padding:0px 20px;
                        overflow: hidden;
                        height: calc( 100vh - 50px );
                    }    
                    .PlanScreen :global(.PlanList){
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                    .ProjectMetaEditor-wrap {
                        width:30%;
                        display: flex;
                        padding-top:20px;
                        flex-direction: column;
                      
                    }
                    .ProjectNotes-wrap  {
                        padding-top:20px;
                        width:30%;
                        display: flex;
                        background: #fff;
                        box-shadow: -2px 0px 4px rgba(0,0,0,0.2);
                        margin-right:-20px;
                        margin-left:0px;
                        
                    }
                    .ProjectStepList-wrap {
                        display: flex;
                        flex:1;
                        padding-top:20px;
                        margin-right:20px;
                        width:100%;
                        overflow-y: hidden;
                    }
                    .milestones-list {
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        margin-right:20px;
                        padding-top:10px;
                    }
                    .delete-control {
                        padding-right:30px;
                    }

                    @media all and (max-width:959px){
                        .Project {
                            padding-top: 37px;
                        }

                        
                    }
                    
                `}</style>
            </div>
        )
    }
}

PlanScreen.propTypes = {
    
}

const mapStateProps = (state, props) => {
    return {
        project : state.project.projectByKey[props.match.params.id],
        selectedStep : state.plan.selectedPlanStep,
        planStepsByProject : state.plan.planStepsByProject
    };

}

export default  connect(mapStateProps, { deleteProject, setSelectedPlanStepId, bulkUpdateSortOrderAndProjectId, setSelectedPlanStep  })(PlanScreen);

import React from 'react';
import PropTypes from 'prop-types';
import { fonts } from '../../../assets/styles/theme';

class PlanListTitle extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="PlanListTitle">
                <p style={{...this.props.style}}>{this.props.children}</p>
                <style jsx>{`
                    .PlanListTitle {
                        width:100%;
                        padding:10px;
                    }    
                    .PlanListTitle p {
                        font-size:17px;
                        ${fonts.bold}
                        text-align: center;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                `}</style>
            </div>
        )
    }
}

PlanListTitle.propTypes = {
    
}

export default PlanListTitle;
const INITIAL_STATE = {
    connected : false,
    connecting : true,
    authedUser : null,
    authChecked : false,
    permissionsLoaded : false,
    permissions : {},
    menuOpen : false
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'FIREBASE_INITIALISING':
            return { ...state, connected : false, connecting : true }
        case 'FIREBASE_CONNECTED':
            return { ...state, connected : true, connecting : false }
        case 'FIREBASE_AUTHSTATE_CHANGE':
            return { ...state, authedUser : action.payload, authChecked : true  }
        
        case 'USER_PERMISSIONS_UPDATE':
            return { ...state, permissions : action.payload, permissionsLoaded : true  }
        case 'MENU_STATE_UPDATE':
                return { ...state, menuOpen : action.payload  }
        case 'AUTH_LOGGED_OUT':
            return { ...state, authUser : null }
        case 'RESET':
            return {...INITIAL_STATE, connecting : false, connected : true}
        default:
            return state;
    }
}
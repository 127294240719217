import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/theme';

class PopUp extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ 

    }

    render(){
        if(!this.props.visible) return null;

        return (
            <div className="PopUp">
                <div className="blind" onClick={this.props.onClose}></div>
                <div className="content">
                    <div onClick={this.props.onClose} className="close">
                        <svg  width="30" height="30" viewBox="0 0 30 30">
                            <path className="close-path" d="M11.48,10.28h0a1.07,1.07,0,0,0-1.8.78,1.1,1.1,0,0,0,.35.79h0L13.45,15,10,18.14h0a1.1,1.1,0,0,0-.35.79A1.07,1.07,0,0,0,10.75,20a1.12,1.12,0,0,0,.73-.28h0l4.28-3.93a1.07,1.07,0,0,0,0-1.58Zm2.63,3.93a1.07,1.07,0,0,0,0,1.58l4.29,3.93h0a1.07,1.07,0,0,0,1.79-.79,1.06,1.06,0,0,0-.35-.79h0L16.42,15l3.43-3.14h0a1.06,1.06,0,0,0,.35-.79A1.07,1.07,0,0,0,19.12,10a1,1,0,0,0-.72.29h0ZM15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm0,25.38A11.38,11.38,0,1,1,26.38,15,11.4,11.4,0,0,1,15,26.38Z"/>
                        </svg>
                    </div>
                    {this.props.children}

                </div>
                <style jsx>{`
                    @keyframes fadeIn {
                        0% { opacity:  0};
                        100% { opacity: 1 };
                    }
                    .PopUp {
                        position: fixed;
                        top:0px;
                        bottom:0px;
                        left:0px;
                        right:0px;
                        z-index: 10;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        animation-fill-mode: forwards;
                        animation: fadeIn;
                        animation-duration: 0.3s;
                       
                    }
                    .blind {
                        background: rgba(0,0,0,0.92);
                        position: fixed;
                        top:0px;
                        bottom:0px;
                        left:0px;
                        right:0px;
                        z-index: 0;
                    }
                    .content {
                        background: ${colors.white};
                        position: relative;
                        z-index: 1;
                        border-radius:5px;
                       
                    }


                    
                        .close {
                            position: absolute;
       
                            right: 20px;
                            top: 20px;
                            z-index: 1;
                        }
                            
                        .close-path {
                            fill :${colors.dark};
                        }

                    @media all and (max-width:759px){
                        .PopUp {
                            top:60px;
                        }
                    }
                    @media all and (max-width:620px){
                        .content {
                            max-width: 100vw;   
                            width: auto;
                            position: fixed;
                            top:70px;
                            bottom:10px;
                            left:10px;
                            right:10px;
                            padding:10px;
                            overflow-y: auto;
                            overflow-x: visible;
                            -webkit-overflow-scrolling: touch;
                            
                        }
                       
                    }
                `}</style>
                
            </div>
        )
    }
}

PopUp.propTypes = {
    
}

export default PopUp;
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from './PopUp';
import { Checkbox, Button, Icon, Dropdown, Form } from 'semantic-ui-react';
import { cancelTimeLog, saveTimeLog } from '../../actions/timeActions';
import ZoneLabel from './ZoneLabel';
import { colors } from '../../assets/styles/theme';

const renderLong = (hours, minutes)=>{
    if(hours === 0){
        hours = '0'
    } 
    // else if (hours < 10){
    //     hours = '0'+hours
    // }

    if(minutes === 0){
        minutes = '00'
    } else if (minutes < 10){
        minutes = '0'+minutes
    }
    return (
        <span className="TimeLengthDisplay">
            {this.props.prefix}
            {hours}.{minutes}
        </span>
    )
}

const parseMinutes = (mins)=>{
   
    let hours = Math.floor(mins/60);
    let rounding = mins%60;
    if(rounding<10){
        rounding =  '0'+rounding;
    }
    if(!hours){
        return mins;
    }
    if(!rounding){
        rounding = '00'
    }
    
    return hours+'.'+rounding;
}

const resolveToMinutes = (input)=>{
    let string = input+"";
    let time = 0;
    let splitChar = (string.indexOf('.')>-1) ? '.' : ':';
    let timeSplit = string.split(splitChar);
    if(timeSplit.length==1){
    if(timeSplit[0]){
        time = parseInt(timeSplit[0]);
    }             
    } else if (timeSplit.length==2) {
        let mins = 0;
        if(timeSplit[0]){
            mins+= (parseInt(timeSplit[0])* 60);
        }
        if(timeSplit[1]){
            if(timeSplit[1].length==1){
                timeSplit[1]+="0";
            }
            mins+= (parseInt(timeSplit[1]));
        }
        time = mins;
    }
    return time;
}




class TimeLogger extends React.Component {

    constructor(props){
        super(props);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleRemainTypeChange = this.handleRemainTypeChange.bind(this);
        this.handleRemainSliderChange = this.handleRemainSliderChange.bind(this);
        this.parseInputString = this.parseInputString.bind(this);
        this.parseInputIndex = this.parseInputIndex.bind(this);
        this.parseInputMinutes = this.parseInputMinutes.bind(this);
        this.saveLog = this.saveLog.bind(this);
        this.state = {
            text : '',
            spent : "0",
            spentIdx : 0,
            remain : "0",
            remainIdx : 0,
            wholeTime : 300,
            remainTouched : false,
            working:false,
            markComplete : false,
            projects : [],
            projectId : null,
            selectProject : false
        }
        this.values = [0,1,2,3,4,5,10,15,20,30,40,45,60,90,120,150,180,210,240,300,360,420,480,540,600,720]

    }

    parseInputMinutes(minutes){
        let idx = 0;
        for(let i=0; i<this.values.length; i++){
            if(this.values[i] < minutes){
                idx = i;
            } else {
                break;
            }
        }
        let vals = this.parseInputIndex(idx);
        return {
            minutes : minutes,
            valueIdx : idx,
            string : vals.string
        }
    }
    parseInputString(timeString){
        let minutes = resolveToMinutes(timeString);
        let idx = 0;
        for(let i=0; i<this.values.length; i++){
            if(this.values[i] < minutes){
                idx = i;
            } else {
                break;
            }
        }
        return {
            minutes : minutes,
            valueIdx : idx,
            string : timeString
        }
    }
    parseInputIndex(idx){
        
        let rawMinutes = this.values[idx];
        let hours = Math.floor(rawMinutes/60);
        let minutes = rawMinutes % 60;
        let timeString = (hours)? hours+'.'  : '';

        if(hours){
            if(minutes === 0){
                timeString += '00'
            } else if (minutes < 10){
                timeString += '0'+minutes
            } else {
                timeString += minutes
            }
        } else {
            timeString += minutes
        }
        return {
            minutes : rawMinutes,
            valueIdx : idx,
            string : timeString
        }

    }


    handleRemainTypeChange(e){
       
        let vals = this.parseInputString(e.target.value)
        
        let remain = parseMinutes(vals.minutes);
        this.setState({
            remainIdx : vals.valueIdx,
            remain :  remain,
            remainTouched : true,
            markComplete : (remain==0)
        })

    }

    handleTypeChange(e){
        
        let vals = this.parseInputString(e.target.value)
        
        let update = {
            spentIdx : vals.valueIdx,
            spent : vals.string
        }

        if(this.state.wholeTime && !this.state.remainTouched){
           let minutes =  this.state.wholeTime - vals.minutes;
           if(minutes > 0){
                let remainVals = this.parseInputMinutes(minutes);
                update.remainIdx = remainVals.valueIdx;
                update.remain = parseMinutes(remainVals.minutes);
                update.markComplete = false;
           } else {
                update.remainIdx = 0;
                update.remain = '0';
                update.markComplete = true;
           }
        }
        
        this.setState(update)
    }

    handleSliderChange(e){
        let spentIdx = e.target.value;
        let update = {};

        let spentVals = this.parseInputIndex(spentIdx);
        update.spentIdx = spentVals.valueIdx;
        update.spent = spentVals.string;

        if(this.state.wholeTime && !this.state.remainTouched){
            let minutes =  this.state.wholeTime - spentVals.minutes;
            if(minutes > 0){
                 let remainVals = this.parseInputMinutes(minutes);
                 update.remainIdx = remainVals.valueIdx;
                 update.remain = parseMinutes(minutes);
                 update.markComplete = false;
            }else {
                update.remainIdx = 0;
                update.remain = '0';
                update.markComplete = true;
           }
         }
    
        this.setState(update)


    }

    handleRemainSliderChange(e){
        let vals = this.parseInputIndex(e.target.value)

        this.setState({
            remainIdx : vals.valueIdx,
            remain : vals.string,
            remainTouched : true,
            markComplete : (vals.minutes==0)
        })


    }
    
    componentWillMount(props){

       
     }
     componentWillReceiveProps(props){
        
        if(props && props.projects){
            let projects = [
                { 
                    value : null,
                    text : "None"
                }
            ]
            props.projects.map((project)=>{
                projects.push({
                    value : project.id,
                    text : project.title
                });
            })

            this.setState({
                projects : projects
            })
        }
        if(props && props.newLog){
            
            let event = this.parseInputMinutes(props.newLog.time);
            let remain = this.parseInputMinutes(props.newLog.timeRemaining);     
                   
            this.setState({
                spentIdx : event.valueIdx,
                spent : parseMinutes(event.minutes),
                remainIdx : remain.valueIdx,
                remain : parseMinutes(remain.minutes),
                wholeTime : props.newLog.time+props.newLog.timeRemaining,
                remainTouched : false,
                text : props.newLog.text,
                markComplete : (props.newLog.timeRemaining==0),
                projectId : props.newLog.projectId,
                selectProject : (!props.newLog.projectId)
            }, ()=>{
                this.timeInput.focus();
            })
            
        }
    }
    
    componentDidMount(){
        
    }

    async saveLog(){
        try {
            this.setState({working : true});

            let log = {
                projectId : this.state.projectId,
                stepId : this.props.newLog.stepId || null,
                text : this.state.text,
                time : resolveToMinutes(this.state.spent),
                timeString : this.state.spent
            }
            let eventId = this.props.newLog.eventId || null

            await this.props.saveTimeLog(log, resolveToMinutes(this.state.remain), this.state.markComplete, eventId);

            await this.props.cancelTimeLog();
            
            //reset the local state
            this.setState({
                text : '',
                spent : "0",
                spentIdx : 0,
                remain : "0",
                remainIdx : 0,
                wholeTime : 300,
                remainTouched : false,
                working:false,
                markComplete : false
            });
            
            if(this.props.onComplete){
                this.props.onComplete();
            }

        }
        catch(e){
            console.error(e);
            this.setState({working : false});
        }
    }
   
    

    render(){

        const getSuffix = (input)=>{
            if(!input || !input.indexOf) return '';
            return (input.indexOf('.')==-1) ? ' mins' : ' hrs'
        }
        const renderCompleteCheckbox = ()=>{
            if((true || !this.state.remain || this.state.remain==='0') && this.props.newLog && this.props.newLog.stepId){
                return (
                    <ZoneLabel style={{marginBottom:20, marginTop:20}}>
                        <Checkbox  label="Step finished" checked={this.state.markComplete} onClick={()=>{ this.setState({ markComplete : !this.state.markComplete }) }} />   
                    </ZoneLabel>
                )

            }
            return ''
        }

        const renderRemaining = ()=>{
            if(this.props.newLog && this.props.newLog.stepId){
                return (
                    <div>
                            <div className="input">
                                <ZoneLabel>Further time needed to complete step</ZoneLabel>
                                <input className="number" type="text" onChange={this.handleRemainTypeChange} value={this.state.remain} />
                                <div className="suffix">{getSuffix(this.state.remain)}</div>
                            </div>
                            <div>
                                <input className="slider" onChange={this.handleRemainSliderChange} type="range" id="cowbell" name="cowbell" min="0" max={this.values.length-1} value={this.state.remainIdx} step="1" />
                            </div>
                            <style jsx>{`
                    .TimeLogger {
                        padding:30px 20px 20px 20px;
                    }
                    .slider{
                        display: block;
                        width:250px;
                        margin-top:5px;
                        margin-bottom:10px;
                    }
                    .input {
                        position: relative;
                    }
                    .suffix {
                        position: absolute;
                        bottom:5px;
                        right:5px;
                        color:${colors.mid};
                    }
                    .number {
                        display: block;
                        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
                        margin: 0;
                        outline: 0;
                        width:250px;
                        -webkit-appearance: none;
                        tap-highlight-color: rgba(255,255,255,0);
                        line-height: 1.21428571em;
                        padding: .67857143em 1em;
                        font-size: 1em;
                        background: #fff;
                        border: 1px solid #22242626;
                        color: #000000de;
                        border-radius: .28571429rem;
                        box-shadow: 0 0 0 0 transparent inset;
                        -webkit-transition: color .1s ease,border-color .1s ease;
                        transition: color .1s ease,border-color .1s ease;
                    }
                
                `}</style>
                    </div>
                )
            }
            return ''
        }

        const renderProjectSelect = ()=>{
            if(this.state.selectProject){
                return (
                    
                    <div style={{marginBottom:10}}>
                        <div className="input">
                            <ZoneLabel>Stream</ZoneLabel>
                            <Dropdown 
                                fluid
                                selection
                                placeholder="Select a stream"
                                options={this.state.projects}
                                onChange={(event, data)=>{
                                    this.setState({projectId : data.value})
                                }}        
                                ></Dropdown>
                        </div>
                      
                    </div>
                )
            }   
            return null;
        }
        return (

            <Popup
                visible={(this.props.newLog)}
                onClose={()=>{this.props.cancelTimeLog()}}
            >
                <div className="TimeLogger">
                    {renderProjectSelect()}
                    <div>
                        <div className="input">
                            <ZoneLabel>Step taken</ZoneLabel>
                            <input className="number" type="text" onChange={(e)=>{this.setState({text : e.target.value})}} value={this.state.text} /> 
                        </div>
                      
                    </div>
                    
                    <div>
                        <div className="input">
                            <ZoneLabel style={{marginTop:10}}>Time spent on step</ZoneLabel>
                            <input ref={(node)=>this.timeInput=node}   className="number" type="text" onChange={this.handleTypeChange} value={this.state.spent} /> 
                            <div className="suffix">{getSuffix(this.state.spent)}</div>
                        </div>
                        <div>
                            <input className="slider" onChange={this.handleSliderChange} type="range" min="0" max={this.values.length-1} value={this.state.spentIdx} step="1" />
                        </div>
                    </div>
                    
                    {renderRemaining()}
                    <div>
                        {renderCompleteCheckbox()}
                    </div>
                    <div>
                        <Button 
                            disabled={(!this.state.text)}
                            color="blue" 
                            loading={this.state.working} 
                            onClick={this.saveLog}
                            fluid
                            >
                                <Icon name="check circle"></Icon>Done</Button>
                    </div>
                
                </div>
                <style jsx>{`
                    .TimeLogger {
                        padding:30px 20px 20px 20px;
                    }
                    .slider{
                        display: block;
                        width:250px;
                        margin-top:5px;
                        margin-bottom:10px;
                    }
                    .input {
                        position: relative;
                    }
                    .suffix {
                        position: absolute;
                        bottom:5px;
                        right:5px;
                        color:${colors.mid};
                    }
                    .number {
                        display: block;
                        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
                        margin: 0;
                        outline: 0;
                        width:250px;
                        -webkit-appearance: none;
                        tap-highlight-color: rgba(255,255,255,0);
                        line-height: 1.21428571em;
                        padding: .67857143em 1em;
                        font-size: 1em;
                        background: #fff;
                        border: 1px solid #22242626;
                        color: #000000de;
                        border-radius: .28571429rem;
                        box-shadow: 0 0 0 0 transparent inset;
                        -webkit-transition: color .1s ease,border-color .1s ease;
                        transition: color .1s ease,border-color .1s ease;
                    }
                
                `}</style>
            </Popup>
            
        )
    }
}

TimeLogger.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        newLog : state.time.newLog,
        projects : state.project.projects
    };
}

export default  connect(mapStateProps, { cancelTimeLog, saveTimeLog })(TimeLogger);

import firebase from 'firebase';
import { logEvent } from '../analytics';


export const loginWithEmail = (email)=> {

    let url = `${window.location.protocol}//${window.location.host}/`

    // alert(`${url} ${email}`);
    

    return (dispatch)=> {
        return new Promise((resolve, reject)=>{
            //  let url = `${window.location.protocol}://${window.location.host}/`

        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: url,
            // This must be true.
            handleCodeInApp: true,
            iOS: {
              bundleId: 'com.example.ios'
            },
            android: {
              packageName: 'com.example.android',
              installApp: true,
              minimumVersion: '12'
            }
          };

          firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
            .then(function() {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);

                //push email to firestore
                firebase.firestore().collection('signups').doc(email).set({
                    email : email,
                    createdAt : firebase.firestore.FieldValue.serverTimestamp(),
                }, { merge : true })
                
                resolve(true);
            })
            .catch(function(error) {
                console.error(error);
                reject(error);
                // Some error occurred, you can inspect the code: error.code
            });
        })
        

      
    }
}


export const logoutUser = ()=>{
    return (dispatch)=>{
        dispatch({
            type: 'AUTH_WORKING'
        });

        firebase.auth().signOut()
            .then(
                ()=>{
                    logEvent('userLoggedOut')
                    console.log('sign user out');
                    dispatch({
                        type: 'USER_LOGGED_OUT'
                    });
                },
                (err)=>{
                    console.log('sign out error', err);
                }
            )
    }
}

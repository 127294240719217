import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sequenceEvents } from '../../actions/eventAction';
import { Button, Icon } from 'semantic-ui-react';

class SequenceTrigger extends React.Component {

    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    handleClick(){
        if(!this.props.sequencing){
            this.props.sequenceEvents();
            
        }
    }
    render(){

        
        return (
            <div className="SequenceTrigger" style={{padding : (this.props.mobile)?0: 20}}>
                <Button
                    size={(this.props.mobile)? 'tiny' : 'medium'}
                    color="teal"
                    icon="calendar alternate"
                    content={(this.props.mobile)? 'Sequence Plans' : 'Sequence Plans'}
                    fluid={true}
                    onClick={this.handleClick}
                    loading={this.props.sequencing}
                >
                   
                </Button>
               
                <style jsx>{`
                    .SequenceTrigger {
                       
                    }    
                    
                `}</style>
            </div>
        )
    }
}

SequenceTrigger.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        sequencing : state.event.sequencing,
       
    };
}

export default  connect(mapStateProps, { sequenceEvents })(SequenceTrigger);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colors, fonts, effects } from '../../../assets/styles/theme';
import { setSelectedPlanStep } from '../../../actions/planAction';
import moment from 'moment';


const Brightness = (color)=>{
    if(color.length==7){color=color.substring(1);}
    var R =	parseInt(color.substring(0,2),16);
    var G =	parseInt(color.substring(2,4),16);
    var B =	parseInt(color.substring(4,6),16);
    return Math.sqrt(R * R * .241 + G * G * .691 + B * B * .068);
}

class PlanListMilestone extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        let step = this.props.step;
        let {color} = this.props.project;
        if(!color){
            color = colors.primary;
        }

        const getExpectedCompletion = ()=>{
            if(this.props.exp[step.id]){
                let prefix = (step.dueOn) ? ' | ' : '';

                return prefix+'Exp: '+moment.unix(this.props.exp[step.id]).format('D MMM YYYY')
            }

            return 'Unscheduled'
        }

        const getDueDate = ()=>{
            if(step.dueOn){
                return 'Due: '+moment(step.dueOn.toDate()).format('D MMM YYYY')
            }

            return ''
        }

        const getStatusMarker = ()=>{
            
            if(step.dueOn && this.props.exp[step.id]){

                let dueDay = moment(step.dueOn.toDate()).startOf('day');
                let expectedDay = moment.unix(this.props.exp[step.id]).startOf('day');
                let fill = (expectedDay.isSameOrBefore(dueDay)) ? colors.green : colors.error;
                return (
                    <svg style={{position : 'relative', top : 2, marginRight:1}} width="12" height="12" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="8" fill={fill} strokeWidth={2} stroke="#fff"></circle>
                    </svg>
                )
            }
            return '';
        }


        return (
            <div className="PlanListMilestone">
                <Draggable key={step.id} draggableId={step.id} index={this.props.idx} >
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="step-wrap"
                                >
                                    <span className="handle" {...provided.dragHandleProps} style={{backgroundColor : color || colors.primary }}>
                                        <svg  width="10" height="32" viewBox="0 0 10 32">
                                            <g opacity="0.48">
                                                <path d="M9.45,4l-4-4a.66.66,0,0,0-.94,0h0l-4,4A.66.66,0,1,0,1.49,5h0L4.32,2.13V13.79a.68.68,0,0,0,.68.67.67.67,0,0,0,.67-.67V2.13L8.5,5a.68.68,0,0,0,.95,0,.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                                <path d="M9.45,27a.66.66,0,0,0-.94,0h0L5.67,29.87V18.21a.67.67,0,0,0-1.34,0h0V29.87L1.49,27A.66.66,0,1,0,.55,28l4,4a.66.66,0,0,0,.94,0h0l4-4a.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                            </g>
                                        </svg>
                                    </span>

                                    <div className="main-area" style={{backgroundColor : color || colors.primary }}  onClick={()=>{this.props.setSelectedPlanStep(step)}}>
                                        <div className="main-area-top">
                                            <p className="action"
                                                style={{
                                                    color : (Brightness(color)<200) ? "#fff" : "#000"
                                                }}
                                            >{step.action}</p>
                                        </div>
                                        <div className="main-area-controls">
                                            <div className="step-time-meta"
                                                style={{
                                                    color : (Brightness(color)<200) ? "#fff" : "#000"
                                                }}
                                            >
                                                {getStatusMarker()} {getDueDate()} {getExpectedCompletion()}
                                            </div>
                                            
                                        </div>
                                    </div>
                              
                                <style jsx>{`
                                .step-wrap {
                                        min-height: 70px;
                                        background: ${colors.white};
                                        margin-bottom:10px;
                                        box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
                                        display: flex;
                                        justify-content: stretch;
                                        position: relative;
                                        border-radius: 2px;
                                        overflow: hidden;
                                    }
                                    .handle {
                                        width:20px;
                                        min-height: 75px;
                                        background: ${colors.primary};
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                    }

                                    @media (pointer: coarse) {
                                        .handle {
                                            width:40px;
                                        }
                                    }
                                    .main-area {
                                        flex:1;
                                        display: flex;
                                        flex-direction: column;
                                        padding:5px 10px;
                                    }
                                    .main-area-top {
                                        flex:1;
                                    }
                                    .action {
                                        font-weight: 400;
                                        font-size:15px;
                                        line-height: 1.2em;
                                        color:${colors.dark};
                                    }
                                    .main-area-controls {
                                        font-size:14px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-end;

                                    }
                                    .step-time-meta {
                                        font-size:11px;
                                        color:${colors.mid};
                                    }
                                    
                                    .stepMark {
                                        width:10px; 
                                        height:10px;
                                        border-radius: 5px;
                                        position: absolute;
                                        display: block;
                                        background: #000;
                                        left:calc(50% + 2.5px);
                                        bottom:-4px;
                                        background: ${colors.primary};
                                    }
                                    @media (pointer: coarse) {
                                        .stepMark {
                                            left:calc(50% - 2.5px);
                                        }
                                    }
                                    `}</style>
                                
                                </div>

                            )}
         
                    </Draggable>
                
            </div>
        )
    }
}

PlanListMilestone.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        user : state.app.authedUser,
        exp : state.event.expectedComplete
    };
}

export default  connect(mapStateProps, { setSelectedPlanStep })(PlanListMilestone);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { colors, effects, fonts } from '../../assets/styles/theme';
import {withRouter} from 'react-router-dom';
import  FlipMove from 'react-flip-move';

class MilestoneList extends React.Component {

    constructor(props){
        super(props);
        this.handleProps = this.handleProps.bind(this);
        this.renderMilestones = this.renderMilestones.bind(this);
        this.renderGroups = this.renderGroups.bind(this);
        this.state = {
            milestones : [],
            groups : []
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.handleProps(this.props);
    }
    componentWillReceiveProps(props){
        this.handleProps(props);
    }

    
    handleProps(props){
        let milestones = 
        props.events.filter((m)=>{
            return m.type==='milestone'
        });
        if(this.props.filtered){
            milestones = milestones.filter((m)=>{
                return m.projectId===this.props.projectId;
            })   
        }

        let grouped = _.groupBy(milestones, 'projectId');
        let groups = [];

        for (const property in grouped) {
            groups.push({
                projectId : property,
                milestones : grouped[property]
            })
    
        }
        // console.log('groups', groups);
        this.setState({
            milestones : milestones,
            groups : groups
        })
    }

    renderMilestones(milestones){
        
        const Brightness = (color)=>{
            if(color.length==7){color=color.substring(1);}
            var R =	parseInt(color.substring(0,2),16);
            var G =	parseInt(color.substring(2,4),16);
            var B =	parseInt(color.substring(4,6),16);
            return Math.sqrt(R * R * .241 + G * G * .691 + B * B * .068);
        }

        const getExpectedCompletion = (step)=>{
            let milestone = this.props.milestones[step.actionId];

            if(this.props.exp[step.actionId]){
                let prefix = (milestone.dueOn) ? ' | ' : '';

                return prefix+'Exp: '+moment.unix(this.props.exp[step.actionId]).format('D MMM YYYY')
            }

            return 'Unscheduled'
        }

        const getDueDate = (step)=>{
            if(this.props.milestones[step.actionId]){
                if(this.props.milestones[step.actionId].dueOn){
                    return 'Due: '+moment(this.props.milestones[step.actionId].dueOn.toDate()).format('D MMM YYYY')+' | '
                } else {
                    return ''
                }
            }
            

            return ''
        }

        const getStatusMarker = (step)=>{
            
            let milestone = this.props.milestones[step.actionId];
            
            if(milestone && milestone.dueOn && this.props.exp[step.actionId]){

                let dueDay = moment(milestone.dueOn.toDate()).startOf('day');
                let expectedDay = moment(step.start).startOf('day');
                let fill = (expectedDay.isSameOrBefore(dueDay)) ? colors.green : colors.error;
                return (
                    <svg style={{position : 'relative', top : 0, marginRight:1}} width="25" height="25" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="8" fill={fill} strokeWidth={2} stroke="#fff"></circle>
                    </svg>
                )
            }
            return '';
        }

        const getProjectColor = (step)=>{
            let project = this.props.projects[step.projectId];
            if(project && project.color){
                return project.color;
            }
            return colors.mid;
        }


        const getTextColor = (step)=>{
            return (Brightness(getProjectColor(step))>200) ? "#000" : "#fff"
        }

        return milestones.map((m, idx)=>{
            return (
                <div className="milestone" 
                    key={m.actionId}
                    style={{
                        backgroundColor : getProjectColor(m)
                    }}
                    onClick={()=>{this.props.history.push(`/streams/${m.projectId}`)}}
                >
                    <div className="content">
                        <p className="summary"
                            style={{
                                color: getTextColor(m)
                            }}
                        >{m.summary}</p>

                        <p className="dateInfo"
                             style={{
                                color: getTextColor(m)
                            }}
                        >
                            {getDueDate(m)}
                            Exp: {moment(m.start).format('D MMM YYYY')}
                        </p>
                    </div>
                    

                    {getStatusMarker(m)}
                    
                    <style jsx>{`
                        .milestone { 
                            padding:5px 10px;
                            margin-bottom:10px;
                            border-radius: 3px;
                            ${effects.shadow1}
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            ${fonts.regular}
                        }
                        .summary {
                            font-size:16px;
                            margin:0px;
                        }
                        .dateInfo {
                            font-size:11px;
                            margin:0px;
                        }
                        .content {
                            flex:1;
                        }
                    
                    `}</style>
                 </div>
            )
        })
    }

    renderGroups(){
        const getProjectTitle = (id)=>{
            if(this.props.filtered)return '';
            if(this.props.projects[id]){
                return this.props.projects[id].title
            }
            return '';
        }
        return this.state.groups.map((group)=>{
            return (
                <div key={group.projectId}>
                    <div className="project-name"
                         
                    >{ getProjectTitle(group.projectId)}</div>
                    <div className="-project-milestones">
                        <FlipMove
                             enterAnimation="accordionVertical"
                             leaveAnimation="accordionVertical"
                             staggerDelayBy={50}
                        >
                            {this.renderMilestones(group.milestones)}
                        </FlipMove>
                       
                    </div>
                    <style jsx>{`
                        .project-name  {
                            font-size:16px;
                            margin-top:20px;
                            margin-bottom:5px;
                            color:${colors.mid};
                            cursor: pointer;
                        }  
                        
                    `}</style>
                </div>
            )
        })
    }
    render(){
        return (
            <div className="MilestoneList">
            <FlipMove
                 enterAnimation="accordionVertical"
                 leaveAnimation="accordionVertical"
                 staggerDelayBy={50}
            >
                {this.renderGroups()}
            </FlipMove>
                
                <style jsx>{`
                    
                    .MilestoneList {
                        padding-right: 20px;
                    }    
                `}</style>
            </div>
        )
    }
}

MilestoneList.propTypes = {
    projectId : PropTypes.string
}

const mapStateProps = (state) => {
    return {
        events : state.event.events,
        projects : state.project.projectByKey,
        milestones : state.event.milestonesByKey,
        exp : state.event.expectedComplete
    };
}

export default  withRouter(connect(mapStateProps, {  })(MilestoneList));

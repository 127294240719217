const INITIAL_STATE = {
    defaultTimeBlocks : {},
    loading : true,
    defaultTimeBlockListener : null,
    timeBuckets : [],
    timeBucketListener : null,
    timeBucketsLoading : true,
    activeTimeBucket : null,
    selectedTimeBucket : null,
    timeBucketsByKey : {},
    timeBlocks : [],
    timeBlockListener : null,
    editingTimeBucket  : null,
    calendarProfiles : [],
    calenderProfileListener : null,
    linkedCalendars : [],
    linkedCalendarListener : null,
    newLog : null,
    helmFeedCalendarId : null
};


export default (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){
        case 'USER_DEFAULT_DAYS_UPDATE':
            return {...state, 
                defaultTimeBlocks : action.payload, 
                loading : false
            };
        case 'USER_DEFAULT_DAYS_LISTENER_UPDATE':
            return {...state, 
                defaultTimeBlockListener : action.payload, 
                loading : false
            };
        
        case 'USER_TIME_BUCKETS_UPDATE':
            attrs.timeBuckets = action.payload;
            attrs.timeBucketsLoading = false;
            attrs.timeBucketsByKey = {};
            attrs.timeBuckets.map((b, idx)=>{
                attrs.timeBucketsByKey[b.id] = b;
                if(!state.activeTimeBucket){
                    if(b.default){
                        attrs.activeTimeBucket = b;
                    }
                }
            })
            
            return {...state, ...attrs};

        case 'USER_TIME_BUCKETS_LISTENER_UPDATE':
            return {...state, 
                timeBucketListener : action.payload, 
                timeBucketsLoading : false
            };
        case 'SET_ACTIVE_TIME_BUCKET':
            return {...state, activeTimeBucket : action.payload }

        case 'USER_TIME_BLOCKS_UPDATE':
            return {...state,
                timeBlocks : action.payload
            }
        case 'USER_TIME_BLOCKS_LISTENER_UPDATE':
            return {...state,
                timeBlockListener : action.payload
            }

        case 'EDIT_TIME_BUCKET':
            return {
                ...state,
                editingTimeBucket : action.payload
            }

            

        case 'DEFAULT_WEEK_CLEAR':
            return {
                ...state, 
                defaultTimeBlocks : {},
                defaultTimeBlockListener : null,
                timeBuckets : [],
                timeBucketListener : null
            }
        
        case 'USER_CALENDAR_PROFILES_UPDATE':
            return {...state, calendarProfiles : action.payload }
        case 'USER_LINKED_CALENDARS_UPDATE':
            return {...state, linkedCalendars : action.payload }
        case 'USER_CALENDAR_LISTENER_UPDATE':
            return { ...state, calenderProfileListener : action.payload.calenderProfileListener, linkedCalendarListener : action.payload.linkedCalendarListener }
        
        case 'NEW_TIME_LOG_UPDATE':
            return {...state, newLog : action.payload}
        
        case 'USER_CHANGE_CALENDAR_FEED_ID':
            return {...state, helmFeedCalendarId : action.payload}
            
        default:
            return state;
    }
}
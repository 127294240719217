import React from 'react';
import PropTypes from 'prop-types';
import { effects, colors, fonts } from '../../assets/styles/theme';

const quotes = [
    {
        q : "Sometimes the most productive thing you can do is relax.",
        a : "Mark Black"
    },
    {
        q : "Every now and then go away, have a little relaxation, for when you come back to your work your judgment will be surer. Go some distance away because then the work appears smaller and more of it can be taken in at a glance and a lack of harmony and proportion is more readily seen. ",
        a : "Leonardo da Vinci"
    },
    {
        q : "The mind should be allowed some relaxation, that it may return to its work all the better for the rest.     ",
        a : "Seneca"
    },
    {
        q : "Your mind will answer most questions if you learn to relax and wait for the answer.",
        a : "William S. Burroughs"
    },
    {
        q : "No matter how much pressure you feel at work, if you could find ways to relax for at least five minutes every hour, you'd be more productive. ",
        a : "Dr. Joyce Brothers"
    },
    {
        q : "Tension is who you think you should be. Relaxation is who you are.",
        a : "Chinese Proverb"
    },
    {
        q : "Take rest; a field that has rested gives a bountiful crop.",
        a : "Ovid"
    },
    {
        q : "He enjoys true leisure who has time to improve his soul's estate.",
        a : "Henry David Thoreau"
    },
    {
        q : "Take the next 24 hours and just relax with the knowing that everything is okay. Let go of the stress and worry for one day and see what happens.",
        a : "Mike Basevic"
    },
    {
        q : "The breaks you take from work pay you back manifold when you return because you come back with a fresher mind and newer thinking. Some of your best ideas come when you're on vacation.",
        a : "Gautam Singhania"
    }
]

class Relax extends React.Component {   

    constructor(props){
        super(props);
        this.state = {
            q : "",
            a : ""
        }
    
    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        let q = quotes[Math.floor(Math.random()*quotes.length)];
        this.setState({...q})   ;
    }

    render(){
        const renderQuote = ()=>{
            return (
                <div className="quote">
                    <p className="q">{this.state.q}</p>
                    <p className="quotee">{this.state.a}</p>
                    <style jsx>{`
                        .quote {
                            margin-bottom:20px;
                            ${effects.shadow1}
                            background: ${colors.white};
                            padding:20px;
                            max-width:800px;
                        }
                        .q {
                            font-size:24px;
                            ${fonts.light}
                            color: ${colors.mid};
                            margin-bottom:10px;
                        }
                        .quotee {
                            color: ${colors.midLo};
                            ${fonts.bold}
                            font-size:13px;
                        }
                        
                        `}</style>
                </div>
            )
        }
        return (
            <div className="Relax">
                <p>There nothing on the list for today.</p>
                {renderQuote()}
            </div>
        )
    }
}

Relax.propTypes = {
    
}

export default Relax;
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { effects, colors, fonts } from '../../assets/styles/theme';
import moment from 'moment';

import { setSelectedPlanStepId } from '../../actions/planAction';
import { createTimeNewLog } from '../../actions/timeActions';
import { Button } from 'semantic-ui-react';
import { toggleEventDone } from '../../actions/eventAction';


class FocusEvent extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        let {summary, start, end, type, actionId, projectId, stepRemainderMins, id } = this.props.event;
        const projectColor = ()=>{
            if(this.props.projects && this.props.projects[this.props.event.projectId]){
                return this.props.projects[this.props.event.projectId].color;
            }
            return colors.mid;
        }

        const getTitle = ()=>{
            if(this.props.projects && this.props.projects[this.props.event.projectId]){
                return this.props.projects[this.props.event.projectId].title;
            }
            return "";
        }
        
        if(this.props.event.done){
            return  (
                <div className="FocusEvent"
                onClick={()=>{
                    if(type=='action'){
                        this.props.setSelectedPlanStepId(actionId);
                    }
                }}
            >
                
                <div className="TopSection">
                    <div className="title">
                        <span className="projectTitle">{getTitle()}</span> : <span className="EventName">{summary}</span>
                    </div>
                    <div>
                    <Button
                        onClick={(e)=>{
                            e.stopPropagation();
                            console.log(this.props.event);
                            this.props.toggleEventDone(this.props.event);
                        }}
                        content="Mark undone"
                        icon="cancel"
                        size="tiny"
                    />
                    </div>

                </div>

               
               
                <style jsx>{`
                    .FocusEvent {
                        margin-bottom:20px;
                        ${effects.shadow1}
                        background: ${colors.white};
                        padding:10px 20px;
                        border-left:20px solid ${colors.mid};
                        opacity: 0.5;
                    }   
                    .title {
                        flex:1;
                        padding-right:10px;
                    }
                    .projectTitle {
                        ${fonts.bold}
                        font-size:13px;
                        color: ${colors.mid};
                        margin-bottom:5px;
                    }
                    .EventName {
                        font-size:13px;
                        ${fonts.light}
                        color:${colors.mid};
                    }
                   
                    .TopSection {
                        display: flex;
                        align-items: center;
                    }
                    
                `}</style>
            </div>
            )
        }

        return (
            <div className="FocusEvent"
                onClick={()=>{
                    if(type=='action'){
                        this.props.setSelectedPlanStepId(actionId);
                    }
                }}
            >
                
                <div className="TopSection">
                    <div className="projectTitle">{getTitle()}</div>
                    <div className="EventName">{summary}</div>

                </div>

                <div className="BottomSection">
                    <div className="EventTime">
                    Time today: {moment(start).format('h:mm a')} - {moment(end).format('h:mm a')}
                    </div>
                   
                    <Button
                        content="Log time"
                        icon="time"
                        size="tiny"
                        color="orange"
                        onClick={(e)=>{
                            e.stopPropagation();
                            let time = moment.duration(moment(end).diff(moment(start))).asMinutes();
                            this.props.createTimeNewLog(actionId, projectId, summary, time, stepRemainderMins, id )
                        }}
                    ></Button>
                    <Button
                        onClick={(e)=>{
                            e.stopPropagation();
                            console.log(this.props.event);
                            this.props.toggleEventDone(this.props.event);
                        }}
                        content="Mark done for today"
                        icon="check"
                        size="tiny"
                        color="green"
                    />
                    
                </div>

               
                <style jsx>{`
                    .FocusEvent {
                        margin-bottom:20px;
                        ${effects.shadow1}
                        background: ${colors.white};
                        padding:20px;
                        border-left:20px solid ${projectColor()};
                    }   
                    .projectTitle {
                        ${fonts.bold}
                        font-size:13px;
                        color: ${projectColor()};
                        margin-bottom:5px;
                    }
                    .EventName {
                        font-size:20px;
                        ${fonts.light}
                        color:${colors.dark};
                    }
                    .EventTime {
                        ${fonts.bold}
                        font-size:13px;
                        color: ${colors.dark};
                        margin-bottom:5px;
                    }
                    .BottomSection {
                        padding-top:10px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

FocusEvent.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projectByKey
    };
}

export default  connect(mapStateProps, { setSelectedPlanStepId, createTimeNewLog, toggleEventDone })(FocusEvent);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { effects, colors } from '../../assets/styles/theme';
import ZoneLabel from './ZoneLabel';
import { updateActiveCalendars, updateLinkedCalendarActiveStatus } from '../../actions/timeActions';
import svgSrc from './../../assets/img/conncted-cal.svg';
class UserCalendarPanel extends React.Component {

    constructor(props){
        super(props);
        this.setInternals =this.setInternals.bind(this);
        this.state = {
            linkedCalendars : [],
            calendarProfileId : null
        }
    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props)
    }
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){
        let attrs = {
            calendarProfileId : null
        };

        attrs.linkedCalendars = props.linkedCalendars.filter((c)=>{
                return c.profile_name == props.profile;
        })
        
        attrs.linkedCalendars.map((c)=>{
            attrs.calendarProfileId = c.profile_id
        })
        
        this.setState({...attrs});
        
    }
    render(){
        const renderCalendars = ()=>{
            return this.state.linkedCalendars
                .map((c, idx)=>{
                    return (
                        <div key={idx} className="profile" onClick={()=>{this.props.toggleCalendar(c.calendar_id, !c.trackingCalendar)}}>
                            <div className="profile-toggle">
                                <svg width="20" height="20" viewBox="0 0 30 30">
                                    <path d="M24.9,24.9a14,14,0,1,0-19.8,0h0A14,14,0,0,0,24.9,24.9Z" 
                                        stroke={(c.trackingCalendar)? colors.primary : colors.mid}
                                        sroke-width="2"
                                        fill={(c.trackingCalendar)? colors.primary : colors.white } 
                                    />
                                    <polygon points="9.98 12.86 13.13 16.01 20.02 9.12 22.46 11.56 15.57 18.45 13.13 20.89 10.69 18.45 7.54 15.3 9.98 12.86" fill="#fff"/>
                                </svg>
                            </div>
                            <div className="profile-name">
                                
                                {c.calendar_name} {(c.calendar_primary)? ' (default)' : ''}
                            </div>
                            <style jsx>{`
                                .profile {
                                    display:flex;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;

                                }
                                .profile-toggle {
                                    margin-right:5px;
                                   
                                }
                                .profile-name {
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    width:100%;
                                }
                                
                            `}</style>
                        </div>
                    )
                })
        }

        return (
            <div className="UserCalendarPanel">
                <div className="head">
                    <div className="icon">
                        <img className="icon-img" src={svgSrc} alt=""/>
                    </div>
                    <div className="account">
                        <ZoneLabel style={{color : colors.primary, marginBottom:0 }}>{this.props.profile}</ZoneLabel>
                    </div>
                   
                </div>
                <div className="body">
                    <ZoneLabel style={{color : colors.mid, marginBottom:0 }}>Calendars to include:</ZoneLabel>
                    {renderCalendars()}

                   
                 
                </div>
                
                <style jsx>{`
                    .UserCalendarPanel {
                        margin-bottom:10px;
                        padding:10px;
                        ${effects.shadow1}
                        background: ${colors.white};
                        overflow: hidden;
                    }
                    .head {
                        display:flex;
                        flex-direction: column;
                    }
                    .account {
                        flex:1
                    }
                    .icon-img {
                        width:30px;
                        height:30px;
                    }
                    .helm-event-form {
                        margin-top:5px;
                    }
                   
                `}</style>
                <style jsx global>{`
                    
                .helm-event-form .ui.toggle.checkbox input:checked~.box:before, .helm-event-form  .ui.toggle.checkbox input:checked~label:before  {
                    background-color : ${colors.primary}!important;
                }  
                `}</style>
            </div>
        )
    }
}

UserCalendarPanel.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
       
    };
}

export default  connect(mapStateProps, { updateActiveCalendars })(UserCalendarPanel);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { exitPlanEditing, insertPlanSteps } from '../../actions/planAction';
import { Form, TextArea, Button, Dropdown } from 'semantic-ui-react'
import PlanEditorList from './PlanEditorList';
import { colors, fonts } from '../../assets/styles/theme';
import close from '../../assets/img/close.svg'
import { parseString } from '../../libs/stringUtils';

class PlanEditor extends React.Component {

    constructor(props){
        super(props);
        this.parseInputString = this.parseInputString.bind(this);
        this.state = {
            inputString : ``,
            working : false,
            insertPoint : 'bottom',
            insertIndex : null
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    async parseInputString(){
        this.setState({working : true});
        let actions = [];
        //split each line onto a string
        let split = this.state.inputString.split('\n');
        let startVal = (this.props.existingSteps) ? this.props.existingSteps.length : 0;


        split.forEach(async (row, idx)=>{

            if(row){
                if(/\S/.test(row)==false){
                    //just whitespace - ignore
                    return;
                }
                
                
                let a = { type : 'action', projectId : this.props.selectedProject.id, timeRemaining : 0, sortKey  : startVal+idx };


                

                let regex = /\/(\s+)?([0-9])+[\.\:]?([0-9]+)?(\s+)?$/;

                let milestoneRegex =/\/(\s+)?m(\s+)?$/i;

                let waitStepRegex =/^(\s+)?wait(\s+)?\/?(.+)?$/i;

                if(waitStepRegex.test(row)){
                    let waitStep = parseString(row);
                    a.type='wait';
                    a.timeRemaining = waitStep.time;
                }
                else if(regex.test(row)){
                    let strPos = row.search(regex);
                    let suffix = row.substring(strPos+1).trim();
                    let step = row.substring(0, strPos);
                    let splitChar = (suffix.indexOf('.')>-1) ? '.' : ':';
                    a.action = step;
                    let timeSplit = suffix.split(splitChar);
                    if(timeSplit.length==1){
                        if(timeSplit[0]){
                            a.timeRemaining = parseInt(timeSplit[0]);
                        }                        
                    } else if (timeSplit.length==2) {
                        let mins = 0;
                        if(timeSplit[0]){
                            mins+= (parseInt(timeSplit[0])* 60);
                        }
                        if(timeSplit[1]){
                            mins+= (parseInt(timeSplit[1]));
                        }
                        a.timeRemaining = mins;
                    }

                }
                else if (milestoneRegex.test(row)){
                    let strPos = row.search(milestoneRegex);
                    let suffix = row.substring(strPos+1).trim();
                    let step = row.substring(0, strPos);
                    a.action = step;
                    a.type = 'milestone';
                } 
                else {
                    a.action = row;
                }

               
                actions.push(a);
            }

           
        })

        try {

            await this.props.insertPlanSteps(actions, this.state.insertPoint, this.state.insertIndex);
            
            let stateAttrs = {working : false, inputString : ''};
            
            //manage the intert selector
            if(this.state.insertPoint=='before' || this.state.insertPoint=='after'){
                stateAttrs.insertPoint =  'bottom';
                stateAttrs.insertIndex =  null;
            }
            this.setState(stateAttrs);
        } 
        catch(e){
            console.error(e);
            this.setState({working : false });
        }
    }


    render(){
        if(!this.props.editMode) return false;

        const renderStepSelection = ()=>{
            if(this.state.insertPoint=='before' || this.state.insertPoint=='after'){
                return (
                    <Dropdown 
                        style={{marginLeft:5}}
                        placeholder='select a step'  
                        inline
                        floating
                        onChange={(e, opt)=>{this.setState({insertIndex: opt.value })}}
                        value={this.state.insertIndex}
                        
                        options={this.props.existingSteps.map((s, idx)=>{
                            return {
                                text : s.action,
                                value : s.sortKey
                            }
                        })} />
                )
            }
            return null;
        }

        const renderInstructions = ()=>{
            return (
                <div className="instructions">
                    <h3 >Step shorthand</h3>
                    <p >Add multiple steps by adding multiple lines.</p>
                    <p >Allocate time to each step by typing a forward slash at the end of the line followed by the time in minutes e.g. <strong >Setup project folder / 20</strong></p>
                    <p >You can also allocate time in hours and minutes e.g. <strong >Make initial plan / 1.30</strong></p>
                    <p >You can add milestones by typing forward slash followed by "m" e.g. <strong >Initial plan created / m</strong></p>
                    <style jsx>{`
                        .instructions {
                            margin-left: 40px;
                            font-size: 14px;
                            color: ${colors.mid};
                            
                            ${fonts.regular}
                            text-align: right;
                            padding: 10px 0px 0px;
                            flex:1;
                        }
                        .instructions:hover {
                            color: ${colors.dark};
                        }
                        .instructions :global(p) {
                            max-width: 350px;
                            margin: 0px 0px 10px auto;
                        }
                        .instructions :global(h3) {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                        .instructions :global(p) :global(strong) {
                           ${fonts.bold};
                           font-style:italic;
                           display: block;
                        }
                        @media all and (max-width:569px){
                                .instructions {
                                    text-align: left;
                                    margin-left: 0px;
                                    margin-top:20px;
                                }
                                .instructions :global(p) {
                                    max-width: 100%;
                                }
                        }

                            
                    `}</style>
                </div>
            )
        }
        return (
            
            <div className="PlanEditor" >
                
                <div className="PlanEditorBlind" onClick={this.props.exitPlanEditing}></div>

                <div className="PlanEditor-Wrap">
                
                    <div className="PlanEditor-list">
                        <PlanEditorList />
                    </div>
                    <div className="PlanEditor-form">
                        
                        <a onClick={this.props.exitPlanEditing} className="close">
                            <svg  width="40" height="40" viewBox="0 0 30 30">
                                <path d="M11.48,10.28h0a1.07,1.07,0,0,0-1.8.78,1.1,1.1,0,0,0,.35.79h0L13.45,15,10,18.14h0a1.1,1.1,0,0,0-.35.79A1.07,1.07,0,0,0,10.75,20a1.12,1.12,0,0,0,.73-.28h0l4.28-3.93a1.07,1.07,0,0,0,0-1.58Zm2.63,3.93a1.07,1.07,0,0,0,0,1.58l4.29,3.93h0a1.07,1.07,0,0,0,1.79-.79,1.06,1.06,0,0,0-.35-.79h0L16.42,15l3.43-3.14h0a1.06,1.06,0,0,0,.35-.79A1.07,1.07,0,0,0,19.12,10a1,1,0,0,0-.72.29h0ZM15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm0,25.38A11.38,11.38,0,1,1,26.38,15,11.4,11.4,0,0,1,15,26.38Z"/>
                            </svg>
                        </a>
                        
                        <h3>Add steps for</h3>
                        <h2>{this.props.selectedProject.title}</h2>

                        <div className="PlanEditor-insert">

                            Insert new steps {' '}
                            <Dropdown placeholder='Select Friend'  
                            inline
                            floating
                            value={this.state.insertPoint}
                            style={{marginLeft:0}}
                            onChange={(e, opt)=>{
                                let attrs = {};
                                attrs.insertPoint = opt.value;
                                if(opt.value==='top'|| opt.value==='bottom'){
                                    attrs.insertIndex = null;
                                }
                                this.setState(attrs)
                                
                            }}
                            options={[
                            { text : 'at the top', value : 'top' },
                            { text : 'at the bottom', value : 'bottom' },
                            { text : 'before', value : 'before' },
                            { text : 'after', value : 'after' }
                        ]} />
                        {renderStepSelection()}
                        </div>
                       
                        
                        <Form>
                            <TextArea
                                style={{maxHeight:'50vh', minHeight:'150px'}}
                                fluid="true"
                                value={this.state.inputString} 
                                autoHeight 
                                placeholder='Try adding multiple lines' 
                                onChange={(e)=>{this.setState({inputString: e.target.value})}}
                                disabled={this.state.working}
                            />
                            <div className="controls">
                                <div>
                                    <Button color="teal" style={{marginTop:10}} loading={this.state.working} onClick={this.parseInputString}>Add Steps</Button>
                                </div>
                                    {renderInstructions()}
                            </div>
                            
                        </Form>
                    </div>
                
                
                </div>
                
                
               
                
                <style jsx>{`
                    @keyframes fadeIn {
                        0%   { opacity: 0; }
                        100% { opacity: 1; }
                    }
                    h2, h3 {
                        margin:0px;
                        color : ${colors.dark};
                    }

                    h3 {
                        font-size:23px;
                    }
                    h2 {
                        font-size:40px;
                        font-weight: 300;
                        margin-bottom:10px;
                    }
                    .PlanEditor-Wrap {
                        max-width:960px;
                        min-width:80vw;
                        margin:auto;
                        display:flex;
                        position:relative;
                        z-index:100;
                        align-items: flex-start;
                    }
                    .PlanEditor-list {
                        width:50%;
                        width:30vw;
                        height:80vh;
                    }
                    .PlanEditor-form {
                        width:50vw;
                        display:flex;
                        flex-direction:column;
                        justify-content:flex-start;
                        max-height:80vh;
                        padding-left:20px;
                    }
                    .PlanEditor {
                        display: flex;
                        align-content : center;
                        justify-items : center;
                        position: fixed;
                        top:0;
                        left:0;
                        width:100vw;
                        height:100vh;
                        z-index: 100;
                    }
                    .PlanEditorBlind {
                        animation: fadeIn .2s;
                        animation-fill-mode: forwards; 
                        background: rgba(255,255,255,1);
                        position: fixed;
                        top:0;
                        left:0;
                        width:100vw;
                        height:100vh;
                    }
                    .PlanEditor-EditArea {
                        background:red;
                    } 
                    .PlanEditor-insert {
                        margin-bottom:10px;
                    }  

                    .close {
                        position: absolute;
                        top:0px;
                        right:0px;
                        cursor: pointer;
                    }
                    .close path {
                        fill : ${colors.dark};
                        transition: fill 0.3s;
                    }
                    .close:hover path{
                        fill :${colors.primary};
                    }

                    .controls {
                        display: flex;
                    }
                    @media all and (max-width:569px){
                        .PlanEditor {
                            top:20px;
                        }
                        .controls {
                            display: block;
                        }
                    }
                    @media all and (max-width:769px){
                        .PlanEditor-list {
                            display: none;
                        }
                        .PlanEditor-form{
                            width:100%;
                            padding:20px;
                        }
                       
                        .PlanEditor-Wrap {
                            min-width:100%;
                            margin: 0;
                            margin-top:60px;
                        }
                        .close {
                         
                            right:10px;
                        }
                        h2 {
                            font-size:22px;
                            font-weight: 300;
                            margin-bottom:10px;
                        }
                    }
                   
                `}</style>
            </div>
        )
    }
}

PlanEditor.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        editMode : state.plan.editMode,
        selectedProject : state.plan.selectedProject,
        existingSteps : state.plan.planSteps
    };
}

export default  connect(mapStateProps, { exitPlanEditing, insertPlanSteps })(PlanEditor);

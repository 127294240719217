const INITIAL_STATE = {
    selectedProject : null,
    planLoading : false,
    planSteps : [],
    planListener : null,
    editMode : false,
    selectedPlanStep : false,
    planStepsByProject : {
        brainDump : []
    },
    planStepsListener : null,
    changes : 0
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'SET_SELECTED_PROJECT':
            return {...state, selectedProject : action.payload, planLoading : true, editMode : true };
        case 'UPDATE_SELECTED_PROJECT_PLAN_STEPS_LISTENER':
            return {...state, planListener  :action.payload }
        case 'UPDATE_SELECTED_PROJECT_PLAN_STEPS':
            return {...state, planSteps  :action.payload,  planLoading : false }
        case 'BULK_PLAN_STEPS_SORT_UPDATED':
            return {...state, planSteps  :action.payload}
        case 'EXIT_PLAN_EDIT_MODE':
            return {...state, selectedProject : null, planLoading : false, editMode : false, planSteps : [] };
        case 'SET_SELECTED_PLAN_STEP':
            return {...state, selectedPlanStep : action.payload }
        case 'UPDATE_USER_PLAN_STEPS':
            return {...state, planStepsByProject : action.payload }
        case 'UPDATE_USER_PLAN_STEP_LISTENER':
            return {...state, planStepsListener : action.payload }
        case 'UPDATE_USER_CHANGES_COUNT':
            return {...state, changes : action.payload }

        default:
            return state;
    }
}
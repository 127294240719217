import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Time from './Time';
import ZoneTitle from '../common/ZoneTitle';

class TimeMobile extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="TimeMobile">
                <div>
                    <ZoneTitle style={{marginBottom:10, textAlign : 'center'}}>We're working on it</ZoneTitle>
                    <p style={{textAlign: 'center'}}>Please sign into Helm on a larger screen to plan your perfect week.</p>
                </div>
                <style jsx>{`
                    .TimeMobile {
                        display: flex;
                        flex:1;
                        align-items: center;
                        justify-content: center;
                        padding:20px;
                        text-align: left;
                    }    
                `}</style>
            </div>
        )
    }
}

TimeMobile.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(TimeMobile);

import React, { Component } from 'react';


import {Provider} from 'react-redux';
import configureStore from './store';
import Helm3 from './components/Helm3';
import HelmV4 from './components/HelmV4';

const store = configureStore();


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HelmV4></HelmV4>
      </Provider>
      
    );
  }
}

export default App;

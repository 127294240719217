import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {scaleLinear} from 'd3-scale';
import interact from 'interactjs';
import { deleteTimeBlock, saveTimeBlock } from '../../actions/timeActions';

import resizeSrc from './../../assets/img/resize-timeblock.svg';
import deleteSrc from './../../assets/img/delete-timeblock.svg';
import { colors } from '../../assets/styles/theme';
class WeekDayTimeBlock extends React.Component {

    constructor(props){

        super(props);
        this.blockHeight = props.blockHeight || 15;
        this.yScale = scaleLinear().domain([0, 86400]).range([0, (96 * this.blockHeight)]).clamp(true);
        this.yScaleReverse = scaleLinear().domain([0, (96 * this.blockHeight)]).range([0, 86400]).clamp(true);
        this.state = {
            y : 0,
            height: 0
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        
        let {block} = this.props;
        
        
        let y = this.yScale(this.props.block.start);
        let height = this.yScale(block.end - block.start)+'px';

       
        this.node.style.webkitTransform = this.node.style.transform = 'translate(0px,' + y + 'px)';
        this.node.style.height = height;
        this.node.setAttribute('data-x', 0);
        this.node.setAttribute('data-height', height);
        this.node.setAttribute('data-y', y);

        var gridTarget = interact.createSnapGrid({
            x: 100,
            y: this.blockHeight,
          });

    
        interact(this.node)
        // .draggable({
        //     axis: 'y',
        //     onmove: (event)=>{

        //         console.log('onmove')
            
        //         var target = event.target,
        //         // keep the dragged position in the data-x/data-y attributes
        //         x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
        //         y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy,

                
        //         height = (parseFloat(target.getAttribute('data-height')) || 0);
               
                
        //         console.log('event', event, x, y);
                
        //         // if(y <= this.bounds.low){
        //         //     console.log('y', y, this.bounds.low)
        //         //     y = this.bounds.low;
        //         // }

        //         // if( y >= this.bounds.hi){
        //         //     console.log('y', y, this.bounds.hi)
        //         //     y = this.bounds.hi
        //         // };

               

            

        //         //add the active class on the block
        //         target.classList.add('active');
                
        //         // translate the element
        //         target.style.webkitTransform =
        //         target.style.transform =
        //           'translate(' + 0 + 'px, ' + y + 'px)';
            
        //         // update the posiion attributes
        //         target.setAttribute('data-x', x);
        //         target.setAttribute('data-y', y);
        //       }
        //     ,
        //     snap : { targets: [gridTarget] },
        //     axis : 'y',
        //     restrict: {
        //       restriction: 'parent',
        //       elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        //     },
        //   })
        //   .on('dragstart', (event)=>{
        //       console.log('dragstart');
        //       var target = event.target,
        //         // keep the dragged position in the data-x/data-y attributes
        //         x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
        //         y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy,
        //         height = (parseFloat(target.getAttribute('data-height')) || 0);


        //         let bounds = {
        //             low : 0,
        //             hi : (96 * this.props.blockHeight) - height
        //         };
        //         //calculate bounds so blocks cannot overlap
        //         let endsBeforeThisBlock = this.props.timeBlocks
        //         .filter((b)=>{
        //             //remove this block
        //             if(b.id===this.props.block.id) return false;
        //             //only blocks for this day
        //             return (this.props.block.day == b.day);
        //         })
        //         .map((b)=>{
        //             let end =  this.yScale(b.end);
        //             if( (end <= y) && end > (bounds.low)){
        //                 bounds.low = end;
        //             }
        //             let start = this.yScale(b.start);
        //             if( (start >= y+height) && start < (bounds.hi)){
        //                 bounds.hi = start-height;
        //             }
        //         });
        //         this.bounds = bounds;
        //         console.log('this.bounds', this.bounds);
            
        //   })
          .resizable({
            axis: 'y',
            // resize from all edges and corners
            edges: { left: false, right: false, bottom: '.WeekDayTimeBlock-handle', top: false },
            interval : this.blockHeight,
            snapSize : { targets: [gridTarget] },
            // keep the edges inside the parent
            // restrictEdges: {
            //   outer: 'parent',
            //   endOnly: true,
            // },
        
            // minimum size
            restrictSize: {
              min: {height: this.blockHeight },
            },
        
            // inertia: true,
          })
          .on('dragend', (event)=>{        
            let start = parseFloat(event.target.getAttribute('data-y'));
            let height = parseFloat(event.target.getAttribute('data-height'));
            event.target.classList.remove('active');

            let block = {...this.props.block};
            block.start = this.yScaleReverse(start);
            block.end = this.yScaleReverse(start + height);
            this.props.saveTimeBlock(block)


          })
          .on('resizemove', (event)=>{
            var target = event.target,
            x = (parseFloat(target.getAttribute('data-x')) || 0),
            y = (parseFloat(target.getAttribute('data-y')) || 0),
            height = event.rect.height;
            

            //prevent resizing past the next block
            let startsAfterThisBlock = this.props.timeBlocks
            .filter((b)=>{
                if(this.props.block.day !== b.day) return false;
                return ( b.start > this.yScaleReverse(y));
            })
            .map((b)=>{
                return this.yScale(b.start);
            })

            if(startsAfterThisBlock[0] && ( startsAfterThisBlock[0] < (height + y) ) ){
                //this block will overlap the next block - reset the height to the max
                height = startsAfterThisBlock[0]-y;
            }

            if( (height + y) >= (96 * this.blockHeight)  ){
                //this block will overlap the next block - reset the height to the max
                height = (96 * this.blockHeight)-y;
            }


            // update the element's style
            target.style.height = height + 'px';
            target.classList.add('active');
            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;
            
            target.style.webkitTransform = target.style.transform =
                'translate(0px,' + y + 'px)';
        
            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
            target.setAttribute('data-height', height);
            // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height);
          })
          .on('resizeend', (event)=>{
                console.log(event.target);
                let start = parseFloat(event.target.getAttribute('data-y'));
                let height = parseFloat(event.target.getAttribute('data-height'));
                event.target.classList.remove('active');
                let block = {...this.props.block};
                block.start = this.yScaleReverse(start);
                block.end = this.yScaleReverse(start + height);
                this.props.saveTimeBlock(block)
                console.log('resizeend', height, this.yScaleReverse(start));
          })
          
    }

    componentDidUpdate(newProps){
    }
   
    getPosition(event) {
        if(event.touches){
            return {
                x: event.touches[0].pageX || event.screenX || 0,
                y: event.touches[0].pageY || event.screenY || 0
            };
        } else {
            return {
                x: event.pageX || event.screenX || 0,
                y: event.pageY || event.screenY || 0
            };
        }

    }

   

    render(){

        const getFill = ()=>{
            if(this.props.timeBucketsByKey && this.props.block.timeBucketId && this.props.timeBucketsByKey[this.props.block.timeBucketId]){
                return this.props.timeBucketsByKey[this.props.block.timeBucketId].color;
            } else {
                return colors.mid;
            }
        }
        return (
            <div
                ref={(node)=>this.node = node}
                style={{background : getFill()}}
                className="WeekDayTimeBlock"
            >
                
                <div className="WeekDayTimeBlock-delete"  onClick={()=>this.props.deleteTimeBlock(this.props.block)}>
                    <img src={deleteSrc} alt=""/>
                </div>
                <div className="WeekDayTimeBlock-handle" ref={(node)=>{this.resizeHandleNode = node}} >
                    <img src={resizeSrc} alt=""/>
                </div>
                
                <style jsx global>{`
                    .WeekDayTimeBlock {
                        position: absolute;
                        top:0px;
                        left:40px;
                        right:0px;
                        max-width: 80px;
                        font-size:10px;
                        opacity: 0.90;
                        overflow: hidden;
                        border-top:1px solid #fff;
                        user-select: none;
                        cursor: pointer;
                        max-width: 50px;
                    }
                    .WeekDayTimeBlock.active {
                        box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
                        z-index: 1;
                    }
                    
                    .WeekDayTimeBlock-delete {
                        position: absolute;
                        top:0px;
                        right:0px;
                        left:0px;
                        height: ${this.props.blockHeight-1}px;
                        background:rgba(255,255,255,0.3);
                        text-align: center;
                        color:rgb(39, 39, 39);
                        font-weight: 700;
                        text-align: right;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        padding-right:1px;
                    
                    }
                    .WeekDayTimeBlock-delete  img {
                        width:${this.props.blockHeight-4}px;
                        opacity: 0.7;
                    }
                    .WeekDayTimeBlock-handle {
                        position: absolute;
                        bottom:0px;
                        left:0px;
                        padding-top:2px;
                        text-align: left;
                        right:${this.props.blockHeight+5}px;
                        height:${this.props.blockHeight-1}px;
                        width:${this.props.blockHeight}px;
                        background:rgba(255,255,255,1);
                        box-shadow: inset -1px 1px 3px rgba(0,0,0,0.5);
                        border-radius : 0px ${this.props.blockHeight}px 0px 0px;
                    }
                    .WeekDayTimeBlock-handle  img {
                        width:${this.props.blockHeight-4}px;
                        position: relative;
                        left:-2px;
                    }
                `}</style>
            </div>
        )
    }
}

WeekDayTimeBlock.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        timeBlocks : state.time.timeBlocks,
        timeBucketsByKey : state.time.timeBucketsByKey
    };
}

export default  connect(mapStateProps, { deleteTimeBlock, saveTimeBlock })(WeekDayTimeBlock);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, fonts, effects } from '../../assets/styles/theme';
import { setActiveTimeBucket, editTimeBucket } from '../../actions/timeActions';
import TimeBucketSelectorButton from './TimeBucketSelectorButton';
import PopUp from './PopUp';
import TimeBucketEditor from './TimeBucketEditor';

class TimeBucketSelector extends React.Component {

    constructor(props){
        super(props);
        this.renderBuckets = this.renderBuckets.bind(this);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    renderBuckets(){
        if(!this.props.timeBuckets) return null;
        return this.props.timeBuckets.map((b)=>{
            return (
                <TimeBucketSelectorButton action={this.props.setActiveTimeBucket}  key={b.id} bucket={b}></TimeBucketSelectorButton>
    
            )
        })
    }
    render(){
        return (
            <div className="TimeBucketSelector">
                <div>
                    {this.renderBuckets()}
                </div>
                <div className="createNew" onClick={()=>{this.props.editTimeBucket({})}}>
                    <svg className="createNewIcon" width="30" height="30" viewBox="0 0 30 30">
                        <path fill={colors.primary} d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm0,25.38A11.38,11.38,0,1,1,26.38,15,11.4,11.4,0,0,1,15,26.38ZM22,15a1,1,0,0,1-1,1H16v5a1,1,0,0,1-2,0V16H9a1,1,0,0,1,0-2h5V9a1,1,0,0,1,2,0v5h5A1,1,0,0,1,22,15Z"/>
                    </svg>
                </div>
                <PopUp
                    onClose={()=>{this.props.editTimeBucket(null)}}
                    visible={(this.props.editingTimeBucket)? true : false}
                >
                    <TimeBucketEditor bucket={this.props.editingTimeBucket}></TimeBucketEditor>
                </PopUp>
                <style jsx global>{`
                    .TimeBucketSelector{
                      
                    }
                    
                    .TimeBucketSelector-button {
                        
                        display: flex;
                        padding:5px;
                        background: #fff;
                        border:1px solid ${colors.mid};
                        margin:0px 5px 0px 5px;
                        border-radius: 3px;
                        align-items: center;
                        cursor: pointer;
                    }
                    .TimeBucketSelector-button:focus, .TimeBucketSelector-button:active {
                        outline: none;
                    }
                    .TimeBucketSelector-button.active {
                        background: ${colors.dark}
                    }
                    .TimeBucketSelector-swatch {
                        width:20px;
                        height: 20px;
                    }
                    .TimeBucketSelector-title {
                        margin-left:5px;
                        margin-right : 10px;
                        ${fonts.bold}
                    }
                    .TimeBucketSelector-button.active  .TimeBucketSelector-title {
                        color: ${colors.white};
                    }
                    .createNew {
                        padding:5px;
                        display: inline-block;
                        background: ${colors.white};
                        border-radius: 5px;
                        ${effects.shadow1}
                        cursor: pointer;
                    }
                    .createNewIcon {
                        display: block;
                    }

                `}</style>
            </div>
        )
    }
}

TimeBucketSelector.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        timeBuckets : state.time.timeBuckets,
        activeTimeBucket : state.time.activeTimeBucket,
        editingTimeBucket : state.time.editingTimeBucket
    };
}

export default  connect(mapStateProps, { setActiveTimeBucket, editTimeBucket })(TimeBucketSelector);


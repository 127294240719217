import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TimeLengthDisplay from './TimeLengthDisplay';
import TimeEditorInput from './TimeEditorInput';
import { colors } from '../../assets/styles/theme';
import { Button } from 'semantic-ui-react';



class TimeEditor extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            editing : false,
            time : null
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        // this.setState({time : parseMinutes(this.props.time)});
    }
    componentWillReceiveProps(newProps){
        // this.setState({time : parseMinutes(newProps.time)});
    }


    
    /**
    let splitChar = (suffix.indexOf('.')>-1) ? '.' : ':';
                    a.action = step;
                    let timeSplit = suffix.split(splitChar);
                    if(timeSplit.length==1){
                        if(timeSplit[0]){
                            a.timeRemaining = parseInt(timeSplit[0]);
                        }                        
                    } else if (timeSplit.length==2) {
                        let mins = 0;
                        if(timeSplit[0]){
                            mins+= (parseInt(timeSplit[0])* 60);
                        }
                        if(timeSplit[1]){
                            mins+= (parseInt(timeSplit[1]));
                        }
                        a.timeRemaining = mins;
                    }
     * 
     * / */
    render(){
        const renderContent = ()=>{
            if(this.state.editing){
                return (
                    <div className="editorWrap">
                        <TimeEditorInput
                        value={this.props.time}
                        onChange={(minutes)=>{
                            this.props.onChange(minutes);
                        }}
                        onEnter={(minutes)=>{
                            this.props.onChange(minutes);
                            this.setState({
                                editing : false
                            });
                        }}
                        ></TimeEditorInput>
                    </div>
                )
            }
            return(
                <div onClick={()=>{this.setState({editing : true})}}>
                    <div className="step-time-allocation">
                    <TimeLengthDisplay
                        long={true} time={this.props.time}
                    />
                        <div className="icon">
                            <svg width="20" height="20" viewBox="0 0 38 38">
                                <path fill={colors.primary} d="M21.78,11l4.63,4.64L14.68,27.33,10,22.7Zm8.15-1.12L27.86,7.78a2,2,0,0,0-2.89,0l-2,2,4.64,4.63,2.31-2.31A1.58,1.58,0,0,0,29.93,9.84ZM7.62,29.21a.53.53,0,0,0,.63.63l5.17-1.25L8.79,24Z"/>
                            </svg>
                        </div>
                    <div>
                   

                    </div>
                    </div>
                    
                    


                    <style jsx>{`
                        .step-time-allocation {
                            min-width: 80px;
                            text-align: center;
                            padding: 5px 36px 5px 15px;
                            border-radius: 15px;
                            background:${colors.primary};
                            white-space: nowrap;
                            font-weight: 700;
                            color: #fff;
                            height: 30px;
                            cursor: pointer;
                            display: inline-block;
                            position: relative;
                            white-space: nowrap;
                        }
                        .icon {
                            position: absolute;
                            right:3px;
                            top:3px;
                            background: ${colors.white};
                            border-radius: 24px;
                            height:24px;
                            width: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .controls {
                            padding:10px;
                            display: flex;
                            justify-content: space-between;
                        }
                        
                        `}</style>
                </div>
            )
        }
        return (
            <div className="TimeEditor">
                {renderContent()}
                <style jsx>{`
                    .TimeEditor {
                        display: inline-block;
                    }    
                    
                `}</style>
            </div>
        )
    }
}

TimeEditor.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(TimeEditor);

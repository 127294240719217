const INITIAL_STATE = {
    projects : [],
    projectByKey : {},
    projectListener : null
};

export default (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){

        case 'USER_PROJECTS_UPDATE':
            attrs.projects= action.payload;
            attrs.projectByKey = {};
            action.payload.map((p)=>{ 
                attrs.projectByKey[p.id] = p;
            })
            return {...state, ...attrs};

        case 'USER_PROJECTS_LISTENER_UPDATE':
            return {...state, projectListener : action.payload };

        case 'PROJECTS_CLEAR':
            return {...state, projects : [], projectByKey : {}, projectListener : null }
        default:
            return state;
    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { bulkUpdateSortOrderAndProjectId, setSelectedPlanStepId, setSelectedPlanStep } from '../../../actions/planAction';
import PlanList from '../../global/PlanList/PlanList';
import ZoneSubTitle from '../../global/Text/ZoneSubTitle';
import { Button,Checkbox} from 'semantic-ui-react';
import NewProjectModal from './NewProjectModal';
import ManageProjects from './ManageProjects';
import PlanStepEditor from '../../common/PlanStepEditor';
import PlanListTitle from './PlanListTitle';
import { colors } from '../../../assets/styles/theme';
import BraindumpInputBox from '../../global/BrainDump/BraindumpInputBox';
import BrainDumpHeader from '../../global/BrainDump/BrainDumpHeader';
import BrainDumpPanel from '../../global/BrainDump/BrainDumpPanel';
import MediaQuery from 'react-responsive';
import TimeLogger from '../../common/TimeLogger';


class PlansScreen extends React.Component {

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            projects : [],
            projectSteps : {},
            isOpen : false,
            manage : false
        }

        
    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
    }
    
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    
    
    setInternals(props){
        
        let attrs = {
            projects : [],
            projectSteps : {}
        };

        if(props.projects){
              
            props.projects
            .filter((p)=>{
                return (!p.archived);
            })
            .map((p)=>{
                attrs.projects.push({...p})
            })
            
            attrs.projects.forEach((proj)=>{
                attrs.projectSteps[proj.id] = props.planStepsByProject[proj.id] || [];
            })
        }
        attrs.projectSteps.brainDump = props.planStepsByProject.brainDump || []; 


        this.setState({...attrs});
    }



    onDragEnd(result){
        // dropped outside the list
        if (!result.destination) {
            return;
        }


        //clone the project steps
        let projectSteps = {...this.state.projectSteps};
        //take out the dropped step

        let dropped = projectSteps[result.source.droppableId].splice(result.source.index, 1);
        //insert into destination array
        projectSteps[result.destination.droppableId].splice(result.destination.index, 0, dropped[0]);

        let sortUpdate = projectSteps[result.destination.droppableId].map((i, idx)=>{
            return {...i, sortKey : idx};
        })
        
        this.props.bulkUpdateSortOrderAndProjectId(sortUpdate, result.destination.droppableId);
      
    }


    render(){

        const renderProjects = ()=>{
            return this.state.projects
                .filter((p)=>{
                    return (!p.archived)
                })
                .map((p)=>{
                    return (
                        <div className="project" key={p.id}>
                            <PlanListTitle>{p.title}</PlanListTitle>
                            <PlanList project={p} steps={this.state.projectSteps[p.id]}></PlanList>
                        </div>
                    )
                })
        }

        const getListWrapWidth = ()=>{
            return (this.state.projects.length + 2) * 310;
        }

        const renderSection = ()=>{
                if(this.state.manage){
                    return <ManageProjects></ManageProjects>
                }
                return (
                    <div className="list-wrap" >
                        <div className="lists" style={{ width :getListWrapWidth() }}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <BrainDumpPanel>
                                    <MediaQuery query="(min-width: 698px)">
                                        <BrainDumpHeader></BrainDumpHeader>
                                    </MediaQuery>
                                    <MediaQuery query="(max-width: 697px)">
                                        <BrainDumpHeader width={50}></BrainDumpHeader>
                                    </MediaQuery>
                                    
                                    <BraindumpInputBox placeholder="What's on your mind?" style={{marginBottom:10, marginTop:10}} />
                                    <PlanList project={{ id : "brainDump"}} steps={this.state.projectSteps["brainDump"]}></PlanList>
                                </BrainDumpPanel>
                                {renderProjects()}
                            </DragDropContext>
                        </div>
                    </div>
                )
        }

        return (

           


            <div className="PlansScreen">
                <div className={(this.state.manage)?'controls  manage' : "controls"} style={{background : "#dddada"}}>
                    <div className="controls-add">
                        <Button 
                                size="small"
                                color="teal"
                                onClick={()=>{
                                this.setState({isOpen : true})
                            }}>Add New Stream
                        </Button>
                    </div>
                    
                    <div className="controls-toggle">
                        <Checkbox toggle
                                style={{marginLeft:10}}
                                checked={this.state.manage}
                                label="Sort / Archive Streams"
                                onChange={(v)=>{this.setState({manage : !this.state.manage })}}
                        />
                    </div>

                    
                </div>
                
                {renderSection()}
                <PlanStepEditor />
                <TimeLogger onComplete={()=>{
                    //reload the step
                    this.props.setSelectedPlanStep(null);
                   
                }} />
                <NewProjectModal 
                    isOpen={this.state.isOpen}
                    onCancel={()=>{this.setState({ isOpen: false })}}
                />
                <style global jsx>{`
                    .PlansScreen{
                        display: grid;
                        height: calc(100vh - 50px);
                        grid-template-columns: 320px  1fr;
                        grid-template-rows:  55px 1fr;
                        grid-template-areas: 
                            "braindump controls"
                            "braindump plans"
                        ;

                    }
                    .PlansScreen .list-wrap {
                        overflow-x: auto;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: none;
                        grid-area:plans;

                        
                    }
                    .PlansScreen .lists {
                        display: flex;
                        margin:0px 10px;
                        padding:10px;
                    }    

                    .PlansScreen .controls {
                        grid-area:controls;
                        display: grid;
                        padding:10px;
                        grid-template-columns: auto 1fr auto;
                        grid-template-areas: 
                            "add . toggle"
                        ;
                        border-bottom:1px solid #fff;
                        
                    }


                    .PlansScreen .ManageProjects {
                        grid-area: plans;
}
                    }

                    .controls-add {
                        grid-area : add;
                        padding-left:10px;
                        align-self: center;
                    }   

                    .controls-toggle {
                        grid-area : toggle;
                        align-self: center;
                    }

                    .PlansScreen .project.brain-dump {
                        grid-area:braindump;
                        
                    }

                    

                    .PlansScreen .project {
                        width:300px;
                        margin-right:20px;
                        
                    }  
                    .OLD .PlansScreen .project.brain-dump {
                        position: fixed;
                        left:0px;
                        top:50px;
                        background: ${colors.primary};
                        z-index: 1;
                        padding:10px 10px 0px 10px;
                        border-radius: 0px 5px 0px 0px;
                        box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
                        bottom:0px;
                        width:330px;
                    }    

                   

                    .OLD .PlansScreen .brain-dump-list {
                        overflow-y: auto;
                        max-height: 100%;
                        padding-bottom:20px;
                        -webkit-overflow-scrolling: touch;
                    }
                    @media all and (max-width:800px){
                       
                        .PlansScreen .lists {
                            padding-left:290px;
                        }  
                       
                    }

                    @media all and (max-width:697px){
                        .PlansScreen .project {
                            width:60vw;
                            margin-right:10px;
                        }  
                        .PlansScreen .lists {
                            
                            padding-left:0px;
                        } 
                        .PlansScreen .project.brain-dump {
                            position: relative;
                            left:0px;
                            background: #444;

                        }    
                        .PlansScreen .controls {
                            padding-left:10px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

PlansScreen.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projects,
        user : state.app.authedUser,
        planStepsByProject : state.plan.planStepsByProject,
        selectedStep : state.plan.selectedPlanStep,
    };
}

export default  connect(mapStateProps, { bulkUpdateSortOrderAndProjectId, setSelectedPlanStepId, setSelectedPlanStep})(PlansScreen);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { updateProjectsSortOrder, saveProject } from '../../actions/projectAction';
import { Checkbox, Pagination, Icon } from 'semantic-ui-react';
import { colors, fonts, effects } from '../../assets/styles/theme';
import  FlipMove from 'react-flip-move';
import ZoneLabel from './ZoneLabel';


const reorder = (list, startIndex, endIndex) => {
const result = Array.from(list);
const [removed] = result.splice(startIndex, 1);
result.splice(endIndex, 0, removed);

return result;
};


const Brightness = (color)=>{
    if(color.length==7){color=color.substring(1);}
    var R =	parseInt(color.substring(0,2),16);
    var G =	parseInt(color.substring(2,4),16);
    var B =	parseInt(color.substring(4,6),16);
    return Math.sqrt(R * R * .241 + G * G * .691 + B * B * .068);
}

class ManageProjects extends React.Component {

    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.toggleArchive = this.toggleArchive.bind(this);
        this.state = {
            showArchived : false,
            projects : [],
            pageSize : 3,
            totalPages : 0,
            currentPage : 0
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        this.setInternals(this.props)
    }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps)
    }

    setInternals(props){

        let projects = props.projects.slice();

        if(!this.props.showArchived){
            projects = projects.filter((p, idx)=>{
                if(this.state.showArchived){
                    return true;
                }
                return !(p.archived)
            })
        }

        this.setState({
            projects : projects,
            totalPages : Math.ceil(projects.length/this.state.pageSize)
        })
    }

    async toggleArchive(project){
        if(project.archived){
            project.archived = false;
            project.sortKey=0;
        } else {
            project.archived = true;
            project.sortKey = -1;
        }
        try {
            await this.props.saveProject(project);
        }
        catch(e){
            console.error(e);
        }
    }

    renderProjectList(){

        const renderControl = (p)=>{
            if(this.state.showArchived){
                return <Checkbox checked={p.archived} style={{marginRight:10}} onClick={()=>{this.toggleArchive(p)}} />
            } else {
                return <Icon name="arrows alternate vertical"></Icon>
            }
        }
        return this.state.projects
            
            .map((p, idx)=>{
            return (
                <Draggable 
                    key={p.id} 
                    draggableId={p.id+"drag"} 
                    index={idx} 
                    isDragDisabled={this.state.showArchived}
                    style={{width:'30vw'}}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    >
                                       <div className="list-row-wrap"
                                        style={{
                                            backgroundColor : p.color || colors.dark
                                        }}
                                       >
                                       
                                       <div className="title"
                                            style={{
                                                color : (Brightness(p.color)<200) ? "#fff" : "#000"
                                            }}
                                       >
                                            {renderControl(p)}
                                           {p.title} 
                                        </div>
                                        
                                       </div>
                                        
                                    </div>
                                )}

                              
                                
                            </Draggable>
            )
        })
        //sortable project list.....
    }

    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        //no actual change
        if(result.source.index===result.destination.index) return;

        const newOrder = reorder(
            this.state.projects.slice(),
            result.source.index,
            result.destination.index
        );
        this.setState({
            projects : newOrder
        })
        await this.props.updateProjectsSortOrder(newOrder, (this.state.currentPage * this.state.pageSize))
    }

   

    render(){
        const renderPagination = ()=>{
            return null;
            if(!this.state.showArchived) return <div />
            if(this.state.totalPages>1){
                return (
                    <Pagination
                        activePage={this.state.currentPage+1}
                        firstItem={null}
                        lastItem={null}
                        size="mini"
                        onPageChange={(e, v)=>{
                            this.setState({currentPage : v.activePage-1}, ()=>{
                                this.setInternals(this.props)
                            })
                        }}
                        totalPages={this.state.totalPages}
                    />
                )
            } else {
                return <div></div>
            }
        }
        return (
            <div className="ManageProjects">
                <div className="instruction">
                <ZoneLabel>
                {(this.state.showArchived)?'Archived':'Drag to change the order of streams in the streams view'}
                </ZoneLabel>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable 
                        droppableId="manageList"
                        isDropDisabled={this.state.showArchived}
                        >
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} >
                            <FlipMove
                                enterAnimation="fade"
                                leaveAnimation="fade"
                            >
                            {this.renderProjectList()}
                            </FlipMove>
                               
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                    
                <div className="controls">
                            
                        <Checkbox toggle
                            checked={this.state.showArchived}
                            label="Show Archived Projects"
                            onChange={(v)=>{
                                this.setState({showArchived : !this.state.showArchived, currentPage : 0 }, ()=>{
                                    this.setInternals(this.props);
                                })
                            }}
                        ></Checkbox>


                </div>
                    
                <style jsx>{`
                    .ManageProjects {
                        padding:50px 20px 20px 20px;
                        width: 100%;
                    }
                    .controls {
                        padding-top:20px;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .ManageProjects :global(.title){
                        flex:1;
                        display: flex;
                        align-items: center;
                    }
                    .instruction {
                        margin-top:10px;
                        margin-bottom:10px;
                    }
                    .ManageProjects :global(.list-row-wrap){
                        ${fonts.bold}
                        background: #fff;
                        display: flex;
                        ${effects.shadow1}
                        padding : 10px 5px 10px 5px;
                        align-items: center;
                        border-radius: 5px;
                        margin-bottom:5px;
                    }
                    .ManageProjects :global(.title){
                        ${fonts.bold}
                    }
                `}</style>
            </div>
        )
    }
}

ManageProjects.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projects
    };
}

export default  connect(mapStateProps, { updateProjectsSortOrder, saveProject })(ManageProjects);

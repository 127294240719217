import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WeekDay from '../common/WeekDay';
import { colors, fonts } from '../../assets/styles/theme';
import TimeBucketSelector from '../common/TimeBucketSelector';
import {TweenLite, ScrollToPlugin, Linear, Power3 } from 'gsap/all';
import ZoneTitle from '../common/ZoneTitle';
import ZoneLabel from '../common/ZoneLabel';
import TimeZoneSelector from '../common/TimeZoneSelector';
import ZoneSubTitle from '../common/ZoneSubTitle';
import UserCalendarSettings from '../common/UserCalendarSettings';
import { logEvent } from '../../analytics';
class Time extends React.Component {

    constructor(props){
        super(props);
        
       
    }
    componentWillMount(){



     }
    
    componentDidMount(){
        
        logEvent('timeScreenLoaded')
        TweenLite.to(this.weekPanel, 1, { scrollTo : 800, ease : Power3.easeOut })

     }

  

    render(){
        if(this.props.blocksLoading){
            return <p>Loading Blocks</p>
        }
        
        return (
            <div className="Time">

                <div className="Time-controls">
                    <ZoneTitle>Time</ZoneTitle>

                    <div className="Time-controls-wrap">
                        
                        <div className="Time-options">
                            <TimeBucketSelector></TimeBucketSelector>
                        </div>
                        <div className="Time-weekdays" >
                            <div className="Time-weekdays-weekdayTitles" >
                                <div className="weekdayTitles"><span className="weekdayTitle">Mon</span></div>
                                <div className="weekdayTitles"><span className="weekdayTitle">Tue</span></div>
                                <div className="weekdayTitles"><span className="weekdayTitle">Wed</span></div>
                                <div className="weekdayTitles"><span className="weekdayTitle">Thur</span></div>
                                <div className="weekdayTitles"><span className="weekdayTitle">Fri</span></div>
                                <div className="weekdayTitles"><span className="weekdayTitle">Sat</span></div>
                                <div className="weekdayTitles"><span className="weekdayTitle">Sun</span></div>
                            </div>
                            <div className="Time-weekdays-weekdayblocks" ref={(node)=>{this.weekPanel = node;}}>
                                <WeekDay day="d1" blockHeight={25} title="Mon" />
                                <WeekDay day="d2" blockHeight={25} title="Tue" />
                                <WeekDay day="d3" blockHeight={25} title="Wed" />
                                <WeekDay day="d4" blockHeight={25} title="Thur" />
                                <WeekDay day="d5" blockHeight={25} title="Fri" />
                                <WeekDay day="d6" blockHeight={25} title="Sat" />
                                <WeekDay day="d0" blockHeight={25} title="Sun" />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="Time-calendars">
                    <div className="Time-calendars-wrap">
                        <ZoneLabel>My Timezone</ZoneLabel>
                        <TimeZoneSelector></TimeZoneSelector>

                        <ZoneSubTitle style={{marginTop:20, marginBottom:10}}>Connected Calendars</ZoneSubTitle>
                        <UserCalendarSettings></UserCalendarSettings>
                    </div>
                </div>

               
                <style jsx>{`
                    .wrap {
                        margin:10px auto;
                        padding:10px;
                        max-width: 900px;
                    }
                    .Time { 
                        display: flex;
                        margin:20px;
                        flex:1;
                        overflow: hidden;
                        max-height: calc( 100vh - 70px );
                        ${fonts.regular};
                        padding-bottom:20px;
                        
                    }
                    .Time-options {
                        padding-right:20px;
                    }
                    .Time-controls{
                        flex:1;
                    
                    }
                    .Time-calendars {
                        width: 25%;
                        padding-left:20px;
                        
                        display: flex;
                        flex-direction: column;
                    }
                    .Time-calendars-wrap {
                        padding-right:20px;
                        flex:1;
                        overflow-y: auto;
                        overflow-x: hidden;
                        -webkit-overflow-scrolling: touch;
                    }
                    .Time-controls-wrap {
                        flex: 1;
                        display: flex;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                        padding-top:20px;
                    }
                    .Time-weekdays {
                        height: 100%;
                        
                        flex:1;
                        display: flex;
                        flex-direction: column;
                        
                    }
                    .Time-weekdays-weekdayblocks {
                        display: flex;
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        margin-bottom:20px;
                    }
                    .Time-weekdays-weekdayTitles {
                        display: flex;
                        padding-right:9px;
                    }
                    .weekdayTitles {
                        flex:1;
                        font : ${fonts.bold};
                        color:${colors.dark};
                        position: relative;    
                        padding-left:10px;  
                        text-align: left;   
                        padding-bottom:5px;               
                    }
                    .weekdayTitle {
                        
                    }
                    @media all and (max-width:959px){
                        .Time { 
                            padding-top:40px;                      
                        }
                    }
                `}</style>
            </div>
        )
    }
}

Time.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        blocksLoading : state.time.loading
    };
}

export default  connect(mapStateProps, {  })(Time);

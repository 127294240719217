import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, Header } from 'semantic-ui-react'
import { colors } from '../../assets/styles/theme';
import  helmSrc from './../../assets/img/helm-logo.svg';                    
class Loading extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="Loading">
                <div className="center-content">
                    
                    <Loader inverted active >
                        <div>
                            <img src={helmSrc} style={{width:94, height:18}}  alt=""/>
                        </div>
                        <div style={{ color : colors.white, fontSize:14, color : colors.midHi }}>{this.props.children || "LOADING"}</div>
                    </Loader>
                </div>
                <style jsx>{`
                    
                    .Loading {
                        flex:1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                   
                    `}</style>
            </div>
        )
    }
}

Loading.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(Loading);

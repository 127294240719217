import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { colors } from '../../assets/styles/theme';
import {TweenLite, ScrollToPlugin, Linear, Power3 } from 'gsap/all';
import ZoneTitle from '../common/ZoneTitle';
import { setSelectedPlanStepId } from '../../actions/planAction';
import PlanStepEditor from '../common/PlanStepEditor';
import MilestoneList from '../common/MilestoneList';
import { logEvent } from '../../analytics';
import PushHelmToCalendar from '../global/PushHelmToCalendar/PushHelmToCalendar';

const plugins = [ScrollToPlugin];
const Brightness = (color)=>{
    if(color.length==7){color=color.substring(1);}
    var R =	parseInt(color.substring(0,2),16);
    var G =	parseInt(color.substring(2,4),16);
    var B =	parseInt(color.substring(4,6),16);
    return Math.sqrt(R * R * .241 + G * G * .691 + B * B * .068);
}

const localizer = BigCalendar.momentLocalizer(moment) 


class MasterPlanMobile extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            events : []
        }
      

    }
    componentWillMount(){ }
    
    componentDidMount(){
        
        logEvent('masterPlanMobileScreenLoaded')
        this.setInternals(this.props);
        TweenLite.to('.rbc-time-content', 1, { scrollTo : 500, ease : Power3.easeOut })
    }

    componentWillReceiveProps(props){
        this.setInternals(props);
    }
    setInternals(props){
        let events = [];
        props.events.map((e)=>{
            e.start = moment(e.start).toDate();
            e.end = moment(e.end).toDate();
            e.fill = colors.mid;
            e.textFill = colors.dark;
            if(e.projectId && props.projects[e.projectId] && props.projects[e.projectId].color){
                e.fill = props.projects[e.projectId].color
                e.textFill = (Brightness(props.projects[e.projectId].color)<200) ? "#fff" : "#000"
            }
            events.push(e);
        });
        this.setState({events : events});
    }



    render(){

        const eventPropGetter = (event)=>{
                let style = {
                    borderRadius : 0
                }
            
                switch(event.type){
                    case 'action':
                    case 'milestone':
                        style.backgroundColor =  event.fill;
                        style.borderColor = colors.white;
                        style.color = event.textFill;
                        break;
                    default:
                        style.backgroundColor = colors.midHi
                        style.borderColor = colors.white
                        style.cursor = 'default'
                        break;
                }
                return {
                    style : style
                }
                
        }
        return (
            <div className="MasterPlan">
                {/* <div className="milestones-wrap">
                    <div className="milestones-list">
                        <MilestoneList />
                    </div>
                </div> */}
                <div className="calendar-wrap" ref={(node)=>{this.calendarWrap=node}}>
                    <BigCalendar
                        localizer={localizer}
                        events={this.state.events}
                        startAccessor='start'
                        endAccessor='end'
                        titleAccessor="summary"
                        defaultView="day"
                        onSelectEvent={(event)=>{
                            if(event.type=='action'){
                                this.props.setSelectedPlanStepId(event.actionId);
                            }
                        }}
                        step={15}
                        eventPropGetter={eventPropGetter}
                />
                
                </div>
                <PushHelmToCalendar style={{
                        paddingRight:20,
                        marginTop:20
                    }}></PushHelmToCalendar>
                <PlanStepEditor></PlanStepEditor>
                <style jsx>{`
                    .MasterPlan {
                        flex-direction: column;
                        display: flex;
                        flex:1;
                        overflow: hidden;
                        padding:20px;
                        max-height: calc( 100vh - 60px );
                    }

                    .MasterPlan :global(.rbc-toolbar) {
                        display: block;
                        text-align: center;
                      
                    }
                    .MasterPlan :global(.rbc-toolbar-label) {
                        display: block;
                        margin-top:10px;
                    }
                    .MasterPlan :global(.rbc-toolbar) :global(.rbc-btn-group:last-child) {
                        display: none;
                    }
                    .calendar-wrap {
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;

                    }
                    .calendar-wrap :global( .rbc-btn-group > button:focus) {
                        outline : none;
                    }  

                    .milestones-wrap {
                        width:300px;
                        margin-right:20px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                    }
                    .milestones-list {
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                    .MasterPlan:global(*) {
                        border:1px solid red;
                    }

                    @media all and (max-width:959px){
                        .MasterPlan {
                            padding-top:20px;
                        }
                    }
                      
                `}</style>
            </div>
        )
    }
}

MasterPlanMobile.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        events : state.event.allEvents,
        projects : state.project.projectByKey
    };
}

export default  connect(mapStateProps, { setSelectedPlanStepId })(MasterPlanMobile);

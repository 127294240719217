import firebase from 'firebase';
import { logEvent } from '../analytics';

export const saveUser = (userRecord)=> {
    return (dispatch, getState)=> {
        return new Promise(async (resolve, reject)=>{
            let user = getState().app.authedUser;
            userRecord.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            let ref = firebase.firestore().collection('users').doc(user.uid);

            try {
                await ref.set(userRecord, {merge : true});
                logEvent('userSavedProfile');
                resolve(userRecord);
            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}



export const initUserProfile  = (user)=> {
  
    return async (dispatch, getState)=> {
        
        const db = firebase.firestore();

        let cancelUserListener = getState().user.userListener;
        if(cancelUserListener){ cancelUserListener() };
        
    
        
        
        if(user){


            //load the user time buckets
            let userListener = await db.collection('users').doc(user.uid)
            .onSnapshot(
                (snapShot)=>{
                    let data = snapShot.data();
                    dispatch({
                        type: 'USER_PROFILE_UPDATE',
                        payload : data
                    });
                }
            )
            //dispatch the time bucket listener
            dispatch({
                type: 'USER_PROFILE_LISTENER_UPDATE',
                payload : userListener
            });


            


           
            
            
        } else {
            //there is no user so clear all data
            dispatch({
                type: 'USER_PROFILE_UPDATE',
                payload : null
            });

        }
    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TimeLogger from '../common/TimeLogger';

class Test extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        return (
            <div className="Test">
                
                <TimeLogger></TimeLogger>
                <style jsx>{`
                    .Test {
                        padding:20px;
                    }
                    
                `}</style>
            </div>
        )
    }
}

Test.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(Test);

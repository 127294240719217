import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Grid, Modal, Form } from 'semantic-ui-react'
import { saveProject } from '../../../actions/projectAction';
import {withRouter} from 'react-router-dom';
import { SwatchesPicker} from 'react-color';

import { logEvent } from '../../../analytics';
import TimeBucketDropDown from '../../global/TimeBucketDropDown/TimeBucketDropDown';
import { colors, effects } from '../../../assets/styles/theme';

class NewProjectModal extends React.Component {

    constructor(props){
        super(props);
        this.saveProject = this.saveProject.bind(this);
        this.updateKey = this.updateKey.bind(this);
        this.state = {
            title : '',
            allocateTo : '',
            color : colors.primary,
            working : false,
            valid : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 


    }


    componentWillReceiveProps(newProps){
        if(newProps.timeBuckets && !this.state.allocateTo && (newProps.timeBuckets.length==1) ){
            this.setState({allocateTo : newProps.timeBuckets[0].id});
        }
    }

    updateKey(key, value){
        let attrs = {
            title : this.state.title,
            allocateTo : this.state.allocateTo,
            color : this.state.color
        }
        attrs[key]= value;
        attrs.valid = (attrs.title && attrs.allocateTo);
        this.setState(attrs)
    }

    async saveProject(){
        // saveProject
        try {
            this.setState({ working : true });
            
            let project = await this.props.saveProject({
                title : this.state.title,
                allocateTo : this.state.allocateTo,
                color : this.state.color,
                archive : false
            });
            this.setState({ working : false, title : '',  color : colors.primary  });
            logEvent('userCreatedNewPlan', { planId : project.id });
            // this.props.history.push(`/plans/${project.id}`)
            this.props.onCancel();
        }
        catch(e){
            console.error(e);
            this.setState({ working : false });
        }
    }
    render(){
        
        const renderPicker = ()=>{
            if(this.state.colorOpen){
                return (
                    <div className="color-pick-panel">
                            <SwatchesPicker
                            color={this.state.color}
                            onChange={(color)=>{
                                this.updateKey('color', color.hex, true);
                                this.setState({colorOpen : false})
                            }}
                        ></SwatchesPicker>
                        <style jsx>{`
                        .color-pick-panel {
                            position: absolute;
                            bottom:-225px;
                            left:15px;
                        }
                            `}</style>
                        </div>
                )
            } else {
                return null
            }
        }

        return (
            <Modal
                className="NewProjectModal"
                basic
                open={this.props.isOpen}
                >
                <Modal.Header>Create a new stream</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                    <Form>
                        <Form.Field >

                            <div className="title-wrap">

                                <div className="color-pick" onClick={()=>{this.setState({colorOpen : !this.state.colorOpen})}}>
                                    <div className="color-pick-swatch" style={{background : this.state.color || colors.primary}}></div>
                                    {renderPicker()}
                                </div>
                                <div className="title-input">
                                    <Form.Input
                                        placeholder="Add a name for your new stream"
                                        type="text"
                                        fluid
                                        size="big"
                                        onChange={(e)=>{ this.updateKey('title', e.target.value)}}
                                        value={this.state.title}
                                        style={{flex:1}}
                                    />
                                </div>
                            </div>
                            
                        </Form.Field>
                        <TimeBucketDropDown
                            placeholder="Select a time bucket for this stream"
                            value={this.state.allocateTo}
                            onChange={(bucketId)=>{ this.updateKey('allocateTo', bucketId) }}
                        ></TimeBucketDropDown>

                        
                    </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red'
                            onClick={this.props.onCancel}
                            inverted>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' 
                        disabled={!this.state.valid}
                        onClick={this.saveProject} inverted
                        loading={this.state.working}
                    >
                        <Icon name='checkmark' /> Go
                    </Button>
                </Modal.Actions>

                <style jsx>{`
                    

                .title-wrap {
                    display: flex;

                }
                .title-input {
                    flex:1;
                }
                .color-pick {
                            width: 48px;
                            height:48px;
                            background: ${colors.white};
                            ${effects.shadow1}
                            border-radius: 4px;
                            position: relative;   
                            z-index: 1;
                            margin-right:5px;
                            border:1px solid #22242626;
                        }
                        .color-pick-swatch {
                            position: absolute;
                            top:4px;
                            left:4px;
                            right:4px;
                            bottom:4px;
                            background: ${colors.primary};
                            border-radius:21px;
                        }

                    `}</style>
            </Modal>
        )
    }
}

NewProjectModal.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        timeBuckets : state.time.timeBuckets
    };
}

export default  withRouter(connect(mapStateProps, { saveProject })(NewProjectModal));

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import ProjectListLargeScreen from '../common/ProjectList/ProjectListLargeScreen';
import ProjectListSmallScreen from '../common/ProjectList/ProjectListSmallScreen';
import PlanStepEditor from '../common/PlanStepEditor';
import NewProjectModal from '../common/ProjectList/NewProjectModal';
import { Button,Checkbox} from 'semantic-ui-react';
import ManageProjects from '../common/ManageProjects';
import TimeLogger from '../common/TimeLogger';
import { logEvent } from '../../analytics';

class ProjectList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isOpen : false,
            manage : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ 
        
        logEvent('plansScreenLoaded')
    }

    
    render(){
        return (
            <div className="ProjectList">

                
                <MediaQuery query="(min-width: 770px)">
                    <div className="projectListActions">
                        
                        <Button 
                            size="small"
                            color="teal"
                            onClick={()=>{
                            this.setState({isOpen : true})
                        }}>Add New Plan</Button>

                        <Checkbox toggle
                            style={{marginLeft:10}}
                            checked={this.state.manage}
                            label="Sort / Archive Plans"
                            onChange={(v)=>{this.setState({manage : !this.state.manage })}}
                        ></Checkbox>
                        
                    </div>
                    {(this.state.manage)? <ManageProjects /> : <ProjectListLargeScreen  /> }

                </MediaQuery>
                <MediaQuery query="(max-width: 769px)">
                    <div className="projectListActions">
                    <Button 
                            size="small"
                            color="teal"
                            onClick={()=>{
                            this.setState({isOpen : true})
                        }}>Add New Plan</Button>
                    </div>
                    {/* <ManageProjects /> */}
                    <ProjectListSmallScreen />
                </MediaQuery>
                <PlanStepEditor />
                <NewProjectModal 
                    isOpen={this.state.isOpen}
                    onCancel={()=>{this.setState({ isOpen: false })}}
                />
                <TimeLogger></TimeLogger>
                
                <style jsx>{`
                    .ProjectList {
                        flex:1;
                        display: flex;
                        position: relative;
                        overflow: hidden;
                        
                    }
                    .projectListActions {
                        display: flex;
                        justify-content: flex-start;
                        position: absolute;
                        padding:10px 20px;
                        width:100%;
                        align-items: center;
                        border-bottom:1px solid #fff;
                    }
                    @media all and (max-width:959px){
                        .ProjectList {
                        
                            padding-top:40px;    
                        }
                        
                    }   
                    @media all and (max-width:769px){
                        .ProjectList {
                                padding-top:40px;    
                            }
                        .projectListActions {
                            padding-left:10px;    
                        }
                        
                    }   

                `}</style>
            </div>
        )
    }
}

ProjectList.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(ProjectList);

import firebase from 'firebase';
import _ from 'lodash';
import moment from 'moment';
import { logEvent } from '../analytics';



export const updateProjectsSortOrder = (projects)=>{
    return async (dispatch, getState)=>{
        return new Promise(async(resolve, reject)=>{

            try {
                let user = getState().app.authedUser;
                let batch  = firebase.firestore().batch();

                projects.forEach((project, idx)=>{
                    let ref = firebase.firestore().collection('users').doc(user.uid).collection('projects').doc(project.id);
                    batch.update(ref, {
                        sortKey : projects.length - idx,
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp() 
                    });
                });

                await batch.commit();
                

                dispatch({
                    type : 'PROJECTS_SORT_ORDER_UPDATED',
                    payload : projects
                })

                resolve(true);


            }
            catch(e){
                console.error(e);
                reject(e);
            }

        })
    }
}

export const deleteProject = (project)=>{
    return (dispatch, getState)=> {
        return new Promise(async (resolve, reject)=>{
            let user = getState().app.authedUser;
            
            try {
                await firebase.firestore().collection('users').doc(user.uid).collection('projects').doc(project.id).delete();
                dispatch({
                    type: 'USER_PROJECT_DELETED',
                    payload : project
                });
                logEvent('projectDeleted', { projectId : project.id })
                resolve(project);
            }
            catch(e){
                console.error(e);
                dispatch({
                    type: 'USER_PROJECT_DELETE_ERROR',
                    payload : e
                });
                reject(e);
            }


        })
    }
}


export const saveProject = (project)=> {
    return (dispatch, getState)=> {
        return new Promise(async (resolve, reject)=>{
            let user = getState().app.authedUser;
            let ref;
            if(!project.id){
                ref = firebase.firestore().collection('users').doc(user.uid).collection('projects').doc();
                project.id = ref.id;
                project.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                // project.sortKey=0; //always place at begining of list?
                project.archived = false;
                
            } else {
                ref = firebase.firestore().collection('users').doc(user.uid).collection('projects').doc(project.id);
            }
            if(typeof project.sortKey =='undefined'){
                project.sortKey=getState().project.projects.length;
               
            }

            if(!project.color){
                project.color="#00c9aa";
            }

            project.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            project.updatedUnix = moment().unix();
            if(!project.personalImportance){
                project.personalImportance = 0;
            }

            try {
                await ref.set(project, {merge : true});
                logEvent('projectUpdated', { projectId : project.id })
                dispatch({
                    type: 'USER_PROJECT_UPDATED',
                    payload : project
                });
                resolve(project);
            }
            catch(e){
                console.error(e);
                dispatch({
                    type: 'USER_PROJECT__UPDATE_ERROR',
                    payload : e
                });
                reject();
            }


        })
    }
}

//loads a project into the editor
// export const setSelectedProject = (id)=>{
//     return (dispatch, getState)=>{
//         //clear the current loaded project
//         dispatch({
//             type : 'SET_SELECTED_PROJECT',
//             payload : {}
//         })

//         if(id){
//             let usr = getState().app.authedUser;
            
//         }

//     }
// }




export const initProjects = (user)=> {
  
    return async (dispatch, getState)=> {
        const db = firebase.firestore();

        let cancelProjectsListener = getState().project.projectListener;
        if(cancelProjectsListener){ cancelProjectsListener() };
        
    
        
        
        if(user){


            //load the user time buckets
            let projectListener = await db.collection('users').doc(user.uid)
            .collection('projects')
            .onSnapshot(
                (snapShot)=>{
                    let projects = [];
                    snapShot.forEach((project)=>{
                        projects.push(project.data());
                    });
                    dispatch({
                        type: 'USER_PROJECTS_UPDATE',
                        payload : _.sortBy(projects, ['sortKey', 'updatedUnix' ]).reverse()
                    });
                }
            )
            //dispatch the time bucket listener
            dispatch({
                type: 'USER_PROJECTS_LISTENER_UPDATE',
                payload : projectListener
            });


            


           
            
            
        } else {
            //there is no user so clear all data
            dispatch({
                type: 'PROJECTS_CLEAR',
                payload : null
            });

        }
    }
}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import FocusDaySwitch from '../common/FocusDaySwitch';
import ZoneSubTitle from '../common/ZoneSubTitle';
import FocusEvent from '../common/FocusEvent';
import PlanStepEditor from '../common/PlanStepEditor';
import FlipMove from 'react-flip-move';
import Relax from '../common/Relax';
import TimeLogger from '../common/TimeLogger';
import DailyLogs from '../common/DailyLogs';
import { logEvent } from '../../analytics';
class Focus extends React.Component {

    constructor(props){
        super(props);
        this.renderTasks = this.renderTasks.bind(this);
        this.state = {
            start : null,
            end : null
        }
    }
    componentWillMount(){
        logEvent('focusScreenLoaded')
        this.setState({
            start : moment().startOf('day').toISOString(),
            end : moment().endOf('day').toISOString()
        })
       
    }
    
    componentDidMount(){ }

    renderTasks(){
        if(!this.props.events) return null;
        let filteredTasks = this.props.events
        .filter((e)=>{
            if(e.type!=='action') return false;
            return  moment(e.start).isBetween(this.state.start, this.state.end); 
            
        });

        if(filteredTasks.length){
            return filteredTasks
            .map((e, idx)=>{
                return <FocusEvent key={e.id} event={e} />
            })
        } else {
            return <Relax></Relax>
        }
        

    }
    render(){

        const renderDay = ()=>{
            if(moment().isSame(this.state.start, 'day')){
                return ' today'
            }
            else {
                return moment().to(moment(this.state.start)) +' time'
            }
        }
        return (
            <div className="Focus">
            
                <div className="Focus-SwitchBox">
                    <FocusDaySwitch 
                        date={this.state.start}
                        onChange={(newDate)=>{
                            this.setState({
                                start : moment(newDate).startOf('day').toISOString(),
                                end : moment(newDate).endOf('day').toISOString()
                            })
                        }}
                    
                    />
                    {/* <ZoneSubTitle style={{marginBottom:20, marginTop:20}}>Thing to focus on {renderDay()}</ZoneSubTitle> */}
                </div>

                <div className="cols">
                        <div className="primary">
                            <div className="slides">
                                <FlipMove
                                    enterAnimation="accordionVertical"
                                    leaveAnimation="accordionVertical"
                                    staggerDelayBy={50}
                                >
                                {this.renderTasks()}
                                </FlipMove>
                            </div>
                        
                        </div>
                        <div className="secondary">
                        <DailyLogs date={this.state.start} ></DailyLogs>
                        </div>
                </div>
                
                
                
                

                <PlanStepEditor></PlanStepEditor>
                <TimeLogger></TimeLogger>


                <style jsx>{`

                    
                    .Focus {
                        margin:20px;
                        flex:1;
                        
                        display: flex;
                        flex-direction: column;
                    }   
                    .Focus-SwitchBox {
                        margin-bottom:20px;
                    } 
                    .slides {
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                    .cols {
                        flex:1;
                        display: flex;
                        justify-content: space-between;
                        overflow: hidden;
                        
                    }
                    .primary {
                        flex:1;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        overflow-x: hidden;
                        padding:0px 20px 0px 0px;
                        
                    }
                    .secondary {
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        overflow-x: hidden;
                        width: 400px;
                        padding-left:10px;
                    }
                    @media all and (max-width:959px){
                        .Focus {
                            padding-top:40px;
                        }
                        .cols {
                            display: flex;
                            flex-direction: column;
                        }
                        .primary {
                            margin-right:0px;
                        }
                        .secondary {
                            display: none;
                        }
                    }
                    @media all and (max-width:769px){
                        .secondary {
                            display: none;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

Focus.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        events : state.event.events
        
    };
}

export default  connect(mapStateProps, {  })(Focus);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, fonts } from '../../../assets/styles/theme';
import FlipMove from 'react-flip-move';
import ProjectListItem from './ProjectListItem';
import { withRouter } from 'react-router-dom';


class ProjectListSmallScreen extends React.Component {

    constructor(props){
        super(props);

    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        const renderProjects = ()=>{
            return this.props.projects.map((p)=>{
                return (
                    <ProjectListItem key={p.id} project={p}  action={()=>{this.props.history.push(`/plans/${p.id}`)}} />
                )
            })
        }

        return (
            <div className="ProjectListSmallScreen">

                <div className="ProjectsList">
                    <FlipMove
                        maintainContainerHeight={true}
                        enterAnimation="accordionVertical" leaveAnimation="accordionVertical"
                        duration={750} easing="ease-out">
                        {renderProjects()}
                    

                    </FlipMove>
                    
                    
                </div>
                <style jsx>{`
                    .ProjectListSmallScreen {
                        margin:10px auto;
                        padding:10px;
                        flex: 1;
                        margin-bottom:-10px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        margin-top:50px!important;
                    } 
                    .ProjectsList {
                        margin-top:0px;
                        flex:1;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        padding-right:5px;
                    }   
                `}</style>
            </div>
        )
    }
}

ProjectListSmallScreen.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        projects : state.project.projects
        
    };
}

export default  withRouter(connect(mapStateProps, {  })(ProjectListSmallScreen));

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colors, fonts, effects } from '../../../assets/styles/theme';
import { setSelectedPlanStep, deleteStep } from '../../../actions/planAction';
import AllocatedTimeDisplay from '../AllocatedTimeDisplay/AllocatedTimeDisplay';
import { parseString, generateString } from '../../../libs/stringUtils';
import { saveStep } from '../../../actions/planAction';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { createTimeNewLog } from '../../../actions/timeActions';



class BrainDumpListStep extends React.Component {

    constructor(props){
        super(props);
        this.activate = this.activate.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.setInternals = this.setInternals.bind(this);
        this.updateAndSaveStep = this.updateAndSaveStep.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.state = {
            controlsActive : false,
            editMode : false,
            inputString : ''
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props);
     }

    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }
    
    setInternals(props){
       this.setState({
           inputString : generateString(props.step.action, props.step.type, props.step.timeRemaining)
       })
    }

    activate(){
        if(this.state.controlsActive) return;
        this.setState({controlsActive : true})
    }
    confirmDelete(e){
        e.stopPropagation();
        this.props.deleteStep(this.props.step.id)
    }

    cancel(){
        this.setState({controlsActive : false})
    }

    onKeyUp(e){
        //enter on return
        if(e.which == 13) { 
            this.updateAndSaveStep(e);
        }
    }
    onBlur(){
        this.updateAndSaveStep();
    }

    async updateAndSaveStep(e){
        let data = (e) ? parseString(e.target.value.trim()) : parseString(this.state.inputString);
        this.setState({
            inputString : data.original.trim()
        })
        let action = {
            type : data.type,
            action : data.title,
            timeRemaining : data.time
        }

        try {
            await this.props.saveStep({...this.props.step, ...action});
            this.setState({
                editMode : false,
                inputString : ""
            })
        }
        catch(e){
            console.error(e);
        }
    }

    onChange(e){
        let target = e.target.value;
        if(e.which == 13) { 
            target = target.trim();
        }
        this.setState({inputString : target});
        
    }

    render(){
        let step = this.props.step;
        let {color} = this.props.project;
        if(!color){
            color = colors.primary;
        }


        const renderDelete = ()=>{
            return (
                
                <div >
                    <button className="button" onClick={this.activate} >

                       
                    
                        <svg width="20px" height="20px" viewBox="0 0 511.626 511.627">
                        <path d="M392.857,292.354h-18.274c-2.669,0-4.859,0.855-6.563,2.573c-1.718,1.708-2.573,3.897-2.573,6.563v91.361
                                    c0,12.563-4.47,23.315-13.415,32.262c-8.945,8.945-19.701,13.414-32.264,13.414H82.224c-12.562,0-23.317-4.469-32.264-13.414
                                    c-8.945-8.946-13.417-19.698-13.417-32.262V155.31c0-12.562,4.471-23.313,13.417-32.259c8.947-8.947,19.702-13.418,32.264-13.418
                                    h200.994c2.669,0,4.859-0.859,6.57-2.57c1.711-1.713,2.566-3.9,2.566-6.567V82.221c0-2.662-0.855-4.853-2.566-6.563
                                    c-1.711-1.713-3.901-2.568-6.57-2.568H82.224c-22.648,0-42.016,8.042-58.102,24.125C8.042,113.297,0,132.665,0,155.313v237.542
                                    c0,22.647,8.042,42.018,24.123,58.095c16.086,16.084,35.454,24.13,58.102,24.13h237.543c22.647,0,42.017-8.046,58.101-24.13
                                    c16.085-16.077,24.127-35.447,24.127-58.095v-91.358c0-2.669-0.856-4.859-2.574-6.57
                                    C397.709,293.209,395.519,292.354,392.857,292.354z"/>
                        <path d="M506.199,41.971c-3.617-3.617-7.905-5.424-12.85-5.424H347.171c-4.948,0-9.233,1.807-12.847,5.424
                            c-3.617,3.615-5.428,7.898-5.428,12.847s1.811,9.233,5.428,12.85l50.247,50.248L198.424,304.067
                            c-1.906,1.903-2.856,4.093-2.856,6.563c0,2.479,0.953,4.668,2.856,6.571l32.548,32.544c1.903,1.903,4.093,2.852,6.567,2.852
                            s4.665-0.948,6.567-2.852l186.148-186.148l50.251,50.248c3.614,3.617,7.898,5.426,12.847,5.426s9.233-1.809,12.851-5.426
                            c3.617-3.616,5.424-7.898,5.424-12.847V54.818C511.626,49.866,509.813,45.586,506.199,41.971z"/>
                        </svg>


                    <div className={(this.state.controlsActive)?"overlayControls open" : "overlayControls"}>
                        <div className="cancel" onClick={this.cancel}></div>
                        
                        
                        
                        <div className="slide"   onClick={(e)=>{
                            e.stopPropagation();
                        }}>
                                <div className="slideInner" onClick={(e)=>{
                            console.log('slider');
                            e.stopPropagation();
                        }}>
                                    {/* <Button.Group> */}
                                        
                                        <Button 
                                            size="tiny"
                                            color="blue"
                                            icon
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                console.log('click');
                                                this.props.createTimeNewLog(step.id, null, step.action, step.timeRemaining, 0, null )
                                            }}
                                        >
                                            <Icon name="check circle"></Icon>
                                            
                                        </Button>
                                       
                                        

                                        <Popup
                                         size='mini'
                                            trigger={
                                                <Button 
                                            size="tiny"
                                            color="red"
                                            icon
                                            style={{marginLeft:2}}
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                            }}
                                        >
                                            <Icon name="trash"></Icon>
                                            
                                        </Button>
                                            }
                                        content={<Button color='red' 
                                            size="tiny"
                                        onClick={this.confirmDelete} content='Sure?' />}
                                        on='click'
                                        position='top center'
                                    />

                                        <Button 
                                            size="tiny"
                                            icon
                                            color='black'
                                            onClick={this.cancel}
                                        >   
                                            <Icon name="chevron right"></Icon>
                                            
                                        </Button>
                                   

                                    <div className="delete">

                                       
                                        {/* <div className="label">Delete</div>
                                    <svg width="20" height="20" style={{marginTop:2}} viewBox="0 0 774.266 774.266">

                                        <path d="M640.35,91.169H536.971V23.991C536.971,10.469,526.064,0,512.543,0c-1.312,0-2.187,0.438-2.614,0.875
                                            C509.491,0.438,508.616,0,508.179,0H265.212h-1.74h-1.75c-13.521,0-23.99,10.469-23.99,23.991v67.179H133.916
                                            c-29.667,0-52.783,23.116-52.783,52.783v38.387v47.981h45.803v491.6c0,29.668,22.679,52.346,52.346,52.346h415.703
                                            c29.667,0,52.782-22.678,52.782-52.346v-491.6h45.366v-47.981v-38.387C693.133,114.286,670.008,91.169,640.35,91.169z
                                            M285.713,47.981h202.84v43.188h-202.84V47.981z M599.349,721.922c0,3.061-1.312,4.363-4.364,4.363H179.282
                                            c-3.052,0-4.364-1.303-4.364-4.363V230.32h424.431V721.922z M644.715,182.339H129.551v-38.387c0-3.053,1.312-4.802,4.364-4.802
                                            H640.35c3.053,0,4.365,1.749,4.365,4.802V182.339z"/>
                                        <rect x="475.031" y="286.593" width="48.418" height="396.942"/>
                                        <rect x="363.361" y="286.593" width="48.418" height="396.942"/>
                                        <rect x="251.69" y="286.593" width="48.418" height="396.942"/>

                                    </svg> */}
                                    </div>
                                   
                                </div>
                            </div>
                    </div>

                    </button>
                   
                    

                    <style jsx>{`
                        .button {
                            background: transparent;
                            width:30px;
                            height: 30px;
                            float: right;
                            display: flex;
                            border:none;
                            outline: none;
                            margin-top:5px;
                        }
                        .overlayControls {
                            position: absolute;
                            top:0px;
                            left:0px;
                            bottom:0px;
                            right:0px;
                            transition: all 0.2s;
                            transform: translateX(100%);
                        }
                        .overlayControls.open {
                            transform: translateX(0%);
                        }
                        .cancel {
                            position: absolute;
                            background: rgba(0,0,0,0.4);
                            top:0px;
                            left:0px;
                            bottom:0px;
                            right:0px;
                            transform: translateX(0%);
                        }
                        .slide {
                            position: absolute;
                            
                            top:0px;
                            bottom:0px;
                            right:0px;
                            transform: translateX(0%);
                            padding:5px 1px;
                            display: flex;
                            align-items: center;
                            color:#fff;
                            ${fonts.bold}
                        }
                        .slideInner {
                            padding:1px;
                        }
                       
                        .slide path, .slide rect, .button path, .button rect {
                            fill: #fff;
                        }
                        
                    `}</style>
                </div>
            )
        }

        const renderMilestoneMarker = (step)=>{
            if(step.type=="milestone"){
                return (
                    <p>
                        Milestone
                        <style jsx>{`
                            p {
                                ${fonts.regularIt}
                                font-size:12px;
                                text-transform: uppercase;
                                opacity: 0.5;
                            }    
                        `}</style>
                    </p>
                )
            }
            return null;
        }
        const renderAction = ()=>{
            if(this.state.editMode){
                return (
                    <div>
                    <textarea 
                    placeholder="Edit"
                    onKeyUp={this.onKeyUp}
                    onBlur={this.onBlur}
                    onChange={this.onChange} 
                    value={this.state.inputString} 
                    rows="1"
                    />
                    <style jsx>{`
                    textarea  {
                        display:block;
                        width:100%;
                        border:1px solid ${colors.midHi};
                        padding:10px;
                        border-radius: 3px;
                        box-shadow: inset 1px 1px 4px rgba(0,0,0,0.2);
                        outline-color: ${colors.primary};
                        
                    }    
                `}</style>
                </div>
                )
            } else {
                return (
                    <div>
                        <p className="action" onClick={()=>{
                            this.setState({editMode : true})
                        }}>{step.action}</p>
                        <AllocatedTimeDisplay>{step.timeRemaining}</AllocatedTimeDisplay>
                        {renderMilestoneMarker(step)}   
                    </div>
                )
            }
        }

        return (
            <div className="BrainDumpListStep">
                <Draggable key={step.id} draggableId={step.id} index={this.props.idx} >
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={(snapshot.isDragging)?"step-wrap dragging" : "step-wrap"}
                                >
                                    <span className="handle" {...provided.dragHandleProps} >
                                        <svg  width="10" height="32" viewBox="0 0 10 32">
                                            <g opacity="0.48">
                                                <path d="M9.45,4l-4-4a.66.66,0,0,0-.94,0h0l-4,4A.66.66,0,1,0,1.49,5h0L4.32,2.13V13.79a.68.68,0,0,0,.68.67.67.67,0,0,0,.67-.67V2.13L8.5,5a.68.68,0,0,0,.95,0,.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                                <path d="M9.45,27a.66.66,0,0,0-.94,0h0L5.67,29.87V18.21a.67.67,0,0,0-1.34,0h0V29.87L1.49,27A.66.66,0,1,0,.55,28l4,4a.66.66,0,0,0,.94,0h0l4-4a.66.66,0,0,0,0-.94Z" fill="#fff"/>
                                            </g>
                                        </svg>
                                    </span>

                                    <div className="main-area">
                                        <div className="action-area">
                                            {renderAction()}
                                        </div>
                                      
                                        
                                    </div>

                                    {renderDelete()}
                              
                                <style jsx>{`
                                .step-wrap {
                                        background: rgba(255,255,255,0.3);
                                        margin-bottom:10px;
                                        box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
                                        display: flex;
                                        justify-content: stretch;
                                        position: relative;
                                        border-radius: 2px;
                                        overflow: hidden;
                                        border:1px dashed #fff;
                                        margin-right:5px;
                                        transition: all 0.3s;
                                    }
                                    .step-wrap.dragging {
                                        background: #fff;
                                        
                                    }
                                    .handle {
                                        width:20px;
                                        background: ${colors.dark};
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                    }

                                    @media (pointer: coarse) {
                                        .handle {
                                            width:40px;
                                        }
                                    }
                                    .main-area {
                                        flex:1;
                                        padding:10px 10px 10px 10px;
                                    }
                                  
                                    .action {
                                        ${fonts.regular}
                                    
                                        font-weight: 400;
                                        font-size:15px;
                                        line-height: 1.2em;
                                        color:${colors.white};
                                        transition: all 0.3s;
                                    }
                                    .step-wrap.dragging .action {
                                        color:${colors.dark};
                                    }
                                    .main-area-controls {
                                        font-size:14px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-end;

                                    }
                                    .step-time-meta {
                                        font-size:11px;
                                        color:${colors.mid};
                                    }
                                    
                                    .stepMark {
                                        width:10px; 
                                        height:10px;
                                        border-radius: 5px;
                                        position: absolute;
                                        display: block;
                                        background: #000;
                                        left:calc(50% + 2.5px);
                                        bottom:-4px;
                                        background: ${colors.primary};
                                    }
                                    @media (pointer: coarse) {
                                        .stepMark {
                                            left:calc(50% - 2.5px);
                                        }
                                    }
                                    `}</style>
                                
                                </div>

                            )}
         
                    </Draggable>
                
            </div>
        )
    }
}

BrainDumpListStep.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        user : state.app.authedUser,
        exp : state.event.expectedComplete
    };
}

export default  connect(mapStateProps, { setSelectedPlanStep, deleteStep, saveStep, createTimeNewLog })(BrainDumpListStep);

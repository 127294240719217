import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, TextArea, Button, Dropdown } from 'semantic-ui-react'
import { saveLog } from '../../../actions/logAction';

class ProjectLogEditor extends React.Component {

    constructor(props){
        super(props);
        this.saveTextLog = this.saveTextLog.bind(this);
        this.state = {
            inputString : "",
            working : false
        }

    }
    componentWillMount(){ }
    
    componentDidMount(){ }


    async saveTextLog(){
        try {
            await this.props.saveLog({
                text : this.state.inputString,
                type : 'note',
                pinned : false,
                projectId : this.props.project.id
            });
            this.setState({working : false, inputString : ''});
        }
        catch(e){
            console.error(e);
            this.setState({working : false});
        }
    }


    render(){
        return (
            
            <div className="ProjectLogEditor">
                <Form>
                    <TextArea
                        style={{maxHeight:'30vh'}}
                        rows={1}
                        fluid="true"
                        value={this.state.inputString} 
                        autoHeight 
                        placeholder='Add to log' 
                        onChange={(e)=>{this.setState({inputString: e.target.value})}}
                        disabled={this.state.working}
                    />
                </Form>
                <Button color="teal" loading={this.state.working} onClick={this.saveTextLog}>GO</Button>
                <style global jsx>{`
                    .ProjectLogEditor {
                        box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                        border-radius:.28571429rem;
                        padding-right:60px;
                        position: relative;
                    }   
                    .ProjectLogEditor .ui.form {
                        width:100%;
                    } 

                    .ProjectLogEditor .ui.button {
                        position: absolute;
                        top:0px;
                        right:0px;
                        bottom:0px;
                        width: 60px;
                        margin:0px;
                        border-radius: 0px .28571429rem  .28571429rem 0px;
                    }
                    .ProjectLogEditor .ui.form textarea {
                        border-radius: .28571429rem 0px  0px  .28571429rem;
                    }
                    
                `}</style>
            
            </div>
        )
    }
}

ProjectLogEditor.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, { saveLog })(ProjectLogEditor);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { loginWithEmail } from '../../actions/authActions';
import { Form, Button, Input, Message, Icon } from 'semantic-ui-react';
import isEmail from 'validator/lib/isEmail';
import { colors } from '../../assets/styles/theme';
import logo from './../../assets/img/helm-logo.svg';
import CentralFramer from './CentralFramer';
import Loading from './Loading';
import { logEvent } from '../../analytics';


class SignIn extends React.Component {

    constructor(props){
        super(props);
        this.updateEmail = this.updateEmail.bind(this);
        this.signInUser = this.signInUser.bind(this);
        this.state = {
            signInSent : false,
            email : '',
            valid : false,
            error : '',
            working : false,
            signingIn : false,
            register : true
        }

    }
    componentWillMount(){
        

    }


    updateEmail(str){
        this.setState({
            email : str.target.value,
            valid : isEmail(str.target.value)
        })
    }

    async signInUser(){
        
        this.setState({ working : true, error : '', signInSent : false })
        try {
            await this.props.loginWithEmail(this.state.email);
            logEvent('userRequestedMagicLink')
            this.setState({ working : false, error : '', signInSent : true })

        }
        catch(e){
            this.setState({ working : false, error : e.message, signInSent : false })
        }
    }
    
    componentDidMount(){
        let that = this;
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            //load email
            //show user as signing in.
            that.setState({signingIn : true})

            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            firebase.auth().signInWithEmailLink(email, window.location.href)
            .then(function(result) {
                window.localStorage.removeItem('emailForSignIn');
                window.history.replaceState({}, '', '/');
                logEvent('userSignedIn')
            })
            .catch(function(error) {
                console.error(error);
                that.setState({error : error.message})
            });
        }

     }

   
     

    render(){
        if(this.state.signingIn){
            return(
                <CentralFramer>
                    <Loading>Logging in</Loading>
                </CentralFramer>
            )
        }
        if(this.state.signInSent){
            return (
                <div>
                    <img className="logo" src={logo} alt=""/>
                    <p>Thank you for signing in to Helm.</p>
                    <p>We've sent an email to your email address with a "Sign in" link.<br></br> Click on the link to login to Helm.</p>
                    <Icon size="huge" color="teal" inverted name="check circle"></Icon>
                    <style jsx>{`
                    p {
                        color:#fff;
                    }
                   
                    .logo {
                        width:200px;
                        height:39px;
                        display: block;
                        margin-bottom:20px;
                    }
                `}</style>
                </div>
            )
        }

        const switcherLink = ()=>{
            if(this.state.register){
                return <a className="switcher" onClick={()=>{this.setState({register: false})}}>Already registered? Click here to sign in.</a>
            } else {
                return <a className="switcher" onClick={()=>{this.setState({register: true})}}>New to Helm? Click here to register.</a>
            }
        }

        const introduction = ()=>{
            if(!this.state.register){
                return (
                    <div>
                        <p>Enter your email address below and press "Log in". We will send to an email with a link that will log you into Helm in one click.</p>
                    </div>
                )
            }
            return (
                <div>
                    <p className="emp">Let's sign you up for <b>1 month's free trial</b>* to Helm.</p>
                    <p>Enter your email address below and press "Sign up". We will send you an email containing a link that will create an account and log you into Helm in one click.</p>
                    <p>Once you have entered a few details, we will send you a few helpful videos to get you started and one of the Helm team will get in touch to arrange a <b>full onboarding call with you via Zoom</b>.</p>
                    <p className="small">*Please note, full access and the 1 month free trial will start after we've onboarded you in person.</p>
                </div>
            )
        }

        const help = ()=>{
            if(this.state.register){
                return (
                    <div>
                        <p>If you have any questions or want to chat to one of the team about Helm and what it can do for you, before you sign up, please don't hesitate to get in touch.</p>
                        <p><a href="mailto:help@myhelm.io">help@myhelm.io</a></p>
                    </div>
                )
            }
            return (
                <div>
                    <p>If you have any questions or want to chat to one of the team about Helm and what it can do for you, please don't hesitate to get in touch.</p>
                    <p><a href="mailto:help@myhelm.io">help@myhelm.io</a></p>
                </div>
            )
        }
   
        return (
            <div className="SignIn">
                <img className="logo" src={logo} alt=""/>
                <h1>{(this.state.register)?'Sign up for Helm':'Welcome back'}</h1>
                {introduction()}
                
                
                <Form>
                    <Input
                    fluid
                    value={this.state.email}
                    onChange={this.updateEmail}
                    label={<Button 
                        color="teal"
                        // attached="right"
                        loading={this.state.working}
                        onClick={this.signInUser}
                        disabled={!this.state.valid}
                    >{(this.state.register)?'Sign up' : 'Log in'}</Button>}
                    labelPosition='right'
                    placeholder='Your email'
                    >
                    </Input>

                    </Form>
                    


                <p style={{textAlign: 'center', margin:0}}>{switcherLink()}</p>

                
                
                {help()}
                

                {(this.state.error)? <p className="error">{this.state.error}</p> : null}
             
                <style jsx>{`
                    .SignIn {
                        max-width: 500px;
                        margin:10px;
                    }
                    .SignIn :global(p) {
                        color:#fff; 
                        margin:15px 0px;
                    }
                    .SignIn :global(p) :global(a) {
                        color:#fff; 
                        margin:10px 0px;
                        font-size:1.5em;
                        color : ${colors.primary};
                    }
                    .SignIn  :global(p.emp){
                        font-size:1.5em;
                    }

                    .SignIn  :global(p.small){
                        font-size:0.8em;
                    }

                    
                    p.error {
                        font-weight: 700;
                        color:${colors.error};
                        margin-top:10px;
                    }
                    h1 {
                        color : ${colors.primary}
                    }
                    .logo {
                        width:200px;
                        height:39px;
                        display: block;
                        margin-bottom:20px;
                    }
                    .SignIn :global(.switcher) {
                        font-size:14px;
                        text-align:center;
                        color:#fff;
                        font-weight:700;
                        cursor:pointer;
                        margin-top:20px;
                        margin-bottom:40px;
                        color : ${colors.primary};
                        display:inline-block;
                        border-radius:3px;
                        padding:3px 10px;
                        background:${colors.dark};
                        
                        transition:all 0.2s;
                       
                    }
                    .SignIn :global(.switcher:hover){
                        box-shadow:2px 2px 4px rgba(0,0,0,0.4);
                    }
                `}</style>
            </div>
        )
    }
}

SignIn.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, { loginWithEmail })(SignIn);

import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../assets/styles/theme';

class SwitchBox extends React.Component {   

    constructor(props){
        super(props);
    }
    componentWillMount(){ }
    
    componentDidMount(){ }

    render(){
        if(!this.props.options) return null;

        const renderOpts = ()=>{
            
            return this.props.options.map((opt, idx)=>{
                return (
                    <div className="option" 
                        key={idx}
                        style={{backgroundColor : (this.props.value === opt.value) ? colors.primary : colors.white}}
                        onClick={()=>{this.props.onChange(opt.value)}}>
                        <span
                            style={{color : (this.props.value === opt.value) ? colors.white : colors.midLo}}
                        >
                            {opt.text}
                        </span>
                        <style jsx>{`
                            .option {
                                flex: 1;
                                padding:3px;
                                border:1px solid #22242626;
                                border-right:0px;
                                text-align: center;
                                font-size:14px;
                                cursor: pointer;
                            }
                            .option:first-child {
                                border-radius: 5px 0px 0px 5px;
                            }
                            .option:last-child {
                                border:1px solid #22242626;
                                border-radius: 0px 5px 5px 0px;
                            }
                        `}</style>
                    </div>
                )   
            })
        }
        return (
            <div className="SwitchBox">
                {renderOpts()}
                <style jsx>{`
                    .SwitchBox {
                        display: flex;
                    }
                    
                `}</style>
            </div>
        )
    }
}

SwitchBox.propTypes = {
    
}

export default SwitchBox;



const getSuffix = (input)=>{
    if(!input || !input.indexOf) return '';
    return (input.indexOf('.')==-1) ? ' mins' : ' hrs'
}
exports.getSuffix = getSuffix;




const parseMinutes = (mins)=>{
   
    let hours = Math.floor(mins/60);
    let rounding = mins%60;
    if(rounding<10){
        rounding =  '0'+rounding;
    }
    if(!hours){
        return mins;
    }
    if(!rounding){
        rounding = '00'
    }
    
    return hours+'.'+rounding;
}
exports.parseMinutes = parseMinutes;

const resolveToMinutes = (input)=>{
    let string = input+"";
    let time = 0;
    let splitChar = (string.indexOf('.')>-1) ? '.' : ':';
    let timeSplit = string.split(splitChar);
    if(timeSplit.length==1){
    if(timeSplit[0]){
        time = parseInt(timeSplit[0]);
    }             
    } else if (timeSplit.length==2) {
        let mins = 0;
        if(timeSplit[0]){
            mins+= (parseInt(timeSplit[0])* 60);
        }
        if(timeSplit[1]){
            if(timeSplit[1].length==1){
                timeSplit[1]+="0";
            }
            mins+= (parseInt(timeSplit[1]));
        }
        time = mins;
    }
    return time;
}
exports.resolveToMinutes = resolveToMinutes;

const getTimeString = (mins)=>{
    let str = '';
    if(mins){
        str = parseMinutes(mins)+" ";
        str += getSuffix(str);
    }
    return str;
}
exports.getTimeString = getTimeString;
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import PlanListStep from './PlanListStep';
import PlanListNote from './PlanListNote';
import PlanListMilestone from './PlanListMilestone';
import BrainDumpListStep from './BrainDumpListStep';
import { colors, fonts, effects } from '../../../assets/styles/theme';
import plus from './../../../assets/img/add-timeblock.svg';
import { brightness } from '../../../libs/colorUtils';
import { updateProjectPlan } from '../../../actions/planAction';
import { withRouter } from 'react-router-dom';
import { TextArea, Form } from 'semantic-ui-react';
import PlanListWait from './PlanListWait';


class PlanList extends React.Component {

    constructor(props){
        super(props);
        this.renderSteps = this.renderSteps.bind(this);
        this.state = {
            planString : ''
        }
    }
    componentWillMount(){ }
    
    componentDidMount(){ }
    
    componentWillUnmount(){ }

   
    renderSteps(){
        if(!this.props.steps) return null;

        this.brainDumpList = [];
        return this.props.steps.map((s, idx)=>{
            
            if(this.props.project.pool && s.type=="milestone"){
                return null;
            }

            if(this.props.project.id=='brainDump'){
                return <BrainDumpListStep project={this.props.project} step={s} idx={idx} key={s.id} />
            }
            if(s.type=='milestone'){
                return <PlanListMilestone project={this.props.project} step={s} idx={idx} key={s.id} />
            }

            if(s.type=='wait'){
                return <PlanListWait project={this.props.project} step={s} idx={idx} key={s.id} />
            }

            
            if(s.type=='action' && s.timeRemaining ){
                return <PlanListStep project={this.props.project} step={s} idx={idx} key={s.id} />
            }

            
            return (
                <PlanListNote project={this.props.project} step={s} idx={idx} key={s.id} />
            )
        })
    }
    render(){

        const renderEdit = ()=>{
            if(this.props.project.id=="brainDump"){
                return null;
            }

           
            return (
                <button className="edit-steps" style={{backgroundColor : this.props.project.color || colors.primary}} onClick={()=>{this.props.updateProjectPlan(this.props.project)}}>
                    <span
                        style={{color : (brightness(this.props.project.color || colors.primary)<200) ? "#fff" : "#000"}}
            >Add / Edit {(this.props.project.pool)?"Items" : "Steps"}</span>
                    
                        <img src={plus} alt=""/>
                </button>
            )
        }

        const renderCenterLine = ()=>{
            if(this.props.project.id=="brainDump"){
                return null;
            }
            if(this.props.project.pool){
                return null;
            }
            return <span className="centerLine" style={{backgroundColor : this.props.project.color || colors.primary }}></span>
        }

        

        const renderViewPlan = ()=>{
            if(this.props.project.id=="brainDump"){
                return null;
            }
            if(this.props.history.location.pathname=='/streams' || this.props.history.location.pathname=='/streams/'){
            
                return (
                    <div className="rightHead">
                       <div className="view-project" onClick={()=>{this.props.history.push(`/streams/${this.props.project.id}`)}}>
                <span className="label">View {(this.props.project.pool)?"Pool" : "Stream"}</span>
                           <span className="icon">
                               <svg className="icon-svg" width="20" height="20" viewBox="0 0 20 20">
                                   <path fill={this.props.project.color || colors.primary} d="M17.56,5.62a8.66,8.66,0,0,0-3.18-3.18A8.53,8.53,0,0,0,10,1.27,8.53,8.53,0,0,0,5.62,2.44,8.66,8.66,0,0,0,2.44,5.62,8.53,8.53,0,0,0,1.27,10a8.53,8.53,0,0,0,1.17,4.38,8.66,8.66,0,0,0,3.18,3.18A8.53,8.53,0,0,0,10,18.73a8.53,8.53,0,0,0,4.38-1.17,8.66,8.66,0,0,0,3.18-3.18A8.53,8.53,0,0,0,18.73,10,8.53,8.53,0,0,0,17.56,5.62ZM14,9A1.33,1.33,0,0,1,14,11L8.7,15.78h0a1.36,1.36,0,0,1-.89.35,1.31,1.31,0,0,1-.88-2.28h0L11.12,10,6.93,6.16h0a1.3,1.3,0,0,1,.88-2.27,1.35,1.35,0,0,1,.89.34h0Z"/>
                               </svg>
                           </span>
                       </div>
                   </div>
               )

            } else {
                return (
                    <div className="rightHead">
                       <div className="view-project" onClick={()=>{this.props.history.push(`/streams`)}}>
                            <span className="icon">
                               <svg className="icon-svg" style={{transform:"rotate(180deg)"}} width="20" height="20" viewBox="0 0 20 20">
                                   <path fill={this.props.project.color || colors.primary} d="M17.56,5.62a8.66,8.66,0,0,0-3.18-3.18A8.53,8.53,0,0,0,10,1.27,8.53,8.53,0,0,0,5.62,2.44,8.66,8.66,0,0,0,2.44,5.62,8.53,8.53,0,0,0,1.27,10a8.53,8.53,0,0,0,1.17,4.38,8.66,8.66,0,0,0,3.18,3.18A8.53,8.53,0,0,0,10,18.73a8.53,8.53,0,0,0,4.38-1.17,8.66,8.66,0,0,0,3.18-3.18A8.53,8.53,0,0,0,18.73,10,8.53,8.53,0,0,0,17.56,5.62ZM14,9A1.33,1.33,0,0,1,14,11L8.7,15.78h0a1.36,1.36,0,0,1-.89.35,1.31,1.31,0,0,1-.88-2.28h0L11.12,10,6.93,6.16h0a1.3,1.3,0,0,1,.88-2.27,1.35,1.35,0,0,1,.89.34h0Z"/>
                               </svg>
                           </span>
                           <span className="label">Back to Streams</span>
                       </div>
                   </div>
               )
            }

                
            
            
            
        }

        return (
            <div 
            className={`PlanList ${(this.props.project.pool)?' Pool ': ''}`} style={{borderColor : this.props.project.color || colors.primary }}>
                {renderCenterLine()}
                <div className="listControls">
                    {renderEdit()}
                    {renderViewPlan()}
                </div>
                
                <Droppable droppableId={this.props.project.id} key={this.props.project.id}>
                    {(provided, snapshot) => (
                        <div className="plan-list" ref={provided.innerRef} >
                            {this.renderSteps()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <style jsx global>{`
                    .PlanList {
                        position: relative;
                        width: 100%;
                        height: 100%;
                       
                    }
                    .PlanList.Pool {
                        margin-top:-10px;
                        border-radius: 5px;
                        border:2px solid #000;
                        padding:10px 10px;
                        background-color: #e7e7e7;
                        box-shadow: inset 2px 2px 6px rgba(0,0,0,0.4);
                    }
                    .PlanList .plan-list {
                        min-height: 100vh;
                        height: 100%;
                    }
                    .PlanList .centerLine {
                        position: absolute;
                        top:0px;
                        bottom:15px;
                        left:10px;
                        width: 8px;
                        background: ${colors.primary};
                        transform: translateX(-50%);
                    }
                    .PlanList .listControls {
                        display:flex;
                        justify-content: space-between;
                    }
                    .PlanList .edit-steps {
                        display: flex;
                        margin:0;
                        z-index: 0;
                        position: relative;
                        height:30px;
                        margin-bottom:5px;
                        background: ${colors.primary};
                        border-radius: 5px;
                        border:none;
                        justify-content: center;
                        align-items: center;
                        ${fonts.bold}
                        font-size: 12px;
                        color:#fff;
                        cursor: pointer;
                        ${effects.shadow1}
                    }
                    .PlanList .edit-steps:focus {
                        outline: none;
                    }
                    .PlanList .edit-steps img {
                        width: 20px;
                        height:20px;
                        display: block;
                    }

                    .PlanList .view-project {
                        background: ${colors.white};
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding:5px;
                        border-radius: 5px;
                        ${effects.shadow1}
                        cursor: pointer;

                    }
                    .PlanList .label {
                        padding:0px 5px;
                        line-height: 1em;
                        ${fonts.bold}
                        font-size: 12px;
                    }
                    .PlanList .icon-svg {
                        display: block;
                    }
        
                `}</style>
            </div>
        )
    }
}

PlanList.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    };
}

export default  withRouter(connect(mapStateProps, { updateProjectPlan })(PlanList));

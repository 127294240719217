import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { loadDailyLog, deleteLog} from '../../actions/logAction';
import { parseMinutes, getSuffix, getTimeString } from './timeUtils';
import { Button, Icon } from 'semantic-ui-react';
import DeleteButton from './DeleteButton';
import { fonts, colors } from '../../assets/styles/theme';
import { createTimeNewLog } from '../../actions/timeActions';

class DailyLogs extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.renderLogItems = this.renderLogItems.bind(this);
        this.state = {
            date : null,
            loading : false,
            totalMinutes : 0,
            timeString : ''
        }
        
    }
    componentWillMount(){ 
        this.setInternals(this.props);
    }
    
    componentDidMount(){ 
        

    }
    componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    setInternals(props){

        let attrs = {
            timeString : ''
        }

        if(props.date!==this.state.date){
            this.props.loadDailyLog(props.date);
            attrs['date']= props.date;
        }
        attrs.totalMinutes = 0;

        props.logs.forEach((l)=>{
            if(l.time){
                attrs.totalMinutes+=l.time;
            }
        })

        if(attrs.totalMinutes){
        
            attrs.timeString = parseMinutes(attrs.totalMinutes);
            attrs.timeString+= ' '+getSuffix(attrs.timeString);
        } 



        this.setState({...attrs})

       
    }


    renderLogItems(){
        if(!this.props.logs || !this.props.logs.length) return '';

    
        
        return this.props.logs.map((log,idx)=>{
            let project = (log && log.projectId) ? this.props.projects[log.projectId]  : null;

            const getColour = ()=>{
                if(!project || !project.color) return colors.mid;
                return project.color;
            }

            return (
                <div key={log.id} className="log-entry">

                    <div className="log-details">
                        <div className="project"
                            style={{
                                color : getColour()
                            }}
                        >{(project)? project.title : '' }</div>
                        <div className="logText">
                            {log.text}
                        </div>
                        <div className="time">
                            {getTimeString(log.time)}
                        </div>
                    </div>
                    <div>
                        <DeleteButton
                            onConfirm={()=>{
                                this.props.deleteLog(log.id)
                            }}
                        />
                    </div>
                 



                    
                   
                    <style jsx>{`
                        .project {
                            ${fonts.bold}
                            font-size:13px;

                        }    
                        .logText {
                            font-size:16px;
                        }
                        .time {
                            font-size:13px;

                        }
                        .log-entry {
                            display: flex;
                            max-width: 800px;
                            border-bottom:1px solid ${colors.mid};
                            margin-bottom:5px;
                            padding:5px;
                            align-items: center;
                            width: 100%;
                        }

                        .log-details {
                            flex:1;
                        }
                    `}</style>
                </div>
            )
        })
    }
    render(){
        return (
            <div className="DailyLogs">

                    <Button
                        floated="right"
                        content="Log time"
                        icon="time"
                        size="tiny"
                        color="blue"
                        onClick={()=>{
                            this.props.createTimeNewLog(null, null, "", 0, 0, null )
                        }}
                    ></Button>
               
                
                {this.renderLogItems()}

                <div className="total">
                    {(this.state.totalMinutes)? `Total logged: ${getTimeString(this.state.totalMinutes)}` : ''}
                </div>
                
                <style jsx>{`
                    .total {
                        ${fonts.bold}
                        color: ${colors.dark};
                        margin-bottom: 20px;
                    }
                    .DailyLogs {
                        width: 100%; 
                    }    
                `}</style>
            </div>
        )
    }
}

DailyLogs.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        logs : state.log.logs,
        projects : state.project.projectByKey
    };
}

export default  connect(mapStateProps, {loadDailyLog, deleteLog, createTimeNewLog })(DailyLogs);
